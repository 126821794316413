import Vue from 'vue'
import './plugins/storage'
import './plugins/rights'
import './plugins/axios'
import './plugins/filters'
import './plugins/marked'
import vuetify from './plugins/vuetify'
import i18nBoot from './plugins/i18n'
import config from './config'
import router from './plugins/router'
import store from './plugins/store'
import App from './App.vue'
import Err from './Err.vue'
import VueTour from 'vue-tour'
import './registerServiceWorker'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
Vue.config.productionTip = false

config.mount(Vue.prototype, store, vuetify)
    .then(config => i18nBoot.init(config, store))
    .then(i18n => {
        new Vue({
            store,
            router,
            vuetify,
            i18n,
            render: h => h(App)
        }).$mount('#app')
    })
    .catch(err => {
        Vue.prototype.currentError = err
        new Vue({
            store,
            vuetify,
            render: h => h(Err)
        }).$mount('#app')
    })
