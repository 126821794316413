<template>
    <div @keydown="onKeyDown">
        <v-combobox
            class="pt-0 mt-0"
            ref="field"
            v-model="value"
            :items="items"
            :menu-props="menuprops"
            :search-input.sync="search"
            hide-details
            single-line flat solo>
        </v-combobox>
    </div>
</template>

<script>
import Vue from 'vue'
import SelectFocus from '@/modules/line/mixin/SelectFocus'
import KeyDown from '@/modules/line/mixin/KeyDown'

export default Vue.extend({
    name: 'kus-line-grid-flow',
    mixins: [SelectFocus, KeyDown],
    data: () => ({
        menuprops: {},
        regexp: /^F[0-9]{8}$/,
        value: '',
        search: '',
        params: null
    }),
    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        },
        items() {
            return this.$store.getters['linegridsel/flows']
        }
    },
    created() {
        if (this.params.context && this.params.context.attach) {
            this.menuprops.contentClass = 'maxZindex'
        }
        this.value = this.params.value
        this.$store.dispatch('linegridsel/flows', { clientId: this.clientId })
            .then(res => this.mixinSelectFocus())

        this.attach = this.$refs.attach
    },
    methods: {
        onKeyDown(event) {
            this.mixinKeyDownMenu(event)
            const reg = /[0-9Ff]+/g
            const keys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete']
            if (keys.indexOf(event.key) === -1 && !reg.test(event.key)) {
                event.preventDefault()
            }
        },
        getValue() {
            this.value = (this.value || '').toUpperCase()
            if (this.value && !this.regexp.test(this.value)) {
                throw new Error(this.$i18n.t('flows.badFormat'))
            }
            return this.value || null
        },
        isPopup() {
            return false
        }
    }
})
</script>
