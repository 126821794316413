<template>
    <v-data-table
        :headers="headers"
        :items="access"
        :loading="loading"
        hide-default-footer>
        <template #top>
            <v-card flat>
                <v-card-title>
                    {{ $t('clients.access.title') }}
                    <v-spacer />
                    <v-btn text small color="primary" @click="add">
                        {{ $t('clients.access.add') }}
                    </v-btn>
                </v-card-title>
            </v-card>
        </template>
        <template #item.role="{ item }">
            <kus-client-access-role
                v-model="item.role"
                :disabled="item.userId === connected.id" />
        </template>
        <template #item.email="{ item }">
            <kus-client-access-email
                v-model="item.email"
                :hint="item.name"
                :id="item.id"
                :client-id="id"
                :sent-at="item.meta.sentAt"
                :accepted="item.accepted"
                :disabled="item.userId === connected.id" />
        </template>
        <template #item.read="{ item }">
            <kus-client-access-right
                v-bind="item.read"
                @disabled="v => item.read.disabled = v"
                @unlimited="v => item.read.unlimited = v"
                @from="v => item.read.from = v"
                @to="v => item.read.to = v" />
        </template>
        <template #item.write="{ item }">
            <kus-client-access-right
                v-bind="item.write"
                :from-max="!item.read.unlimited && !item.read.disabled && item.read.from"
                :to-max="!item.read.unlimited && !item.read.disabled && item.read.to"
                @disabled="v => item.write.disabled = v"
                @unlimited="v => item.write.unlimited = v"
                @from="v => item.write.from = v"
                @to="v => item.write.to = v" />
        </template>
        <template #item.action="{ item }">
            <v-btn text small icon
                color="primary"
                @click="save(item)"
                :disabled="loading">
                <v-icon small>$vuetify.icons.baseSave</v-icon>
            </v-btn>
            <kus-card-remove-btn
                small
                :title="$t('clients.access.removeTitle')"
                :direct="!item.id"
                @remove="remove(item)"
                :disabled="item.userId === connected.id">
                <template #content>
                    {{ $t('clients.access.remove') }}
                </template>
            </kus-card-remove-btn>
        </template>
        <template #item.documentRights="{ item }">
            <kus-client-access-roles-doc
                v-model="item.documentRights"
                :full-disabled="item.userId === connected.id"
                :disabled="item.documentRightsDisabled"
                @disabled="v => item.documentRightsDisabled = v" />
        </template>
    </v-data-table>
</template>

<script>
import KusClientAccessRight from '@/modules/client/access/KusClientAccessRight'
import KusClientAccessEmail from '@/modules/client/access/KusClientAccessEmail'
import KusClientAccessRole from '@/modules/client/access/KusClientAccessRole'
import KusClientAccessRolesDoc from '@/modules/client/access/KusClientAccessRolesDoc'
import KusCardRemoveBtn from '@/modules/card/KusCardRemoveBtn'

export default {
    name: 'kus-client-access-table',
    components: { KusClientAccessRight, KusClientAccessEmail, KusClientAccessRole, KusCardRemoveBtn, KusClientAccessRolesDoc },
    props: {
        id: { type: String, required: true }
    },

    data: () => ({
        loading: false
    }),

    computed: {
        headers() {
            return [
                { text: this.$i18n.t('clients.access.headers.role'), value: 'role', width: 180 },
                { text: this.$i18n.t('clients.access.headers.email'), value: 'email' },
                { text: this.$i18n.t('clients.access.headers.read'), value: 'read', sortable: false, width: 270 },
                { text: this.$i18n.t('clients.access.headers.write'), value: 'write', sortable: false, width: 270 },
                { text: this.$i18n.t('clients.access.headers.documents'), value: 'documentRights', sortable: false, width: 260 },
                { text: '', value: 'action', align: 'end', sortable: false, width: 90 }
            ]
        },

        connected() {
            return this.$store.getters['auth/user']
        },

        access() {
            return this.$store.getters['caccess/list']
        }
    },

    watch: {
        id: 'load'
    },

    mounted() {
        return this.load()
    },

    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('caccess/list', { clientId: this.id })
                .finally(() => (this.loading = false))
        },

        add() {
            this.$store.commit('caccess/add', { clientId: this.id })
        },

        save(access) {
            this.loading = true
            return this.$store.dispatch('caccess/save', { access: access })
                .finally(() => (this.loading = false))
        },

        remove(access) {
            this.loading = true
            return this.$store.dispatch('caccess/remove', { access: access })
                .finally(() => (this.loading = false))
        }
    }
}
</script>
