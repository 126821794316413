<template>
    <span>
        <span v-if="client.id">{{ client.company || `${client.firstname} ${client.lastname}` }}</span>
        <v-progress-circular
            v-else
            indeterminate
            color="primary" />
    </span>
</template>

<script>
export default {
    name: 'kus-line-topbar',
    data: () => ({
        client: {}
    }),
    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        }
    },
    mounted() {
        this.$store.dispatch('client/get', { id: this.clientId })
            .then(client => (this.client = client))
    }
}
</script>
