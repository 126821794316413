<template>
    <v-menu v-model="show"
        :close-on-content-click="false"
        :position-x="pos.x"
        :position-y="pos.y"
        absolute
        min-width="300"
        max-width="400"
        z-index="999">
        <v-card>
            <kus-card-title :loading="loading" :title="$t('document.flowPopup.title', { flow: flow })" @close="show = false">
                <template #buttons>
                    <v-spacer />
                    <kus-document-upload-btn small
                        ref="uploader"
                        :loading="loading"
                        :flow="flow"
                        @upload:success="saveDocUploaded"
                        :tooltip="$t('document.upload.tooltip')" />
                </template>
            </kus-card-title>
            <v-list dense v-if="files.length">
                <v-list-item v-for="file in files" :key="file.id" @click="open(file)">
                    <v-list-item-title>{{ file.title || file.uploadName }}</v-list-item-title>
                    <v-list-item-icon>
                        <v-icon small @click.stop="preview(file)">$vuetify.icons.docOpen</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
            <v-card-text v-else>{{ $t('document.flowPopup.empty') }}</v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusDocumentUploadBtn from '@/modules/document/KusDocumentUploadBtn'

export default {
    name: 'kus-document-flow-popup',
    components: { KusCardTitle, KusDocumentUploadBtn },
    computed: {
        show: {
            get() {
                return this.$store.getters['docpop/popup']
            },
            set(v) {
                this.$store.commit('docpop/hide')
            }
        },

        clientId() {
            return this.$store.getters['auth/client']
        },

        loading() {
            return this.$store.getters['docpop/loading']
        },

        files() {
            return this.$store.getters['docpop/files']
        },

        flow() {
            return this.$store.getters['docpop/flow']
        },

        pos() {
            return this.$store.getters['docpop/pos']
        }
    },

    watch: {
        flow() {
            if (this.files.length === 1) {
                this.open(this.files[0], true)
            }
        }
    },

    methods: {
        saveDocUploaded(file) {
            this.$store.commit('docpop/add', { file: file.response })
            this.$store.commit('linegrid/updateFlowLines', { flow: this.flow, docNb: this.files.length })
        },

        preview(file) {
            this.$store.commit('doc/preview', { file: file, popup: true })
            this.show = false
        },

        async open(file, close) {
            // on s'assure que le panel document est ouvert
            const docPanelOpen = this.$store.getters['pref/getClientPref'](this.clientId, 'docpnl')
            let width
            if (!docPanelOpen) {
                this.$store.dispatch('pref/setClientPref', { clientId: this.clientId, key: 'docpnl', value: true })
                width = await this.$api.get(`/clients/${this.clientId}/preferences/document-panel-width`)
                    .then(res => res.data.width || 300)
            }
            this.$nextTick(() => {
                this.$store.commit('lineedit/show', {
                    show: true,
                    block: this.$store.getters['docpop/block'],
                    flow: this.flow,
                    field: 'docNb'
                })
                if (width) {
                    this.pos.x += width
                }
                this.$nextTick(() => {
                    this.$store.commit('doc/preview', { file: file })
                    if (close) {
                        this.show = false
                    }
                })
            })
        }
    }
}
</script>
