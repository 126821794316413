/* eslint-disable space-before-function-paren */
/* eslint-disable no-console */

import { register } from 'register-service-worker'

const getText = path => {
    return 'Update'
}

// Show notification for refresh page
const createUIPrompt = async (registration) => {
    const newVersion = await caches.has('kushim-service-worker')

    const div = document.createElement('div')
    div.className = 'v-snack v-snack--active v-snack--bottom v-snack--has-background v-snack--multi-line'
    div.innerHTML = `
        <div id="pwacontainer" class="v-snack__wrapper v-sheet theme--dark">
            <div role="status" aria-live="polite" class="v-snack__content">${getText('shared-front:pwa.text')}</div>
            <div class="v-snack__action ">
                <button id="pwabtnupdate" type="button" class="v-btn v-btn--flat v-btn--text theme--dark v-size--default yellow--text v-snack__btn">
                    <span class="v-btn__content">${getText('shared-front:pwa.btn')}</span>
                </button>
                <button id="pwabtnclose" type="button" class="v-icon notranslate v-icon--link mdi mdi-close theme--dark" style="font-size: 16px;"></button>
            </div>
        </div>
    `
    document.getElementById('app').appendChild(div)

    setTimeout(() => {
        if (newVersion) {
            try {
                registration.waiting.postMessage({
                    type: 'SKIP_WAITING'
                })
            } catch (e) {
                div.remove()
                return console.error(e)
            }
        } else {
            window.location.reload()
        }

        const close = document.getElementById('pwabtnclose')
        close.addEventListener('click', () => {
            document.getElementById('pwacontainer').remove()
        })

        const button = document.getElementById('pwabtnupdate')

        button.classList.add('v-btn--loading', 'v-btn--icon')
        button.innerHTML = `
        <span class="v-btn__content">${getText('shared-front:pwa.btn')}</span>
        <span class="v-btn__loader">
            <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="v-progress-circular v-progress-circular--indeterminate" style="height: 23px; width: 23px;">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="21.904761904761905 21.904761904761905 43.80952380952381 43.80952380952381" style="transform: rotate(0deg);">
                    <circle fill="transparent" cx="43.80952380952381" cy="43.80952380952381" r="20" stroke-width="3.8095238095238093" stroke-dasharray="125.664" stroke-dashoffset="125.66370614359172px" class="v-progress-circular__overlay"></circle>
                </svg>
                <div class="v-progress-circular__info"></div>
            </div>
        </span>
        `
    }, 600)
}

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            )
        },
        registered() {
            console.log('Service worker has been registered.')
        },
        cached() {
            console.log('Content has been cached for offline use.')
        },
        updatefound() {
            console.log('New content is downloading.')
        },
        updated(registration) {
            console.log('New content is available; please refresh.')
            if (registration.waiting) {
                createUIPrompt(registration)
            }
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        }
    })

    // Listen for new service worker taking control
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('controllerchange', async () => {
            const newVersion = await caches.has('kushim-service-worker')
            if (newVersion) {
                window.location.reload()
            }
        }, { once: true })
    }
}
