<template>
    <draggable
        class="kus-balance--group"
        tag="ul"
        :list="groups"
        :group="groupAccountName"
        handle=".handle">
        <li v-for="group in groups"
            :key="group.id"
            :class="{
                'red lighten-4': group.type === 'group',
                'green lighten-4': group.type === 'account',
                hidden: !group.visible
            }">
            <v-text-field
                v-if="group.type === 'group'"
                dense hide-details single-line
                :label="$t('result.group.title')"
                :ref="`gt-${group.id}`"
                v-model="group.name">
                <template #prepend>
                    <v-icon small class="handle">$vuetify.icons.balanceDrag</v-icon>
                </template>
                <template #append>
                    <v-icon small :color="group.groups.length ? 'grey' : 'red'" @click="removeGroup(group)">$vuetify.icons.baseDelete</v-icon>
                </template>
            </v-text-field>

            <p v-else>
                <v-icon small class="handle">$vuetify.icons.balanceDrag</v-icon>
                {{ group.code }} {{ group.name }}
            </p>

            <kus-result-group
                v-if="group.type === 'group'"
                :parent="group"
                :groups="group.groups"
                :group-account-name="groupAccountName" />
        </li>
    </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    name: 'kus-result-group',
    components: { draggable },
    props: {
        groups: { type: Array, required: true },
        groupAccountName: { type: String, required: true },
        parent: { type: Object }
    },
    methods: {

        focusFirstGroup() {
            if (!this.groups.length) {
                return
            }
            const id = `gt-${this.groups[0].id}`
            this.$refs[id][0].focus()
        },

        removeGroup(group) {
            if (group.groups.length) {
                return
            }
            this.$store.commit('res/show', {
                parent: this.parent,
                accounts: this.flatAccounts(group),
                group
            })
        },

        flatAccounts(group) {
            return (group.groups || []).filter(g => g.type === 'account').reduce((a, g) => {
                return a.concat(this.flatAccounts(g))
            }, [])
        }
    }
}
</script>

<style lang="sass">
.kus-balance--group
    min-height: 20px
    border-bottom: 1px dashed

    list-style: none
    margin: 0
    padding-bottom: 1em

    .hidden
        display: none

    .v-icon
        cursor: pointer

    p
        padding: 0.5em
        margin: 0

    &.first
        padding-right: 24px

.kus-balance--accounts
    margin: 0
    list-style: none

</style>
