import KusUserAccountVue from '@/modules/user/KusUserAccountVue'
import KusUserListVue from '@/modules/user/KusUserListVue'
import KusUserForm from '@/modules/user/KusUserForm'

export default [
    {
        path: '/user/account',
        name: 'useraccount',
        component: KusUserAccountVue
    },
    {
        path: '/user',
        name: 'user',
        component: KusUserListVue,
        children: [
            {
                path: ':id',
                name: 'userform',
                component: KusUserForm,
                props: true
            }
        ]
    }
]
