import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        files: [],
        popup: false,
        flow: null,
        block: null,
        dom: null,
        pos: {},
        clientId: null,
        loading: false
    },

    getters: {
        files: state => state.files,
        popup: state => state.popup,
        dom: state => state.dom,
        pos: state => state.pos,
        flow: state => state.flow,
        block: state => state.block,
        clientId: state => state.clientId,
        added: state => state.files.length
    },

    mutations: {
        popup(state, data) {
            state.dom = data.dom
            state.pos = data.pos
            state.popup = data.show || false
            state.flow = data.flow
            state.block = data.block
            state.clientId = data.clientId
        },

        hide(state) {
            state.popup = false
            state.dom = false
            state.files = []
        },

        files(state, data) {
            state.files = data.files || []
        },

        add(state, data) {
            state.files.push(data.file)
        },

        loading(state, data) {
            state.loading = data.loading
        },

        pos(state, data) {
            state.pos = data.pos
        },

        dom(state, data) {
            state.dom = data.dom
        }
    },

    actions: {
        show(context, data) {
            context.commit('loading', { loading: true })
            return Vue.prototype.$api.get(`/clients/${data.clientId}/flows/${data.flow}/documents`)
                .then(res => {
                    context.commit('files', { files: res.data })
                    context.commit('loading', { loading: false })
                    if (data.disablePopup !== true) {
                        context.commit('popup', data)
                    }
                    return res.data
                })
        }
    }
}
