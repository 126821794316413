<template>
    <div>
        <kus-client-list-search start-load>
            <template #append-outer>
                <v-icon color="primary" @click="add">$vuetify.icons.clientAdd</v-icon>
            </template>
        </kus-client-list-search>
        <v-list>
            <v-list-item
                v-for="client in clients"
                :key="client.id"
                :to="{ name: 'clientform', params: { id: client.id } }">
                <v-list-item-content>
                    <v-list-item-title v-if="client.company">{{ client.company }}</v-list-item-title>
                    <v-list-item-title v-else>{{ client.firstname }} {{ client.lastname }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn
                        @click.prevent.stop="select(client)"
                        :dark="client.id === selected"
                        :class="{ primary: client.id === selected }"
                        icon
                        small>
                        <v-icon small>$vuetify.icons.clientSelect</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import KusClientListSearch from '@/modules/client/KusClientListSearch'

export default {
    name: 'kus-client-list',
    components: { KusClientListSearch },
    computed: {
        clients() {
            return this.$store.getters['client/list']
        },
        selected() {
            return this.$store.getters['auth/client']
        }
    },

    methods: {
        add() {
            this.$router.push({ name: 'clientform', params: { id: '0' } })
        },

        select(client) {
            return this.$store.dispatch('auth/client', { clientId: client.id })
        }
    }
}
</script>
