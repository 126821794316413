<template>
    <v-select
        ref="field"
        v-model="text"
        :label="label"
        :items="items" />
</template>

<script>
import SelectFocus from '@/modules/line/mixin/SelectFocus'

export default {
    name: 'kus-line-search-text',
    mixins: [SelectFocus],
    props: {
        value: { type: String },
        label: { type: String }
    },
    mounted() {
        this.$nextTick(() => this.mixinFocus())
    },
    computed: {
        text: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        items() {
            const data = this.$i18n.t('lines.account.types')
            return Object.entries(data).map(a => ({
                value: a[0],
                text: a[1]
            }))
        }
    },
    methods: {
        focus() {
            this.mixinFocus()
        }
    }
}
</script>
