<template>
    <div>
        <v-combobox
            class="pt-0 mt-0 kus-line-grid-forex-string"
            ref="field"
            v-model="value"
            :items="items"
            :menu-props="menuprops"
            :search-input.sync="search"
            hide-details
            single-line flat solo dense
            @keydown="onKeyDown">
            <template #prepend-item>
                <v-alert color="warning" text dense tile class="text-caption mb-0">
                    <a :href="source" target="_blank">Source: {{ name }}</a>
                </v-alert>
            </template>
            <template #append-outer>
                <v-icon v-if="value" small @click="value = undefined">$vuetify.icons.baseClose</v-icon>
                <v-tooltip bottom v-if="!forexHidden">
                    <template #activator="{ on }">
                        <v-icon v-on="on" small @click="forex" :color="forexAvailable ? 'primary' : 'grey'">$vuetify.icons.lineForexBtn</v-icon>
                    </template>
                    <span v-if="forexAvailable">Calculer automatiquement</span>
                    <span v-else-if="forexUnknownCurrency">Monnaie inconnue, impossible de calculer automatiquement</span>
                    <span v-else>Renseigner la date, les monnaies et le montant pour le calcul automatique</span>
                </v-tooltip>
            </template>
        </v-combobox>
    </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import SelectFocus from '@/modules/line/mixin/SelectFocus'
import KeyDown from '@/modules/line/mixin/KeyDown'
import Forex from '@/modules/line/mixin/Forex'

export default Vue.extend({
    name: 'kus-line-grid-forex-string',
    mixins: [SelectFocus, KeyDown, Forex],
    data: () => ({
        menuprops: { maxWidth: 150, minWidth: 150 },
        search: '',
        value: null
    }),
    computed: {
        items() {
            const f = this.$store.getters['linegridsel/forex']
            return f && f.currencies
        },

        source() {
            const f = this.$store.getters['linegridsel/forex']
            return f && f.source
        },

        name() {
            const f = this.$store.getters['linegridsel/forex']
            return f && f.name
        }
    },
    created() {
        this.value = this.params.value
    },
    mounted() {
        if (this.params.context && this.params.context.attach) {
            this.menuprops.contentClass = 'maxZindex'
        }
        this.value = this.params.value
        this.$store.dispatch('linegridsel/forex', { date: moment(this.params.data.opDate || undefined) })
            .then(res => this.mixinSelectFocus())

        this.attach = this.$refs.attach
    },
    methods: {
        onKeyDown(event) {
            this.forexKeyDown(event)
            this.mixinKeyDown(event)
        },
        getValue() {
            return this.value || null
        },
        isPopup() {
            return false
        }
    }
})
</script>

<style lang="sass">
.kus-line-grid-forex-string
    .v-input__append-inner
        display: none !important

    .v-input__append-outer
        margin-top: 5px !important
        margin-left: 4px
        margin-right: 4px

</style>
