<template>
    <v-dialog v-model="show" max-width="450">
        <template #activator="{ on }">
            <v-btn
                v-on="on"
                color="red"
                :disabled="disabled"
                :icon="!fab"
                :text="!fab"
                :fab="fab"
                :dark="fab"
                :small="fab || small">
                <v-icon :small="small">$vuetify.icons.baseDelete</v-icon>
            </v-btn>
        </template>
        <v-card :loading="loading ? 'secondary' : false">
            <kus-card-title color="red lighten-1" :title="title" @close="show = false" />
            <v-card-text>
                <slot name="content"></slot>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="show = false" :disabled="disabled" text color="grey">
                    {{ $t('base.no') }}
                </v-btn>
                <v-spacer />
                <v-btn @click="remove" :disabled="disabled" text color="red">
                    {{ $t('base.yes') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'

export default {
    name: 'kus-card-remove-btn',
    components: { KusCardTitle },
    props: {
        title: { type: String },
        disabled: { type: Boolean },
        loading: { type: Boolean },
        fab: { type: Boolean },
        small: { type: Boolean },
        direct: { type: Boolean }
    },
    data: () => ({
        show: false
    }),
    methods: {
        remove() {
            this.$emit('remove')
        }
    }
}
</script>
