<template>
    <v-autocomplete
        v-model="selected"
        :label="label"
        :items="items"
        :filter="filter"
        :search-input.sync="search"
        :disabled="disabled"
        item-value="code"
        chips
        multiple>
        <template #selection="data">
            <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                :disabled="disabled"
                small
                close
                @click="data.select"
                @click:close="remove(data.item)">
                {{ data.item.code }} {{ data.item.name }}
            </v-chip>
        </template>
        <template #item="data">
            <v-list-item-action class="account-code">
                {{ data.item.code }}
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="account-type">
                {{ $t(`lines.account.types.${data.item.type}`) }}
            </v-list-item-action>
        </template>
    </v-autocomplete>
</template>

<script>
import lodash from 'lodash'

export default {
    name: 'kus-vat-accounts-selector',
    props: {
        value: { type: Array },
        type: { type: String },
        label: { type: String },
        disabled: { type: Boolean }
    },
    data: () => ({
        search: null
    }),
    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        },
        items() {
            return this.$store.getters['linegridsel/accounts'].filter(account => {
                return account.type === this.type
            })
        },
        selected: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    watch: {
        selected: 'onChangeDebounce'
    },
    mounted() {
        this.$store.dispatch('linegridsel/accounts', { clientId: this.clientId })
    },
    methods: {
        filter(item, queryText, itemText) {
            const query = lodash.deburr(queryText || '').toLowerCase()
            const keys = ['name', 'code']

            for (let i = 0; i <= keys.length; i += 1) {
                if (lodash.deburr(item[keys[i]]).toLowerCase().indexOf(query) !== -1) {
                    return true
                }
            }
            return false
        },

        remove(item) {
            const index = this.selected.findIndex(i => i === item.code)
            if (index !== -1) {
                this.selected.splice(index, 1)
            }
        },

        onChangeDebounce: lodash.debounce(function() {
            this.$emit('change', this.selected)
        }, 1000)
    }
}
</script>
