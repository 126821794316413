<template>
    <v-card scrollable>
        <kus-card-title :title="$t('result.menu')" :dismissible="false" hide-spacer>
            <template #buttons>
                <span class="white--text kus-separator mx-3">|</span>
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-btn small text icon dark to="line" v-on="on" :disabled="loading">
                            <v-icon small>$vuetify.icons.lineMenu</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('lines.menu') }}</span>
                </v-tooltip>
                <span class="white--text kus-separator mx-3">|</span>
                <kus-line-grid-topbar-balance />
                <kus-line-grid-topbar-result />
                <kus-line-grid-topbar-vat />
                <span class="white--text kus-separator mr-3">|</span>
                <v-tooltip v-if="!disabled" bottom>
                    <template #activator="{ on }">
                        <v-btn text icon dark small @click="addGroup" v-on="on" :disabled="loading">
                            <v-icon small>$vuetify.icons.baseAdd</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('result.group.add') }}</span>
                </v-tooltip>
                <v-tooltip v-if="!disabled" bottom>
                    <template #activator="{ on }">
                        <v-btn small text icon dark @click="refreshSchema()" v-on="on" :disabled="loading">
                            <v-icon small>$vuetify.icons.balanceRefresh</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('balance.refresh') }}</span>
                </v-tooltip>
                <v-btn text icon dark small v-if="disabled" @click="download">
                    <v-icon small>$vuetify.icons.balanceDownload</v-icon>
                </v-btn>
                <v-btn text icon dark small
                    v-if="isAdmin"
                    @click="disabled ? disabled = !disabled : saveSchema()"
                    :disabled="loading">
                    <v-icon v-if="disabled" small>$vuetify.icons.baseEdit</v-icon>
                    <v-icon v-else small>$vuetify.icons.baseSave</v-icon>
                </v-btn>
            </template>
        </kus-card-title>
        <v-card-text v-if="disabled">
            <kus-result-date-selector v-model="dates" :disabled="!isAdmin" @save="loadBalance(true)" />
            <v-switch v-model="inverseSign" :disabled="!isAdmin" :label="$t('balance.inverseSign')" hide-details />
        </v-card-text>
        <kus-result-group
            v-if="!disabled"
            class="first"
            ref="list"
            group-account-name="account"
            :groups="groups" />

        <kus-result-display v-else
            :groups="groups"
            :headers="datesDisplay"
            :inverse-sign="inverseSign"
            @expanded="onToggleExpand" />

        <kus-result-remove-group-dialog @remove="removeGroup" />
    </v-card>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusLineGridTopbarBalance from '@/modules/line/gridTopbar/KusLineGridTopbarBalance'
import KusLineGridTopbarResult from '@/modules/line/gridTopbar/KusLineGridTopbarResult'
import KusLineGridTopbarVat from '@/modules/line/gridTopbar/KusLineGridTopbarVat'
import KusResultDisplay from '@/modules/result/KusResultDisplay'
import KusResultGroup from '@/modules/result/KusResultGroup'
import KusResultRemoveGroupDialog from '@/modules/result/KusResultRemoveGroupDialog'
import KusResultDateSelector from '@/modules/result/KusResultDateSelector'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import lodash from 'lodash'

export default {
    name: 'kus-result',
    components: {
        KusCardTitle,
        KusLineGridTopbarBalance,
        KusLineGridTopbarResult,
        KusLineGridTopbarVat,
        KusResultDisplay,
        KusResultGroup,
        KusResultRemoveGroupDialog,
        KusResultDateSelector
    },
    data: () => ({
        pivot: false,
        disabled: true,
        loading: false,
        export: [],
        config: {},
        dates: [],
        datesDefault: [[
            moment().set({
                year: Number(moment().subtract(1, 'year').format('YYYY')),
                month: 0,
                date: 1
            }).format('YYYY-MM-DD'),
            moment().set({
                year: Number(moment().subtract(1, 'year').format('YYYY')),
                month: 11,
                date: 31
            }).format('YYYY-MM-DD')
        ], [
            moment().set({
                year: Number(moment().subtract(2, 'year').format('YYYY')),
                month: 0,
                date: 1
            }).format('YYYY-MM-DD'),
            moment().set({
                year: Number(moment().subtract(2, 'year').format('YYYY')),
                month: 11,
                date: 31
            }).format('YYYY-MM-DD')
        ]],
        groups: [],
        schema: []
    }),

    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        },

        tokenQuery() {
            return this.$store.getters['auth/tokenQuery']
        },

        datesDisplay() {
            return this.dates
                .filter(date => date && date.length === 2)
        },

        groupBalanceId() {
            return this.$store.getters['bal/groupBalanceId']
        },

        isAdmin() {
            return this.$store.getters['auth/is']('admin')
        },

        inverseSign: {
            get() {
                return this.config.sign
            },
            set(v) {
                this.$set(this.config, 'sign', v)
                return this.$store.dispatch('pref/save', { type: 'result-config', clientId: this.clientId, value: { sign: v } })
            }
        }
    },

    mounted() {
        this.loading = true
        this.$store.commit('cls/add', { type: 'main', cls: 'kus-scrollable' })
        return Promise
            .all([
                this.loadPreferences(),
                this.loadSchema()
            ])
            .then(() => this.loadBalance(true))
            .finally(() => (this.loading = false))
    },

    beforeDestroy() {
        this.$store.commit('cls/remove', { type: 'main', cls: 'kus-scrollable' })
    },

    methods: {

        download() {
            const ids = this.export.reduce((str, id) => `${str}&id=${id}`, 'id=&id=')
            const dates = JSON.stringify(this.dates.reduce((acc, date) => {
                if (!date || date.length !== 2) {
                    return acc
                }
                acc.push({
                    from: date[0],
                    to: date[1]
                })
                return acc
            }, []))
            const url = `${this.$api.defaults.baseURL}/clients/${this.clientId}/result/export?${this.tokenQuery}&isign=${this.inverseSign}&dates=${dates}&${ids}`
            window.location.href = url
        },

        onToggleExpand(group) {
            const index = this.export.indexOf(group.id)
            if (index === -1 && !group.expanded) {
                return this.export.push(group.id)
            }
            if (index !== -1 && group.expanded) {
                this.export.splice(index, 1)
            }
        },

        loadPreferences() {
            return Promise
                .all([
                    this.$store.dispatch('pref/get', { type: 'result-dates', clientId: this.clientId, defaultTo: this.datesDefault }),
                    this.$store.dispatch('pref/get', { type: 'result-config', clientId: this.clientId, defaultTo: {} })
                ])
                .then(([pref, config]) => {
                    this.dates = pref
                    this.config = config
                })
        },

        loadSchema() {
            return this.$api.get(`/clients/${this.clientId}/result/schema`)
                .then(res => (this.schema = res.data))
        },

        saveSchema() {
            this.loading = true
            return this.$api.put(`/clients/${this.clientId}/result/schema`, this.groups)
                .then(() => {
                    this.disabled = true
                    this.schema = []
                    this.groups = []
                    return this.loadSchema()
                })
                .then(() => this.loadBalance())
                .finally(() => (this.loading = false))
        },

        refreshSchema() {
            this.loading = true
            return this.$api.post(`/clients/${this.clientId}/result/schema/refresh`)
                .then(() => {
                    this.schema = []
                    this.groups = []
                    return this.loadSchema()
                })
                .then(() => this.loadBalance())
                .finally(() => (this.loading = false))
        },

        loadBalance(savePref) {
            const q = JSON.stringify(this.dates.reduce((acc, date) => {
                if (!date || date.length !== 2) {
                    return acc
                }
                acc.push({
                    from: date[0],
                    to: date[1]
                })
                return acc
            }, []))
            return this.$api.get(`/clients/${this.clientId}/result?dates=${q}`)
                .then(res => {
                    // on merge les valeurs dans les comptes
                    this.groups = this.parseGroups(lodash.cloneDeep(this.schema), res.data, Object.keys(res.data))
                    if (savePref === true) {
                        return this.$store.dispatch('pref/save', { type: 'result-dates', clientId: this.clientId, value: this.dates })
                    }
                })
        },

        parseGroups(groups, balances, valid) {
            return (groups || []).map(group => {
                if (group.type === 'account') {
                    const code = Number(group.code)
                    this.$set(group, 'balances', balances[code] ? balances[code].balances : {})
                }
                this.$set(group, 'visible', valid.indexOf(String(group.code)) !== -1 || group.id === this.groupBalanceId || group.type !== 'account')
                group.groups = this.parseGroups(group.groups, balances, valid)
                return group
            })
        },

        addGroup() {
            const id = uuidv4()
            this.groups.splice(0, 0, {
                id: id,
                type: 'group',
                code: null,
                visible: true,
                balances: {},
                groups: []
            })
            this.$nextTick(() => this.$refs.list.focusFirstGroup())
        },

        removeGroup({ parent, accounts, group }) {
            const p = parent || this
            const index = p.groups.findIndex(g => g.id === group.id)
            if (index !== -1) {
                p.groups.splice(index, 1)
            }
        }
    }
}
</script>
