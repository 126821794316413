<template>
    <v-text-field
        ref="field"
        type="password"
        name="password"
        v-model="password"
        :rules="rs"
        :label="label" />
</template>

<script>
import KusBaseRules from '@/modules/base/KusBaseRules'

export default {
    name: 'kus-user-form-password',
    props: {
        value: { type: String },
        label: { type: String },
        required: { type: Boolean },
        rulesEq: { type: String },
        rulesEqMsg: { type: String }
    },
    computed: {
        password: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        rs() {
            const rules = [KusBaseRules.password]
            if (this.required) {
                rules.push(KusBaseRules.required)
            }
            if (this.rulesEq) {
                rules.push(KusBaseRules.eq(this.rulesEq, this.rulesEqMsg))
            }
            return rules
        }
    }
}
</script>
