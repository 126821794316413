import moment from 'moment'
import { LicenseManager } from 'ag-grid-enterprise'

export default {

    mount(vue, store, vuetify) {
        vue.$config = {
            version: process.env.VUE_APP_VERSION,
            api: process.env.VUE_APP_API,
            aggrid: {
                license: process.env.VUE_APP_AGGRID_LICENSE
            }
        }
        vue.$api.defaults.baseURL = vue.$config.api

        vue.$constant = {
            ENTER: 13,
            TAB: 9,
            UP: 38,
            DOWN: 40
        }

        return vue.$api.get('/')
            .then(res => {
                Object.assign(vue.$config, (res && res.data) || {})
                const region = `${vue.$config.i18n.user}-${vue.$config.i18n.region}`

                store.commit('ln/region', { region: vue.$config.i18n.user })

                moment.locale([region, vue.$config.i18n.user])

                LicenseManager.setLicenseKey(vue.$config.aggrid.license)

                if (vue.$config.style.colour) {
                    vuetify.framework.theme.themes.light.primary = vue.$config.style.colour
                }

                if (!store.getters['auth/token']) {
                    return vue.$config
                }
                return store.dispatch('auth/get')
                    .catch(() => store.commit('auth/logout'))
                    .then(() => vue.$config)
            })
    }
}
