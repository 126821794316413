<template>
    <v-form ref="form" v-model="valid" @submit.stop.prevent="submit">
        <v-card :loading="loading ? 'secondary' : false">
            <v-card-text>
                <kus-user-form-email v-model="user.email" />
                <kus-user-form-name
                    v-model="user.firstname"
                    :label="$t('users.form.firstname')"
                    name="firstname" />

                <kus-user-form-name
                    v-model="user.lastname"
                    :label="$t('users.form.lastname')"
                    name="lastname" />

            </v-card-text>
            <v-card-actions>
                <v-btn
                    :disabled="!valid"
                    type="submit"
                    class="kus-btn-center"
                    color="primary">{{ $t('base.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import KusUserFormEmail from '@/modules/user/form/KusUserFormEmail'
import KusUserFormName from '@/modules/user/form/KusUserFormName'

export default {
    name: 'kus-user-account',
    components: {
        KusUserFormEmail,
        KusUserFormName
    },
    data: () => ({
        valid: false,
        loading: false
    }),
    computed: {
        user() {
            return this.$store.getters['auth/user']
        }
    },
    methods: {

        submit() {
            if (!this.$refs.form.validate()) {
                return
            }
            this.loading = true
            return this.$api.put('/accounts', { ...this.user })
                .then(() => {
                    this.$store.commit('msg/show', { message: this.$i18n.t('users.account.saved') })
                })
                .finally(() => (this.loading = false))
        }
    }
}
</script>
