<template>
    <v-select
        max-width="100"
        v-model="role"
        :items="roles"
        :rules="[required]"
        :disabled="disabled" />
</template>

<script>
import KusBaseRules from '@/modules/base/KusBaseRules'

export default {
    name: 'kus-client-access-role',
    props: {
        value: { type: String },
        disabled: { type: Boolean }
    },

    data: () => ({
        required: KusBaseRules.required
    }),

    computed: {
        role: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        roles() {
            return this.$store.getters['caccess/roles'].map(role => ({
                value: role,
                text: this.$i18n.t(`clients.access.roles.${role}`)
            }))
        }
    }
}
</script>
