<template>
    <v-card flat class="kus-content--container" max-width="800" :loading="loading">
        <v-card-title>{{ content.title }}</v-card-title>
        <v-card-text>
            <div v-marked="content.body" class="kus-content"></div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'kus-content-page',

    props: {
        contentId: { type: String }
    },

    data: () => ({
        content: {},
        loading: false
    }),

    watch: {
        contentId: 'load'
    },

    mounted() {
        return this.load()
    },

    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('content/get', { id: this.contentId })
                .then(content => (this.content = content))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style lang="sass">
.kus-content--container
    counter-reset: chapter

.kus-content
    h4
        counter-increment: chapter
        counter-reset: section
        margin: 2em 0
        padding-left: 2em

    ol
        list-style: none
        margin-bottom: 1em

    ol li
        counter-increment: section
        padding-bottom: 0.5em
        padding-inline-start: 1em

    ol li::marker
        content: counter(chapter) "." counter(section) " "

</style>
