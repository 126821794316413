import Vue from 'vue'
import moment from 'moment'
import store from './store'

const numFormatter = {}

Vue.filter('number', (value, opts = { minimumFractionDigits: 2, maximumFractionDigits: 2 }) => {
    const lang = store.getters['ln/region'] === 'fr'
        ? 'de-ch' // le fr-ch n'a pas les bon format (5'000.55 s'écrit 5 000,55)
        : store.getters['ln/region']

    const hash = JSON.stringify({ lang, ...opts })

    if (!numFormatter[hash]) {
        numFormatter[hash] = new Intl.NumberFormat(lang, opts)
    }
    if (!value && value !== 0) {
        return ''
    }
    if (typeof value === 'object') {
        value = value.value()
    }
    return numFormatter[hash].format(value)
})

Vue.filter('date', (value, format, input) => {
    return moment(value, input).format(format)
})

Vue.filter('formatSize', size => {
    if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
    } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
    } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB'
    } else if (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB'
    }
    return (size || 0).toString() + ' B'
})
