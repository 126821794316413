<template>
    <div>
        <v-autocomplete
            class="pt-0 mt-0"
            ref="field"
            v-model="value"
            :items="items"
            :menu-props="menuprops"
            :filter="filter"
            :search-input.sync="search"
            item-value="code"
            hide-details
            return-object
            auto-select-first
            single-line flat solo
            @keydown="onKeyDown">
            <template #selection="data">
                <span :class="{ selected: data.selected }">{{ data.item.code }} {{ data.item.name }}</span>
            </template>
            <template #item="data">
                <v-list-item-action class="account-code">
                    {{ data.item.code }}
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="account-type">
                    {{ $t(`lines.account.types.${data.item.type}`) }}
                </v-list-item-action>
            </template>
            <template #no-data>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <code v-if="accountCreate" class="ok">[{{ accountCreate.code }}] [{{ accountCreate.name }}] [{{ type(accountCreate.type) }}]</code>
                            <code v-else>{{ $t('lines.account.add', { balance: balanceCode, result: resultCode }) }}</code>
                        </v-list-item-title>
                        <v-list-item-subtitle v-html="$t('lines.account.addEnter')"></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import Vue from 'vue'
import lodash from 'lodash'
import KeyDown from '@/modules/line/mixin/KeyDown'
import SelectFocus from '@/modules/line/mixin/SelectFocus'
import SelectFirst from '@/modules/line/mixin/SelectFirst'

export default Vue.extend({
    name: 'kus-line-grid-account',
    mixins: [KeyDown, SelectFocus, SelectFirst],
    data: () => ({
        balanceCode: 'b',
        resultCode: 'r',
        menuprops: {},
        value: '',
        search: '',
        params: null
    }),
    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        },
        accountCreate() {
            return this.extractData(this.search)
        },
        items() {
            return this.$store.getters['linegridsel/accounts']
        },
        regexp() {
            return new RegExp(`^([^ ]+) (.+)([${this.balanceCode}${this.resultCode}])$`)
        }
    },
    mounted() {
        if (this.params.context && this.params.context.attach) {
            this.menuprops.contentClass = 'maxZindex'
        }
        this.value = this.params.value
        this.$store.dispatch('linegridsel/accounts', { clientId: this.clientId })
            .then(res => {
                if (this.params.charPress !== null) {
                    this.value = ''
                } else {
                    this.value = this.items.find(item => item.code === (this.params.value && this.params.value.code))
                }
                this.$nextTick(() => {
                    this.mixinFocus()
                    if (this.params.charPress !== null) {
                        this.$nextTick(() => (this.search = this.params.charPress))
                    }
                })
            })

        this.attach = this.$refs.attach
    },
    methods: {
        onKeyDown(event) {
            this.mixinKeyDownMenu(event)
        },
        extractData(str) {
            // création d'un nouveau compte
            const test = lodash.trim(str)
            if (!this.regexp.test(test)) {
                return null
            }
            const result = test.match(this.regexp)
            return {
                code: lodash.trim(result[1]),
                name: lodash.trim(result[2]),
                type: result[3] === this.balanceCode ? 'balance' : 'result'
            }
        },
        getValue() {
            const value = this.search || this.value
            if (value && typeof value === 'string') {
                // création d'un nouveau compte
                const result = this.extractData(value)
                if (!result && this.value) {
                    return this.value
                }
                if (!result) {
                    return ''
                }
                const found = this.items.find(item => item.code === result.code)
                if (found && (found.name !== result.name || found.type !== result.type)) {
                    throw new Error(this.$i18n.t('lines.account.errorExistDiff'))
                }
                return result
            }
            return this.value
        },
        isPopup() {
            return false
        },
        type(type) {
            return this.$i18n.t(`lines.account.types.${type}`)
        },
        filter(item, queryText, itemText) {
            const query = lodash.deburr(queryText || '').toLowerCase()
            const keys = ['name', 'code']

            for (let i = 0; i <= keys.length; i += 1) {
                if (lodash.deburr(item[keys[i]]).toLowerCase().indexOf(query) !== -1) {
                    return true
                }
            }
            return false
        }
    }
})
</script>

<style lang="sass" scoped>
.account-code
    font-size: 80%
    font-weight: bold !important

.account-type
    font-size: 80%
    font-style: italic
    color: #777

.selected
    border: 1px solid #999
    border-radius: 8px
    padding-left: 5px
    padding-right: 5px
    color: #999

.ok
    color: #090

</style>
