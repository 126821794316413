<template>
    <div @keydown="onKeyDown">
        <v-text-field
            ref="field"
            class="pt-0 mt-0"
            v-model="value"
            single-line flat solo
            hide-details />
    </div>
</template>

<script>
import Vue from 'vue'
import SelectFocus from '@/modules/line/mixin/SelectFocus'
import KeyDown from '@/modules/line/mixin/KeyDown'

export default Vue.extend({
    name: 'kus-line-grid-string',
    mixins: [SelectFocus, KeyDown],
    data: () => ({
        value: null
    }),
    created() {
        this.value = this.params.value
    },
    mounted() {
        this.mixinSelectFocus()
    },
    methods: {
        onKeyDown(event) {
            this.mixinKeyDown(event)
        },
        getValue() {
            return this.value || null
        },
        isPopup() {
            return false
        }
    }
})
</script>
