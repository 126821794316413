<template>
    <v-dialog v-model="show" max-width="800" scrollable>
        <kus-document-preview-card
            v-if="show"
            :file="file"
            @close="show = false" />
    </v-dialog>
</template>

<script>
import KusDocumentPreviewCard from '@/modules/document/KusDocumentPreviewCard'

export default {
    name: 'kus-document-preview',
    components: { KusDocumentPreviewCard },
    data: () => ({
        info: false
    }),
    computed: {
        show: {
            get() {
                return !!this.$store.getters['doc/previewPopup']
            },
            set(v) {
                this.$store.commit('doc/preview', { popup: false })
            }
        },
        file() {
            return this.$store.getters['doc/preview'] || {}
        }
    }
}
</script>
