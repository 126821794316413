<template>
    <v-app>
        <v-main>
            <v-container fluid>
                <div class="main-content">
                    <div class="main-content-center">
                        <h4>Serveur inaccessible, nous sommes sur le qui-vive! Veuillez nous excuser.</h4>
                        <p>{{ errorMsg }}</p>
                        <p><v-btn @click="reload()" color="primary" text>Réessayer</v-btn></p>
                    </div>
                </div>
            </v-container>
        </v-main>
        <v-footer app>
            <v-spacer />
            <span>
                &copy;{{ year }},
                <a href="https://www.kushim.ch/" target="_blank">Kushim</a>,
                <a href="https://www.voilab.ch" target="_blank">Voilab</a>
            </span>
        </v-footer>
    </v-app>
</template>

<script>
import moment from 'moment'

export default {
    name: 'kus-error',
    data: () => ({
        year: moment().format('YYYY')
    }),
    computed: {
        errorMsg() {
            return this.currentError.message
        }
    },
    mounted() {
        // eslint-disable-next-line
        console.warn(this.currentError)
    },
    methods: {
        reload() {
            window.location.reload()
        }
    }
}
</script>

<style lang="sass">
.main-content
    height: 100%
    display: flex

.main-content-center
    width: 100%
    text-align: center

.main-content-center
    margin: 10em auto 2em auto !important
    max-width: 350px
    display: block
    padding: 1px

.footer
    justify-content: flex-end

</style>
