<template>
    <div>
        <v-snackbar v-for="msg in messages" :key="msg.id"
            :top="true"
            :timeout="msg.timeout"
            :color="msg.color"
            :multi-line="true"
            v-model="msg.show">
            {{ msg.message }}
            <template #action="{ attrs }">
                <v-btn text icon @click="msg.show = false" v-bind="attrs">
                    <v-icon>$vuetify.icons.baseClose</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    name: 'kus-base-message',
    computed: {
        messages() {
            return this.$store.getters['msg/messages']
        }
    }
}
</script>
