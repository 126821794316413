export default {
    namespaced: true,

    state: {
        removeDialog: false,
        group: null,
        parent: null,
        accounts: []
    },

    getters: {
        removeDialog: state => state.removeDialog,
        group: state => state.group,
        parent: state => state.parent,
        accounts: state => state.accounts
    },

    mutations: {
        show(state, data) {
            state.removeDialog = true
            state.group = data.group
            state.parent = data.parent
            state.accounts = data.accounts
        },

        hide(state) {
            state.removeDialog = false
            state.group = null
            state.parent = null
            state.accounts = []
        }
    }
}
