<template>
    <v-container fluid class="fill-height pa-0">
        <v-row no-gutters class="fill-height">
            <v-col cols="12" order-md="2">
                <kus-home-public-login class="align-self-stretch fill-height" />
            </v-col>
            <v-col cols="12" order-md="1">
                <kus-home-public-info class="align-self-stretch fill-height" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import KusHomePublicInfo from '@/modules/home/public/KusHomePublicInfo'
import KusHomePublicLogin from '@/modules/home/public/KusHomePublicLogin'

export default {
    name: 'kus-home-public',
    components: { KusHomePublicInfo, KusHomePublicLogin }
}
</script>
