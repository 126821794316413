<template>
    <v-dialog v-model="show" scrollable max-width="650">
        <v-card>
            <kus-card-title :title="message" color="red" @close="show = false" />
            <v-card-text>
                <ul>
                    <li v-for="(d, i) in detail" :key="i">{{ d }}</li>
                </ul>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import lodash from 'lodash'

export default {
    name: 'kus-base-error',
    components: { KusCardTitle },
    computed: {
        show: {
            get() {
                return this.$store.getters['error/show']
            },
            set(v) {
                this.$store.commit('error/hide')
            }
        },
        detail() {
            return lodash.isArray(this.$store.getters['error/detail'])
                ? this.$store.getters['error/detail']
                : [this.$store.getters['error/detail']]
        },
        message() {
            return this.$store.getters['error/message']
        }
    }
}
</script>
