import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        accesses: [],
        roles: ['admin', 'subscriber'],
        rolesDoc: ['import', 'rename', 'remove', 'move', 'export']
    },

    getters: {
        list: state => state.accesses,
        roles: state => state.roles,
        rolesDoc: state => state.rolesDoc
    },

    mutations: {
        list(state, data) {
            state.accesses = data.access
        },

        remove(state, data) {
            const index = state.accesses.findIndex(a => {
                if (!data.access.id) {
                    return a.email === data.access.email
                }
                return a.id === data.access.id
            })
            if (index !== -1) {
                state.accesses.splice(index, 1)
            }
        },

        setSentAt(state, data) {
            const access = state.accesses.find(a => a.id === data.accessId)
            if (!access) {
                return
            }
            access.meta.sentAt = (new Date()).toISOString()
        },

        add(state, data) {
            state.accesses.push({
                clientId: data.clientId,
                email: '',
                role: 'subscriber',
                documentRights: [],
                documentRightsDisabled: false,
                write: {
                    disabled: false,
                    unlimited: true,
                    from: null,
                    to: null
                },
                read: {
                    disabled: false,
                    unlimited: true,
                    from: null,
                    to: null
                },
                meta: {
                    sentAt: null
                }
            })
        }
    },

    actions: {
        get(context, data) {
            return Vue.prototype.$api.get(`/clients/access/${data.token}`)
                .then(res => res.data)
        },

        accept(context, data) {
            return Vue.prototype.$api.post(`/clients/access/${data.token}`)
        },

        invite(context, data) {
            return Vue.prototype.$api.post(`/clients/${data.clientId}/access/${data.accessId}/invite`)
                .then(res => {
                    context.commit('setSentAt', data)
                    return res
                })
        },

        list(context, data) {
            return Vue.prototype.$api.get(`/clients/${data.clientId}/access`)
                .then(res => {
                    context.commit('list', { access: res.data })
                    return res.data
                })
        },

        remove(context, data) {
            return Promise
                .resolve()
                .then(() => {
                    return data.access.id && Vue.prototype.$api.delete(`/clients/${data.access.clientId}/access/${data.access.id}`)
                })
                .then(() => {
                    context.commit('remove', { access: data.access })
                })
        },

        save(context, data) {
            const promise = data.access.id
                ? Vue.prototype.$api.put(`/clients/${data.access.clientId}/access/${data.access.id}`, data.access)
                : Vue.prototype.$api.post(`/clients/${data.access.clientId}/access`, data.access)

            return promise.then(res => {
                if (!data.access.id) {
                    data.access.id = res.data.id
                }
                return res.data
            })
        }
    }
}
