export default {
    namespaced: true,

    state: {
        app: [],
        main: [],
        header: []
    },

    getters: {
        app: state => state.app.join(' '),
        main: state => state.main.join(' '),
        header: state => state.header.join(' ')
    },

    mutations: {
        add(state, data) {
            state[data.type].push(data.cls)
        },

        remove(state, data) {
            state[data.type] = state[data.type].filter(cls => cls !== data.cls)
        }
    }
}
