import Vue from 'vue'
import i18next from 'i18next'
import VueI18Next from '@panter/vue-i18next'
import lodash from 'lodash'
import moment from 'moment'
import store from './store'

import lnFr from '../i18n/fr.json'

const I18nPlugin = {
    install(vue, options) {
        vue.mixin({
            computed: {
                $tr() {
                    return (data, ln) => {
                        ln = ln || store.get().getters['ln/current']
                        const content = lodash.trim(data[ln]) ? data[ln] : data[options.default]
                        return content
                    }
                },

                $tu() {
                    // unescape partiel de i18next
                    return (key, data) => {
                        return i18next.t(key, {
                            ...data,
                            interpolation: {
                                escape: (str) => {
                                    const _entityMap = {
                                        '&': '&amp;',
                                        '<': '&lt;',
                                        '>': '&gt;',
                                        '"': '&quot;',
                                        "'": '&#39;',
                                        '/': '&#x2F;'
                                    }
                                    return (str || '').replace(/[<"/]/g, s => _entityMap[s])
                                }
                            }
                        })
                    }
                }
            }
        })
    }
}

Vue.use(VueI18Next)

export default {

    init(config) {
        const ln = lang => lang.split('-')[0]
        return i18next
            .init({
                returnObjects: true,
                lng: ln(config.i18n.user),
                fallbackLng: ln(config.i18n.default),
                preload: [ln(config.i18n.user)],
                available: config.i18n.available.map(ln),
                resources: {
                    fr: { translation: lnFr },
                    en: { translation: {} },
                    de: { translation: {} },
                    it: { translation: {} }
                },
                interpolation: {
                    format(value, format, lng) {
                        if (format === 'num') {
                            return Vue.filter('num')(value)
                        }
                        if (format) {
                            return moment(value).format(format)
                        }
                        return value
                    }
                }
            })
            .then(() => {
                store.commit('ln/current', { current: ln(config.i18n.user) })
                store.commit('ln/available', { available: config.i18n.available.map(ln) })
                Vue.use(I18nPlugin, { default: ln(config.i18n.default) })
                return new VueI18Next(i18next)
            })
    }
}
