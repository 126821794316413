<template>
    <v-layout>
        <v-flex v-for="(group, i) in metadata" :key="`i${i}`">
            <v-list dense :dark="dark" class="kus-document-metadata">
                <v-list-item v-for="(key, j) in group" :key="`j${j}`">
                    <v-list-item-content class="grey--text py-0">{{ $t(`document.${key}`) }} :</v-list-item-content>
                    <v-list-item-content class="text-right py-0">
                        <span v-if="key === 'size'">
                            {{ file[key] | formatSize }}
                        </span>
                        <span v-else-if="key === 'flow'">
                            {{ file[key] === 'noassoc' ? $t('document.noassoc') : file[key] }}
                        </span>
                        <span v-else-if="['createdAt', 'updatedAt'].indexOf(key) !== -1">
                            {{ file[key] | date($t('document.dateFormat')) }}
                        </span>
                        <span v-else>
                            {{ file[key] }}
                        </span>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: 'kus-document-metadata',
    props: {
        file: { type: Object },
        dark: { type: Boolean }
    },
    data: () => ({
        metadata: [
            ['flow', 'nb', 'uploadName', 'size', 'contentType'],
            ['createdAt', 'createdBy', 'updatedAt', 'updatedBy']
        ]
    })
}
</script>

<style lang="sass">
.kus-document-metadata .v-list-item
    min-height: 0
    font-size: 0.9em

</style>
