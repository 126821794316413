<template>
    <v-bottom-sheet class="kus-document-upload--canvas"
        v-model="show"
        hide-overlay
        persistent
        inset
        :max-width="500">
        <v-card>
            <kus-card-title color="secondary"
                :title="$t(`document.upload.${finished ? 'finished' : 'title'}`, { total: filesUploadLength, done: filesDone.length, count: filesError.length })"
                @close="show = false" />
            <v-card-text class="kus-content">
                <kus-document-upload-list />
            </v-card-text>
        </v-card>
    </v-bottom-sheet>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusDocumentUploadList from '@/modules/document/KusDocumentUploadList'

export default {
    name: 'kus-document-snack',
    components: { KusCardTitle, KusDocumentUploadList },
    data: () => ({
        show: false
    }),
    computed: {
        filesUpload() {
            return this.$store.getters['doc/filesUpload']
        },
        filesUploadLength() {
            return this.filesUpload.length
        },
        filesDone() {
            return this.filesUpload.filter(f => f.success || f.error)
        },
        filesError() {
            return this.filesUpload.filter(f => f.error)
        },
        finished() {
            return this.filesUpload.filter(f => !f.success && !f.error).length === 0
        },
        succeeded() {
            return this.filesUpload.filter(f => !f.success).length === 0
        }
    },
    watch: {
        filesUploadLength(v) {
            this.show = v > 0
        },
        show(v) {
            !v && this.removeDoneFiles()
        }
    },
    methods: {
        removeDoneFiles() {
            if (this.succeeded) {
                this.$store.commit('doc/setFilesUpload', { files: [] })
            }
        }
    }
}
</script>

<style lang="sass">
.v-bottom-sheet.v-bottom-sheet--inset.v-dialog--persistent
    display: flex

    .kus-content
        max-height: 170px

    > .v-card
        display: flex
        flex: 1 1 100%
        flex-direction: column

        > .v-card__title
            flex: 1 0 auto

        > .v-card__text
            overflow-y: auto
            backface-visibility: hidden

</style>
