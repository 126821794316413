<template>
    <v-select
        ref="field"
        v-model="text"
        :label="label || $t('lines.search.types')"
        :items="items"
        clearable />
</template>

<script>
export default {
    name: 'kus-line-search-type',
    props: {
        value: { type: String },
        label: { type: String },
        skip: { type: Array, default: () => [] }
    },
    computed: {
        text: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        items() {
            const status = this.$i18n.t('lines.searchtypes')
            return Object.entries(status)
                .filter(d => this.skip.indexOf(d[0]) === -1)
                .map(d => ({
                    value: d[0],
                    text: d[1]
                }))
        }
    }
}
</script>
