<template>
    <v-card v-resize="onResize">
        <kus-card-title :title="$t('lines.menu')" :dismissible="false" hide-spacer>
            <template #before-buttons>
                <slot name="toptoolbar"></slot>
            </template>
            <template #buttons>
                <span class="white--text kus-separator mx-3">|</span>
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-btn small text icon dark :input-value="!pivot" v-on="on" :disabled="loading" @click="selectPivot(null)">
                            <v-icon small>$vuetify.icons.lineMenu</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('lines.menu') }}</span>
                </v-tooltip>
                <kus-line-grid-topbar-pivot
                    v-model="pivot"
                    @input="selectPivot"
                    :active="!!pivot"
                    :current="currentPivotConfig" />
                <span class="white--text kus-separator mx-3">|</span>
                <kus-line-grid-topbar-balance />
                <kus-line-grid-topbar-result />
                <kus-line-grid-topbar-vat />
                <span class="white--text kus-separator mr-3">|</span>
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-btn small text icon dark @click="popup()" v-on="on" class="mr-3">
                            <v-icon small>$vuetify.icons.baseAdd</v-icon>
                        </v-btn>
                    </template>
                    {{ $t('lines.add.btn') }}
                </v-tooltip>
                <kus-line-grid-topbar-import
                    @upload:start="uploadStart"
                    @upload:error="uploadError"
                    @upload:success="uploaded" />
                <kus-line-grid-topbar-export :search="search" />
                <kus-line-grid-topbar-menu
                    id="kusgridtopmenubtn"
                    :showDeleted="showDeleted"
                    @reloadDeleted="reloadDeleted"
                    @adjustColumns="adjustColumns" />
                <v-spacer />
                <v-btn
                    v-if="lastSearch !== null"
                    small
                    rounded
                    depressed
                    @click="autoFilterRollback"
                    color="accent"
                    class="kus-auto-filter--btn">
                    <v-icon small left color="red">$vuetify.icons.baseClose</v-icon>
                    {{ $t('lines.search.cancelAutoFilter') }}
                </v-btn>
                <kus-line-search v-model="search" ref="searchField" />
            </template>
        </kus-card-title>
        <v-card-text class="pa-0">
            <kus-line-grid
                v-show="!pivot"
                ref="grid"
                @mounted="mounted"
                @removeLine="removeLine"
                @copyLine="copyLine"
                @sumChanged="sumChanged"
                @autoFilter="autoFilter"
                @sortChanged="sortChanged"
                header-type="main"
                :skipHeadersChanges="skipHeadersChanges"
                @update:skipHeadersChanges="v => skipHeadersChanges = v"
                :getRowStyle="getRowStyle"
                :data="data"
                :disableEdit="editing"
                :statusPanel="statusPanel"
                :sort="sort"
                :show-deleted="showDeleted"
                autoHeight
                enterMovesDownAfterEdit
                show-separation />

            <kus-line-grid
                v-show="!!pivot"
                ref="pivotGrid"
                @sumChanged="sumChanged"
                @pivotChanged="setCurrentPivotConfig"
                header-type="pivot"
                :pivot="!!pivot"
                :data="pivotData"
                :disableEdit="true"
                autoHeight
                enterMovesDownAfterEdit
                disable-cell-click />

            <kus-line-remove-popup :remove-mode="showDeleted" />
            <kus-document-flow-popup />
        </v-card-text>
    </v-card>
</template>

<script>
import KusLineGrid from '@/modules/line/grid/KusLineGrid'
import KusLineRemovePopup from '@/modules/line/removePopup/KusLineRemovePopup'
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusLineSearch from '@/modules/line/KusLineSearch'
import KusLineGridTopbarImport from '@/modules/line/gridTopbar/KusLineGridTopbarImport'
import KusLineGridTopbarExport from '@/modules/line/gridTopbar/KusLineGridTopbarExport'
import KusLineGridTopbarPivot from '@/modules/line/gridTopbar/KusLineGridTopbarPivot'
import KusLineGridTopbarBalance from '@/modules/line/gridTopbar/KusLineGridTopbarBalance'
import KusLineGridTopbarResult from '@/modules/line/gridTopbar/KusLineGridTopbarResult'
import KusLineGridTopbarVat from '@/modules/line/gridTopbar/KusLineGridTopbarVat'
import KusLineGridTopbarMenu from '@/modules/line/gridTopbar/KusLineGridTopbarMenu'
import KusDocumentFlowPopup from '@/modules/document/KusDocumentFlowPopup'
import lodash from 'lodash'

export default {
    name: 'kus-line-main-view',
    props: {
        height: { type: Number }
    },
    components: {
        KusCardTitle,
        KusLineGrid,
        KusLineRemovePopup,
        KusLineSearch,
        KusLineGridTopbarImport,
        KusLineGridTopbarExport,
        KusLineGridTopbarPivot,
        KusLineGridTopbarBalance,
        KusLineGridTopbarResult,
        KusLineGridTopbarVat,
        KusLineGridTopbarMenu,
        KusDocumentFlowPopup
    },
    data: () => ({
        pivot: null,
        pivotData: [],
        pivotState: {},
        showDeleted: false,
        skipHeadersChanges: true,
        disableLoadLines: false,
        loading: false,
        search: null,
        sort: 'opDate',
        dir: 'asc',
        statusPanel: { statusPanel: 'gridStatusBar' },
        firstLoad: true,
        lastSearch: null
    }),
    computed: {
        data() {
            return this.$store.getters['linegrid/lines']
        },
        clientId() {
            return this.$store.getters['auth/client']
        },
        editing() {
            return this.$store.getters['lineedit/show']
        },
        headers() {
            return this.$store.getters['linegrid/headers']
        },
        sortField() {
            const field = this.headers.find(h => h.field === this.sort)
            return field?.sortField
        },
        currentPivotConfig() {
            if (!this.pivot) {
                return {}
            }
            return {
                sumType: this.$store.getters['linegrid/sumType'],
                sort: this.sort,
                search: this.search,
                state: this.pivotState
            }
        }
    },
    watch: {
        editing: 'onResize',
        search() {
            if (this.firstLoad) {
                return
            }
            return this.onTypeSearch()
        },
        pivot: 'onPivot',
        height: 'onResize'
    },
    methods: {

        mounted() {
            this.$refs.grid.setHeaders(this.headers)
            this.$refs.pivotGrid.setHeaders(this.headers)

            return this.loadData()
        },

        loadData() {
            const prefs = this.$store.getters['pref/getGrid'](this.clientId)
            this.sort = prefs.gs || 'opDate'
            this.dir = prefs.gd || 'asc'
            this.search = lodash.trim(prefs.gq, '=')
            this.showDeleted = prefs.ga

            if (this.search) {
                this.$refs.searchField.setSearch(prefs.gq, prefs.gv)
            }
            if (prefs.su) {
                this.$store.commit('linegrid/sumType', { type: prefs.su })
            }
            return this.onSearch()
        },

        reloadDeleted() {
            this.showDeleted = !this.showDeleted
            return this.onSearch()
        },

        getRowStyle(params) {
            if (params.node.data && params.node.data.deletedAt) {
                return { color: '#777', 'background-color': '#fee' }
            }
        },

        removeLine(params) {
            this.$store.commit('linegrid/removePopup', { line: params.node.data })
        },

        async copyLine(params) {
            // KUS-25: on cache l'edit avant de la remontrer avec la pièce copiée
            this.$store.commit('lineedit/show', {
                show: false
            })
            await this.$nextTick()
            this.$store.commit('lineedit/show', {
                show: true,
                copy: params.node.data.block
            })
        },

        toggleStatusBar(show) {
            const bar = this.$refs.grid.options().api.getStatusPanel('gridStatusBar')
            let instance = bar
            if (bar && bar.getFrameworkComponentInstance) {
                instance = bar.getFrameworkComponentInstance()
            }
            instance && instance.setVisible(show)
        },

        uploadStart() {
            this.loading = true
            this.$refs.grid.options().api.showLoadingOverlay()
        },

        uploadError() {
            this.loading = false
            this.$refs.grid.options().api.hideOverlay()
        },

        uploaded() {
            this.loading = false
            return this.loadData()
                .then(() => this.$refs.grid.options().api.hideOverlay())
        },

        popup() {
            this.onResize(true)
            // on n'appelle pas le /reset car ça fait double emploi avec le show
            // sauf si la popup est déjà ouverte
            this.editing && this.$store.commit('lineedit/reset')
            this.$store.commit('lineedit/show', { show: true, block: null })
        },

        goToBottom() {
            this.$nextTick(() => {
                this.$refs.grid.goToBottom()
            })
        },

        refresh(fields) {
            this.$refs.grid.refresh(fields)
        },

        adjustColumns() {
            const grid = this.pivot ? 'pivotGrid' : 'grid'
            this.$refs[grid].options().api.sizeColumnsToFit()
        },

        sortChanged(sorts) {
            if (!sorts.length) {
                return
            }
            const s = sorts[0]
            if ((this.sort && s.colId === this.sort) && (this.dir && s.sort === this.dir)) {
                return
            }
            this.sort = s.colId
            this.dir = s.sort || 'asc'
            this.onSearch()
        },

        selectPivot(pivot) {
            if (!pivot) {
                this.pivot = null
                return
            }
            this.disableLoadLines = true
            try {
                this.$refs.searchField.setSearch(pivot.search, !!pivot.search)
                this.$store.commit('linegrid/sumType', { type: pivot.sumType })
                this.sort = pivot.sort

                this.pivot = pivot

                const api = this.$refs.pivotGrid.options().columnApi
                api.setPivotMode(true)
                api.applyColumnState(pivot.state)

                // 600 pour passer au-delà du debounce sur le watcher this.search
                window.setTimeout(() => (this.disableLoadLines = false), 600)
            } catch (err) {
                this.disableLoadLines = false
                throw err
            }
        },

        setCurrentPivotConfig() {
            if (!this.pivot) {
                return {}
            }
            this.pivotState = this.$refs.pivotGrid.options().columnApi.getColumnState()
        },

        onPivot(v) {
            if (!v) {
                return
            }
            this.pivotData = []
            return this.$store
                .dispatch('linegrid/pivotLines', {
                    clientId: this.clientId,
                    all: this.showDeleted,
                    query: this.search,
                    sort: this.sort,
                    sortField: this.sortField
                })
                .then(lines => {
                    this.pivotData = lines
                    window.setTimeout(() => this.adjustColumns(), 100)
                })
                .finally(() => this.$refs.pivotGrid.options().api.hideOverlay())
        },

        sumChanged(type) {
            if (this.disableLoadLines) {
                return
            }
            const action = this.pivot ? 'pivotLines' : 'lines'
            const grid = this.pivot ? 'pivotGrid' : 'grid'
            this.$refs[grid].options().api.showLoadingOverlay()
            this.$store.commit('linegrid/sumType', { type: type })
            return this.$store
                .dispatch(`linegrid/${action}`, {
                    clientId: this.clientId,
                    all: this.showDeleted,
                    query: this.search,
                    sort: this.sort,
                    sortField: this.sortField,
                    dir: this.dir
                })
                .then(lines => {
                    if (this.pivot) {
                        this.pivotData = lines
                    }
                    this.goToBottom()
                })
                .finally(() => this.$refs[grid].options().api.hideOverlay())
        },

        /**
         * Gestion du filtre automatique après clic sur une cellule
         *
         * @param {Object} data
         * @return {void}
         */
        autoFilter(data) {
            const field = data.colDef.field
            if (field !== 'flow' || this.lastSearch !== null) {
                return
            }
            this.lastSearch = (this.search || '') + '___skip'
            this.$refs.searchField.setSearch(`=${field}::${data.data[field]}`, this.$refs.searchField.isAdvanced())
        },

        autoFilterRollback() {
            if (!this.lastSearch) {
                this.$refs.searchField.setSearch('', this.$refs.searchField.isAdvanced())
                return
            }
            this.$refs.searchField.setSearch(`=${this.lastSearch}`, this.$refs.searchField.isAdvanced())
        },

        onResize() {
            // 88 = le bottom de la grid, 3 = le slider
            const margin = this.editing ? this.height + 88 + 3 : 0
            this.$refs.grid && this.$refs.grid.setHeight(window.innerHeight - margin - 88)
            this.$refs.pivotGrid && this.$refs.pivotGrid.setHeight(window.innerHeight - margin - 88)
        },

        onSearch() {
            if (this.search && this.search.length < 2) {
                return
            }
            if ((this.loading && this.firstLoad) || this.disableLoadLines) {
                return
            }
            // pour gérer le filtre automatique sur le clic d'une colonne
            if (this.lastSearch && this.lastSearch.indexOf('___skip') !== -1) {
                this.lastSearch = this.lastSearch.replace('___skip', '')
            } else {
                this.lastSearch = null
            }
            // ---
            const action = this.pivot ? 'pivotLines' : 'lines'
            const grid = this.pivot ? 'pivotGrid' : 'grid'

            if (this.search || !this.$store.getters['linegrid/sumType']) {
                // KUS-159: Gestion du type de somme en fonction des filtres
                const type = /opDate::[0-9-]+(=|<>|>=|>)/g.test(this.search)
                    ? 'relative'
                    : 'absolute'

                this.$store.commit('linegrid/sumType', { type: type })
            }

            if (action === 'lines') {
                this.$store.dispatch('pref/saveGrid', {
                    clientId: this.clientId,
                    sort: this.sort,
                    sortField: this.sortField,
                    dir: this.dir,
                    query: `=${this.search}`,
                    advanced: this.$refs.searchField.isAdvanced(),
                    sum: this.$store.getters['linegrid/sumType'],
                    all: this.showDeleted
                })
            }

            this.loading = true
            this.$refs[grid].options().api.showLoadingOverlay()
            return this.$store
                .dispatch(`linegrid/${action}`, {
                    clientId: this.clientId,
                    all: this.showDeleted,
                    query: this.search,
                    sort: this.sort,
                    sortField: this.sortField,
                    dir: this.dir
                })
                .then(lines => {
                    if (this.pivot) {
                        this.pivotData = lines
                    }
                    const grid = this.pivot ? 'pivotGrid' : 'grid'
                    this.$refs[grid].options().columnApi.applyColumnState({
                        defaultState: { sort: null },
                        state: [{
                            colId: this.sort,
                            sort: this.dir
                        }]
                    })
                    this.goToBottom()
                })
                .finally(() => {
                    this.loading = false
                    this.firstLoad = false
                    this.$refs[grid].options().api.hideOverlay()
                })
        },

        onTypeSearch: lodash.debounce(function() {
            this.onSearch()
        }, 500)
    }
}
</script>

<style lang="sass">
.kus-auto-filter--btn
    text-transform: none

</style>
