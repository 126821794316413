import lodash from 'lodash'

export default {
    watch: {
        search: 'mixinAutoSelectFirst'
    },
    methods: {
        mixinAutoSelectFirst: lodash.debounce(function() {
            this.$refs.field && this.$refs.field.setMenuIndex(0)
        }, 2000)
    }
}
