import KusContentVue from '@/modules/content/KusContentVue'

export default [
    {
        path: '/content/:contentId',
        name: 'content',
        component: KusContentVue,
        props: true,
        meta: {
            public: true,
            private: false
        }
    }
]
