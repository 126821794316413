export default {
    computed: {
        forexAvailable() {
            const d = { ...this.params.node.data }
            const c = this.params.colDef.colId
            d[c] = this.value
            return !this.forexUnknownCurrency && d.foreignCurrency && d.currency && d.opDate && d.amountForeignCurrency
        },

        forexUnknownCurrency() {
            const f = this.$store.getters['linegridsel/forex']
            const currencies = f?.currencies || []

            const d = { ...this.params.node.data }
            return !currencies.includes(d.foreignCurrency)
        },

        forexHidden() {
            const d = this.params.node.data
            const c = this.params.colDef.colId
            return ['currency', 'amount'].indexOf(c) !== -1 && !d.foreignCurrency
        }
    },

    methods: {
        forexKeyDown(event) {
            if (event.key === 'Enter' && event.shiftKey) {
                window.setTimeout(() => this.forex(), 100)
                event.preventDefault()
                event.stopPropagation()
            }
        },

        async forex() {
            if (!this.forexAvailable) {
                return
            }
            const d = this.params.node.data
            const c = this.params.colDef.colId
            if (!d.change || ['currency', 'foreignCurrency'].indexOf(c) !== -1) {
                const h = {
                    from: 'foreignCurrency',
                    to: 'currency',
                    date: 'opDate',
                    amount: 'amountForeignCurrency'
                }
                const data = Object.entries(h).reduce((acc, v) => {
                    acc[v[0]] = v[1] === c ? this.value : d[v[1]]
                    return acc
                }, {})
                const forex = await this.$store.dispatch('line/forex', data)
                this.params.node.setDataValue('change', forex.rate)
                this.params.node.setDataValue('amount', forex.amountTo)
                return
            }
            switch (c) {
            case 'foreignCurrency':
            case 'currency':
                this.params.node.setDataValue('amount', this.$options.filters.number(d.change * d.amountForeignCurrency))
                break
            case 'amountForeignCurrency':
                this.params.node.setDataValue('amount', this.$options.filters.number(d.change * this.value))
                break
            case 'change':
                this.params.node.setDataValue('amount', this.$options.filters.number(this.value * d.amountForeignCurrency))
                break
            case 'amount':
                this.params.node.setDataValue('change', this.$options.filters.number(this.value / d.amountForeignCurrency, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 10
                }))
                break
            default:
                throw new Error(`Forex mixin, field ${c} unknown`)
            }
        }
    }
}
