<template>
    <v-card flat class="kus-line-documents">
        <kus-card-title :title="$t('Documents')" :dismissible="false" hide-spacer>
            <template #buttons>
                <v-spacer />
                <kus-document-upload-btn small
                    ref="uploader"
                    :loading="loading"
                    :flow="uploadFlow"
                    @upload:success="saveDocUploaded"
                    @upload:end="saveDocUploadedEnd"
                    :tooltip="$t('document.upload.tooltip')" />
            </template>
        </kus-card-title>
        <v-card-text class="kus-line-documents--content pa-0" ref="dialog">
            <v-tabs v-model="tab" text color="secondary" dark>
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab href="#unassoc" class="white--text">
                    <v-badge :value="!!unassocCount">
                        <template #badge>
                            {{ unassocCount }}
                        </template>
                        {{ $t('document.anonymous.short') }}
                    </v-badge>
                </v-tab>
                <v-tab href="#flow" :disabled="!currentFlow" class="white--text">
                    <v-badge :value="currentFlow && !!flowCount">
                        <template #badge>
                            {{ flowCount }}
                        </template>
                        {{ $t('document.flow') }}
                    </v-badge>
                </v-tab>
                <v-tab href="#preview" :disabled="!file.id" class="white--text">
                    <v-icon small>$vuetify.icons.docOpen</v-icon>
                </v-tab>
                <v-tab-item value="unassoc" class="pa-0">
                    <kus-document-unassociated hide-title :selectable="false" ref="unassoc" />
                    <v-alert outlined type="info" v-if="false">
                        {{ $t('document.anonymous.addToNew') }}
                    </v-alert>
                </v-tab-item>
                <v-tab-item value="flow">
                    <kus-document-flow-list />
                </v-tab-item>
                <v-tab-item value="preview">
                    <kus-document-preview-card v-if="showFile" :file="file" @close="file = null" expand />
                </v-tab-item>
            </v-tabs>
        </v-card-text>
        <v-card-actions class="kus-line-documents--actions" v-show="tab !== 'preview'" id="kusgridunassoctab">
            <kus-document-upload-btn small
                :loading="loading"
                :flow="uploadFlow"
                @upload:success="saveDocUploaded"
                @upload:end="saveDocUploadedEnd"
                drop
                :tooltip="$t('document.upload.tooltip')" />
        </v-card-actions>
    </v-card>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusDocumentUnassociated from '@/modules/document/KusDocumentUnassociated'
import KusDocumentFlowList from '@/modules/document/KusDocumentFlowList'
import KusDocumentUploadBtn from '@/modules/document/KusDocumentUploadBtn'
import KusDocumentPreviewCard from '@/modules/document/KusDocumentPreviewCard'

export default {
    name: 'kus-line-document',
    components: {
        KusCardTitle,
        KusDocumentUnassociated,
        KusDocumentFlowList,
        KusDocumentPreviewCard,
        KusDocumentUploadBtn
    },

    data: () => ({
        tab: 'unassoc',
        lastTab: null,
        loading: false,
        showFile: false
    }),

    computed: {
        currentFlow() {
            return this.$store.getters['line/currentFlow']
        },

        flowCount() {
            return this.$store.getters['doc/files'].length
        },

        unassocCount() {
            return this.$store.getters['doc/filesUnassociated'].length
        },

        uploadFlow() {
            return this.tab === 'flow' ? this.currentFlow : ''
        },

        editing() {
            return this.$store.getters['lineedit/show']
        },

        file: {
            get() {
                if (this.$store.getters['doc/previewPopup']) {
                    return {}
                }
                return this.$store.getters['doc/preview'] || {}
            },
            set(v) {
                this.tab = this.lastTab
                this.$store.commit('doc/preview', {})
            }
        },

        previewIsUnassociated() {
            return this.$store.getters['doc/previewIsUnassociated']
        },

        previewToggle() {
            return this.$store.getters['doc/previewToggle']
        }
    },

    watch: {
        currentFlow(v) {
            if (!this.previewIsUnassociated) {
                this.tab = v ? 'flow' : 'unassoc'
                if (v && this.file && this.file.id) {
                    this.tab = 'preview'
                }
            }
        },

        tab(v, old) {
            this.lastTab = old
            if (v === 'preview') {
                if (this.file?.id) {
                    this.$store.commit('doc/selectUnassociated', { file: this.file })
                }
            } else {
                this.$store.commit('doc/resetSelectUnassociated')
            }
        },

        previewToggle() {
            this.showFile = false
            if (this.file.id) {
                this.tab = 'preview'
                // à cause de l'animation du tab, on doit mettre un delay sur
                // l'apparition du preview, autrement l'affichage est incorrect
                window.setTimeout(() => (this.showFile = true), 300)
            }
        }
    },

    methods: {
        saveDocUploaded() {},
        saveDocUploadedEnd() {},

        selectNext() {
            if (!this.unassocCount) {
                return null
            }
            const file = this.$store.getters['doc/filesUnassociated'][0]
            this.$store.commit('doc/selectUnassociated', { file })
            this.$store.commit('doc/preview', { file })
            return file
        },

        endPreview() {
            this.file = null
            this.tab = 'unassoc'
        },

        upload(flow) {
            const files = this.$store.getters['doc/filesUnassociatedSelected']
            if (!files.length) {
                return Promise.resolve([])
            }
            this.loading = true
            return files
                .reduce((promise, file) => {
                    return promise.then(() => this.moveToFlow(flow, file))
                }, Promise.resolve())
                .then(() => files)
                .finally(() => (this.loading = false))
        },

        moveToFlow(flow, file) {
            return this.$api.post(`/documents/${file.id}/in/${flow}`)
                .then(res => {
                    this.$store.commit('doc/removeFileUnassociated', { file: file })
                    this.$store.commit('doc/addFile', { file: res.data })
                })
        }
    }
}
</script>

<style lang="sass">
.v-window-item.pa-0 > .v-card > .v-card__text
    padding: 0

.kus-line-documents

    flex: 1 1 100%
    flex-direction: column
    display: flex
    max-height: calc(100vh - 48px)

    .kus-line-documents--content
        overflow-y: auto
        flex: 1 1 auto
        display: flex

    .kus-line-documents--actions
        display: block

    .v-tabs-slider-wrapper
        height: 6px !important

</style>
