<template>
    <div>
        <p>Bienvenue</p>
    </div>
</template>

<script>
export default {
    name: 'kus-home'
}
</script>
