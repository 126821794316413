<template>
    <div class="kus-home-info primary white--text">
        <div class="kus-home-info--content">
            <h4>Bienvenue dans l'application Kushim</h4>
            <p>Comptabilité en ligne collaborative</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'kus-home-public-info'
}
</script>

<style lang="sass">
.kus-home-info
    display: flex
    align-items: center

    .kus-home-info--content
        margin: 80px auto
        max-width: 500px
        text-align: center

    .kus-home-info--logo
        margin: 0 auto

</style>
