import KusBaseTest from '@/modules/base/KusBaseTest'

export default [
    {
        path: '/test',
        name: 'test',
        component: KusBaseTest
    },
    {
        path: '*',
        name: 'notfound'
    }
]
