<template>
    <v-menu
        :close-on-content-click="false"
        v-model="menu"
        transition="scale-transition"
        offset-y
        min-width="290px"
        :content-class="menuContentClass">
        <template #activator="{ on }">
            <v-text-field
                v-on="on"
                ref="field"
                v-model="dateFormatted"
                :readonly="readonly"
                :label="label"
                :hint="hint"
                :disabled="disabled"
                :persistent-hint="persistentHint"
                :rules="rules"
                @keydown="onKeypress"
                @blur="onBlur"
                @click:clear="ev => $emit('click:clear', ev)"
                :flat="flat"
                :single-line="singleLine"
                :solo="solo"
                :hide-details="hideDetails"
                :class="inputClass"
                :clearable="clearable" />

        </template>
        <v-date-picker
            v-model="date"
            @input="closeMenu"
            no-title />
    </v-menu>
</template>

<script>
import SelectFocus from '@/modules/line/mixin/SelectFocus'
import lodash from 'lodash'
import moment from 'moment'

export default {
    name: 'kus-base-date-field',
    mixins: [SelectFocus],
    props: {
        value: { type: String },
        label: { type: String },
        hint: { type: String },
        persistentHint: { type: Boolean },
        rules: { type: Array, default: () => [] },
        readonly: { type: Boolean, default: false },
        formatSeparator: { type: String, default: '.' },
        flat: { type: Boolean },
        solo: { type: Boolean },
        singleLine: { type: Boolean },
        hideDetails: { type: Boolean },
        inputClass: { type: [String, Object] },
        menuContentClass: { type: [String, Object] },
        standard: { type: Boolean },
        clearable: { type: Boolean },
        disabled: { type: Boolean }
    },
    data: () => ({
        menu: false,
        recompute: false,
        parsingDate: null
    }),
    computed: {
        date: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        dateFormatted: {
            get() {
                const tmp = (this.date || '').split('-')
                return tmp.reverse().join(this.formatSeparator) + (this.recompute ? ' ' : '')
            },
            set(v) {
                const tmp = lodash.compact((v || '').split(this.formatSeparator)).reverse()
                this.parsingDate = v ? tmp.join('-') : null
            }
        }
    },
    mounted() {
        this.mixinFocus()
    },
    methods: {
        focus() {
            this.mixinFocus()
        },

        setDate() {
            const tmp = this.parsingDate
                ? this.parsingDate.split('-').map(p => {
                    const n = Number(p)
                    return lodash.isNaN(n) || !n ? 1 : n
                })
                : []

            const now = moment()
            let acc = []
            if (tmp.length === 1) {
                acc = [
                    now.format('YYYY'),
                    now.format('MM'),
                    String(tmp[0]).padStart(2, 0)
                ]
            } else if (tmp.length === 2) {
                acc = [
                    now.format('YYYY'),
                    String(tmp[0]).padStart(2, 0),
                    String(tmp[1]).padStart(2, 0)
                ]
            } else if (tmp.length === 3) {
                const year = tmp[0].length === 2
                    ? now.format('YYYY').substr(0, 2) + String(tmp[0])
                    : String(tmp[0])

                acc = [
                    year,
                    String(tmp[1]).padStart(2, 0),
                    String(tmp[2]).padStart(2, 0)
                ]
            }

            const date = acc.join('-')
            this.parsingDate = false
            if (moment(date).isValid()) {
                this.recompute = false
                this.date = date
            } else {
                // on utilise recompute pour réafficher la date précédente
                // qui était juste, et ne plus afficher la date fausse
                this.recompute = !this.recompute
            }

            this.menu = false
        },

        onBlur() {
            if (this.menu || this.standard) {
                // lorsqu'on clique sur une date du picker, le champ perd son
                // focus. Le setDate est appelé, mais le picker n'a pas encore
                // transmis la valeur de la date cliquée. On est donc obligé
                // de faire ce trick pour que la date cliquée soit prise en
                // compte
                return
            }
            this.setDate()
        },

        onKeypress(e) {
            // on doit gérer ici le TAB dans le champ, lorsque le menu du picker
            // est ouvert
            this.menu = false
        },

        closeMenu() {
            this.menu = false
            this.$nextTick(() => this.$refs.field.focus())
        }
    }
}
</script>
