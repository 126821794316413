<template>
    <v-simple-table class="kus-balance--table">
        <template #default>
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th :colspan="result.length + 1" class="text-right">{{ $t('vat.accounts.result') }}</th>
                    <th :colspan="balance.length + 1" class="text-right">{{ $t('vat.accounts.balance')}}</th>
                </tr>
                <tr>
                    <th class="kus-balance--header-name">&nbsp;</th>
                    <th class="text-right" v-for="(account, i) in headers" :key="i" :class="{ 'grey lighten-3': account.indexOf('total-') === 0 }">
                        {{ account.indexOf('total-') === 0 ? $t('vat.total') : account }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(row, index) in flatGroups"
                    :key="index"
                    :class="{ group: row.expandable}"
                    v-show="!row.hidden && row.visible">
                    <td :style="{ 'padding-left': `${1.5 * row.level}em` }" @click="row.expandable && toggle(row, index)">
                        <v-icon small v-if="row.expandable">
                            {{ row.expanded ? '$vuetify.icons.balanceCollapse' : '$vuetify.icons.balanceExpand' }}
                        </v-icon>
                        <v-icon small v-else>$vuetify.icons.balanceNotExpandable</v-icon>
                        {{ row.code }} {{ row.name }}
                    </td>
                    <td v-for="(header, i) in headers"
                        :key="i"
                        class="text-right"
                        :class="{ 'kus-link-grid': row.type === 'account', 'grey lighten-3': header.indexOf('total-') === 0 }"
                        @dblclick="showGrid(row, header)">
                        <span :class="{ 'grey--text': !row.balances[header] }">{{ row.balances[header] | number }}</span>
                    </td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td v-for="(header, i) in headers" :key="`total-${i}`" class="text-right">
                        <template v-if="i < headers.length - 1"></template>
                        <strong v-else>{{ total | number }}</strong>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
    name: 'kus-vat-display',
    props: {
        groups: { type: Array, required: true },
        balance: { type: Array },
        result: { type: Array },
        dates: { type: Array, default: () => [] }
    },
    data: () => ({
        flatGroups: []
    }),
    computed: {
        headers() {
            return this.result.concat(['total-result']).concat(this.balance).concat(['total-balance'])
        },

        clientId() {
            return this.$store.getters['auth/client']
        },

        total() {
            return this.flatGroups.reduce((acc, group) => {
                if (!group.code) {
                    return acc
                }
                return acc + group.balances['total-balance']
            }, 0)
        }
    },
    mounted() {
        this.setFlatGroups()
    },
    watch: {
        groups: 'setFlatGroups'
    },
    methods: {

        showGrid(row, accountCode) {
            const account = this.getAccountByCode(this.groups, accountCode)
            if (!account || !row.code) {
                return
            }
            this.$store.dispatch('pref/saveGrid', {
                clientId: this.clientId,
                sort: 'accountCode',
                dir: 'asc',
                all: false,
                sum: account.accountType === 'result' ? 'relative' : 'absolute',
                query: {
                    accountType: account.accountType,
                    accountCode: account.accountCode,
                    opDate: `${this.dates[0]}<>${this.dates[1]}`,
                    vatCode: row.code
                }
            })
            this.$router.push({ name: 'line' })
        },

        setFlatGroups() {
            const flatGroups = this.flattenGroups(this.groups, 1)
            this.flatGroups = flatGroups
        },

        toggle(group, index) {
            group.expanded = !group.expanded
            for (let i = index + 1; i < this.flatGroups.length; i += 1) {
                if (this.flatGroups[i].level > group.level) {
                    this.flatGroups[i].expanded = group.expanded
                    this.flatGroups[i].hidden = !group.expanded
                } else {
                    break
                }
            }
            this.$emit('expanded', group)
        },

        getGroupById(groups, id) {
            let found
            for (let g = 0; g < groups.length; g += 1) {
                if (groups[g].id === id) {
                    return groups[g]
                }
                found = this.getGroupById(groups[g].groups, id)
                if (found) {
                    return found
                }
            }
            return null
        },

        getFirstAccount(groups) {
            return groups.reduce((account, group) => {
                if (group.accounts.length) {
                    return group.accounts[0]
                }
                return this.getFirstAccount(group.groups)
            }, null)
        },

        flattenGroups(groups, level) {
            return groups.reduce((acc, group) => {
                const g = {
                    type: group.type,
                    id: group.id,
                    name: group.name,
                    code: group.code,
                    level: level,
                    visible: group.visible,
                    hidden: false,
                    expanded: true,
                    expandable: !!group.groups.length,
                    balances: this.headers.reduce((b, account) => {
                        const totalId = `total-${group.balances[account]?.accountType}`
                        // initialisation du total par ligne. Le undefined est à cause
                        // des groupes de codes TVA
                        if (!b['total-undefined']) {
                            b['total-undefined'] = 0
                        }
                        if (!b['total-result']) {
                            b['total-result'] = 0
                        }
                        if (!b['total-balance']) {
                            b['total-balance'] = 0
                        }
                        if (account.indexOf('total-') !== 0) {
                            b[totalId] += group.balances[account]?.balance || 0
                            b[account] = group.balances[account]?.balance || 0
                        }
                        return b
                    }, {})
                }
                acc.push(g)
                if (group.groups.length) {
                    const subgroups = this.flattenGroups(group.groups, level + 1)
                    subgroups
                        .filter(sg => sg.level === level + 1)
                        .forEach(sg => this.headers.forEach(account => {
                            g.balances[account] += sg.balances[account]
                        }))

                    acc = acc.concat(subgroups)
                    // permet d'afficher l'arobrescence, ou de la cacher si aucun
                    // compte visible n'existe dedans
                    if (g.type !== 'account' && !subgroups.find(s => s.visible) && !subgroups.find(s => s.type === 'account' && s.visible)) {
                        g.visible = false
                    }
                }
                return acc
            }, [])
        },

        getAccountByCode(groups, code) {
            for (let i = 0; i < groups.length; i += 1) {
                let found

                const group = groups[i]
                if (group.code) {
                    found = group.balances && group.balances[code]
                } else {
                    found = this.getAccountByCode(group.groups || [], code)
                }
                if (found) {
                    return found
                }
            }
            return null
        }
    }
}
</script>

<style lang="sass">
.kus-balance--table
    .kus-balance--header-name
        width: 100%

    .group td
        font-weight: bold

</style>
