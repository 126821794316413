<template>
    <v-card :loading="loading ? 'secondary' : false" class="bordered">
        <v-form v-model="valid" ref="form" @submit.stop.prevent="submit">
            <v-card-title>
                {{ $t('clients.form.info') }}
            </v-card-title>
            <v-card-text>

                <kus-user-form-name
                    v-model="client.company"
                    :label="$t('clients.form.company')"
                    name="company" />

                <kus-user-form-name
                    v-model="client.firstname"
                    :label="$t('clients.form.firstname')"
                    name="firstname" />

                <kus-user-form-name
                    v-model="client.lastname"
                    :label="$t('clients.form.lastname')"
                    name="lastname" />

                <kus-client-form-currency v-model="client.currency" :disabled="!!client.id" />
                <kus-client-form-pivot-date v-model="client.pivotDate" :disabled="!!client.id" />
            </v-card-text>
            <v-card-actions>
                <kus-card-remove-btn
                    :title="$t('clients.remove.title')"
                    @remove="remove"
                    :loading="loading">
                    <template #content>
                        {{ $t('clients.remove.text', clientRemove) }}
                    </template>
                </kus-card-remove-btn>
                <v-spacer />
                <v-btn
                    :disabled="!valid"
                    type="submit"
                    absolute dark fab bottom right
                    color="primary">
                    <v-icon>$vuetify.icons.baseSave</v-icon>
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import KusCardRemoveBtn from '@/modules/card/KusCardRemoveBtn'
import KusUserFormName from '@/modules/user/form/KusUserFormName'
import KusClientFormCurrency from '@/modules/client/form/KusClientFormCurrency'
import KusClientFormPivotDate from '@/modules/client/form/KusClientFormPivotDate'

export default {
    name: 'kus-client-form',
    components: {
        KusCardRemoveBtn,
        KusUserFormName,
        KusClientFormCurrency,
        KusClientFormPivotDate
    },
    props: {
        id: { type: String, required: true }
    },
    data: () => ({
        loading: false,
        valid: false
    }),

    computed: {
        client() {
            return this.$store.getters['client/current']
        },
        clientRemove() {
            return {
                lastname: '',
                firstname: '',
                ...this.client
            }
        },
        createMode() {
            return !this.id || this.id === '0'
        }
    },

    watch: {
        $route: 'load'
    },

    mounted() {
        this.load()
    },

    methods: {

        load() {
            if (this.createMode) {
                return this.$store.commit('client/current', {
                    client: {
                        currency: this.$config.client.defaultCurrency,
                        pivotDate: this.$config.client.defaultPivotDate
                    }
                })
            }
            this.loading = true
            return this.$store.dispatch('client/get', { id: this.id })
                .then(() => this.$store.dispatch('auth/client', { clientId: this.id }))
                .finally(() => (this.loading = false))
        },

        submit() {
            if (!this.$refs.form.validate()) {
                return
            }
            this.loading = true
            return this.$store.dispatch('client/upsert', { client: this.client })
                .then(client => {
                    if (this.createMode) {
                        // on sélectionne le nouveau client
                        this.$store.dispatch('auth/client', { clientId: client.id })
                        this.$router.push({ name: 'clientform', params: { id: client.id } })
                    }
                    this.$store.commit('msg/show', { message: this.$i18n.t('clients.form.saved') })
                })
                .finally(() => (this.loading = false))
        },

        remove() {
            this.loading = true
            return this.$store.dispatch('client/remove', { client: this.client })
                .then(() => {
                    this.$store.commit('msg/show', { message: this.$i18n.t('clients.remove.success') })
                    this.$router.push({ name: 'client' })
                })
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style lang="sass">
.bordered form
    border-left: 1px solid #ddd
    border-right: 1px solid #ddd
    border-top: 1px solid #ddd
</style>
