<template>
    <v-dialog v-model="show" max-width="800" scrollable>
        <v-card>
            <kus-card-title title="Saisie des données dans la grille" @close="show = false" />
            <v-card-text>
                <h4>Général</h4>
                <ul>
                    <li><kbd>Maj + Entrée</kbd> pour enregistrer la pièce</li>
                    <li><kbd>Ctrl + Maj + &uarr;</kbd> pour déplacer vers le haut une ligne de la pièce</li>
                    <li><kbd>Ctrl + Maj + &darr;</kbd> pour déplacer vers le bas une ligne de la pièce</li>
                </ul>

                <h4>Saisie de dates: <code>Date | Date de paiement</code></h4>
                <ul>
                    <li>Le format est <kbd>DD.MM.YYYY</kbd> , soit le jour sur 2 chiffres, le mois sur 2 chiffres, et l'année sur 4 chiffres</li>
                    <li>En ne saisissant qu'un chiffre, le mois et l'année seront complétés: <code>02 => 02.{{ month }}.{{ year }}</code></li>
                    <li>En ne saisissant que le jour et le mois, l'année sera complétée: <code>02.{{ sampleMonth }} => 02.{{ sampleMonth }}.{{ year }}</code></li>
                    <li>La saisie du jour peut être sur un seul chiffre: <code>2 => 02.{{ month }}.{{ year }}</code></li>
                    <li>La saisie du mois peut être sur un seul chiffre: <code>2.{{ sampleMonthShort }} => 02.{{ sampleMonth }}.{{ year }}</code></li>
                    <li>La saisie de l'année peut être sur 2 chiffres: <code>2.{{ sampleMonthShort }}.{{ sampleYearShort }} => 02.{{ sampleMonth }}.{{ sampleYear }}</code></li>
                </ul>

                <h4>Saisie de montants: <code>Montant | Taux TVA | Change | Montant ME</code></h4>
                <ul>
                    <li><kbd>[0-9]</kbd> va insérer ce chiffre dans la cellule</li>
                    <li><kbd>[.,]</kbd> va insérer automatiquement <code>0.</code></li>
                    <li><kbd>-</kbd> va changer le signe du montant</li>
                    <li><kbd>autre chose que [0-9]</kbd> pour sélectionner tout le contenu</li>
                </ul>

                <h4>Comptes: <code>Compte</code></h4>
                <ul>
                    <li>Le champ propose tout ce qui a déjà été saisi. La recherche se fait aussi bien sur le n° du compte que sur son libellé</li>
                    <li>Les touches directionnelles <kbd>&darr;</kbd> et <kbd>&uarr;</kbd> permettent de naviguer dans les choix existants</li>
                    <li>Pour ajouter un nouveau compte:
                        <ol>
                            <li>Inscrire le code composé de n'importe quel caractère <strong>sauf des espaces</strong></li>
                            <li>Mettre un espace</li>
                            <li>Inscrire le libellé composé de n'importe quel caractère, lettre, chiffre ou caractère spécial</li>
                            <li>Terminer par
                                <dl>
                                    <dd><kbd>b</kbd> pour l'inscrire au bilan</dd>
                                    <dd><kbd>r</kbd> pour l'inscrire au résultat</dd>
                                </dl>
                            </li>
                            <li>Appuyer sur <kbd>Entrée</kbd> ou <kbd>Tab</kbd> pour valider</li>
                        </ol>
                    </li>
                    <li>Pour effacer le compte sélectionné
                        <ol>
                            <li>Entrer dans le champ avec <kbd>Tab</kbd> ou <kbd>Entrée</kbd></li>
                            <li>Appuyer sur la flèche <kbd>&larr;</kbd> pour sélectionner le compte</li>
                            <li>Appuyer sur <kbd>Del</kbd> ou <kbd>Backspace</kbd> pour le supprimer</li>
                        </ol>
                    </li>
                </ul>

                <h4>Codes analytiques: <code>Code analytique 1 | ... | Code analytique 9</code></h4>
                <ul>
                    <li>Le champ propose tout ce qui a déjà été saisi</li>
                    <li>Les touches directionnelles <kbd>&darr;</kbd> et <kbd>&uarr;</kbd> permettent de naviguer dans les choix existants</li>
                    <li>Si le choix n'est pas proposé, appuyer sur <kbd>Entrée</kbd> permet d'en inséré un nouveau</li>
                </ul>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment'
import KusCardTitle from '@/modules/card/KusCardTitle'

const now = moment()
const sample = moment().subtract(1, 'year').set({ month: now.format('M') === '3' ? 0 : 2 })

export default {
    name: 'kus-line-helper',
    components: { KusCardTitle },
    data: () => ({
        year: now.format('YYYY'),
        month: now.format('MM'),
        sampleYear: sample.format('YYYY'),
        sampleYearShort: sample.format('YY'),
        sampleMonth: sample.format('MM'),
        sampleMonthShort: sample.format('M')
    }),
    computed: {
        show: {
            get() {
                return this.$store.getters['line/helper']
            },
            set(v) {
                this.$store.commit('line/helper', { show: false })
            }
        }
    }
}
</script>

<style lang="sass" scoped>
ul
    margin-top: 1em
    margin-bottom: 2em

    li
        padding: 0.3em

</style>
