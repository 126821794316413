<template>
    <div>
        <v-select
            class="pt-0 mt-0 kus-grid-pay-status"
            ref="field"
            v-model="value"
            :items="items"
            :menu-props="menuprops"
            :search-input.sync="search"
            hide-details
            single-line flat solo
            @keydown="onKeyDown">
            <template #append v-if="value">
                <v-icon small @click="clear">$vuetify.icons.baseClose</v-icon>
            </template>
        </v-select>
    </div>
</template>

<script>
import Vue from 'vue'
import SelectFocus from '@/modules/line/mixin/SelectFocus'
import KeyDown from '@/modules/line/mixin/KeyDown'

export default Vue.extend({
    name: 'kus-line-grid-pay-status',
    mixins: [SelectFocus, KeyDown],
    data: () => ({
        menuprops: {},
        value: '',
        search: '',
        params: null
    }),
    computed: {
        items() {
            const status = this.$i18n.t('lines.payStatus')
            return Object.entries(status).map(d => ({
                value: d[0],
                text: d[1]
            }))
        }
    },
    mounted() {
        if (this.params.context && this.params.context.attach) {
            this.menuprops.contentClass = 'maxZindex'
        }
        this.value = this.params.value
        this.attach = this.$refs.attach
    },
    methods: {
        onKeyDown(event) {
            this.mixinKeyDownMenu(event)
        },
        getValue() {
            return this.value
        },
        isPopup() {
            return false
        },
        clear(e) {
            e.stopPropagation()
            e.preventDefault()
            this.value = undefined
            this.$refs.field.isMenuActive = false
        }
    }
})
</script>

<style lang="sass">
.kus-grid-pay-status .v-select__selection
    margin-top: 4px !important
    margin-bottom: 4px !important

</style>
