<template>
    <kus-base-upload-btn
        class="kus-document-upload--btn"
        :color="small ? 'white' : 'primary'"
        :small="small"
        :accept="mimes"
        :auto-start="autoStart"
        :url="url"
        :axios="$api"
        :loading="loading"
        :tooltip="tooltip"
        @upload:start="upload"
        @upload:progress="uploading"
        @upload:success="uploaded"
        @upload:error="error"
        @upload:end="end"
        :drop="drop"
        ref="upload">
        <template #content v-if="!small">
            {{ $t('document.upload.select') }}
        </template>
    </kus-base-upload-btn>
</template>

<script>
import KusBaseUploadBtn from '@/modules/base/KusBaseUploadBtn'

export default {
    name: 'kus-document-upload-btn',
    props: {
        flow: { type: String },
        small: { type: Boolean },
        loading: { type: Boolean },
        tooltip: { type: String },
        autoStart: { type: Boolean, default: true },
        drop: { type: Boolean }
    },
    components: { KusBaseUploadBtn },
    data: () => ({
        files: []
    }),
    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        },

        url() {
            const base = `/clients/${this.clientId}`
            return this.flow
                ? `${base}/flows/${this.flow}/documents`
                : `${base}/documents/unassociated`
        },

        mimes() {
            return null // this.$config.mimes
        }
    },
    methods: {
        reset() {
            this.$store.commit('doc/setFilesUpload', { files: [] })
            this.$refs.upload.reset()
        },

        start() {
            this.$refs.upload.upload()
        },

        upload(files) {
            this.$store.commit('doc/setFilesUpload', { files: files })
            this.$emit('upload:start', files)
        },

        uploading(file) {
            this.$store.commit('doc/upsertFileUpload', { file: file })
            this.$emit('upload:progress', file)
        },

        uploaded(file) {
            this.$store.commit('doc/upsertFileUpload', { file: file })
            if (this.flow) {
                this.$store.commit('doc/addFile', { file: file.response })
            } else {
                this.$store.commit('doc/addFileUnassociated', { file: file.response })
            }
            this.$emit('upload:success', file)
        },

        error(file) {
            this.$store.commit('doc/upsertFileUpload', { file: file })
            this.$emit('upload:error', file)
        },

        end(ev) {
            this.$emit('upload:end', ev)
        }
    }
}
</script>
