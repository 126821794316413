import KusDemo from '@/modules/demo/KusDemo'

export default [
    {
        path: '/demo',
        name: 'demo',
        component: KusDemo,
        meta: {
            public: true,
            private: true,
            hideConnected: true
        }
    }
]
