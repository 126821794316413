<template>
    <v-tooltip bottom>
        <template #activator="{ on }">
            <v-btn small text icon dark v-on="on" to="result" class="mr-3">
                {{ $t('result.short') }}
            </v-btn>
        </template>
        {{ $t('result.menu') }}
    </v-tooltip>
</template>

<script>
export default {
    name: 'kus-line-grid-topbar-result'
}
</script>
