<template>
    <v-menu open-on-hover offset-y v-model="menu" :close-on-content-click="false" :disabled="loading">
        <template #activator="{ on: menu }">
            <v-btn
                v-on="{ ...menu }"
                small icon text dark
                :loading="loading"
                :disabled="loading"
                class="ml-3 mr-3">
                <v-icon small>$vuetify.icons.lineExport</v-icon>
            </v-btn>
        </template>
        <v-card max-width="350">
            <kus-card-title :title="$t('lines.export.btn')" :dismissible="false" />
            <v-card-text>
                <v-switch v-model="all" :label="$t('lines.export.withDeleted')" hide-details />
                <v-switch v-model="docs" :label="$t('lines.export.withDocs')" hide-details />
                <div v-if="docs" class="ml-4">
                    <v-checkbox v-model="df" :label="$t('lines.export.withDocsFlow')" hide-details />
                    <v-checkbox v-model="du" :label="$t('lines.export.withDocsUnassociated')" hide-details />
                    <v-checkbox v-if="all" v-model="dd" :label="$t('lines.export.withDocsDeleted')" hide-details />
                    <v-divider class="my-5" />
                    <div v-if="type === 'tar'" v-marked="$t('lines.export.typeHint')"></div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn block text color="primary" @click="exportAll">{{ $t('lines.export.btn') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'

export default {
    name: 'kus-line-grid-topbar-export',
    components: { KusCardTitle },
    props: {
        search: { type: String }
    },
    data: () => ({
        menu: false,
        loading: false,
        docs: false,
        type: 'zip',
        all: false,
        df: true,
        du: false,
        dd: false
    }),
    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        },

        tokenQuery() {
            return this.$store.getters['auth/tokenQuery']
        }
    },
    watch: {
        docs(v) {
            // on force sur le TAR quoi qu'il arrive, et tant pis pour les
            // utilisateurs windows
            this.type = v ? 'tar' : 'zip'
        }
    },
    methods: {
        exportAll(widthDocs) {
            if (this.loading) {
                return
            }
            const q = ['all', 'docs', 'df', 'du', 'dd', 'type'].reduce((str, field) => {
                if (field === 'type') {
                    str.push(`${field}=${this[field]}`)
                } else {
                    str.push(`${field}=${this[field] ? 'true' : 'false'}`)
                }
                return str
            }, []).join('&')

            this.menu = false

            const url = `/clients/${this.clientId}/lines/export?${q}&query=${this.search || ''}`

            if (!this.docs) {
                // téléchargement direct du XSL
                window.open(`${this.$api.defaults.baseURL}${url}&${this.tokenQuery}`)
                return
            }
            this.loading = true
            return this.$api.post(url, 'kushim-export')
                .then(() => {
                    this.$store.commit('msg/show', { message: this.$i18n.t('lines.export.exported') })
                })
                .finally(() => (this.loading = false))
        }
    }
}
</script>
