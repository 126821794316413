import i18next from 'i18next'

export default {
    required: v => !!v || i18next.t('base.rules.required'),
    requiredZero: v => !!v || v === 0 || i18next.t('base.rules.required'),
    ranged: (min, max) => {
        return v => {
            const lnKey = `base.rules.${min && max ? 'minmax' : (min ? 'min' : 'max')}`
            const msg = i18next.t(lnKey, { min: min, max: max })
            return !v || ((!min || v.length >= min) && (!max || v.length <= max)) || msg
        }
    },
    emailed: v => {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return !v || re.test(v) || i18next.t('base.rules.email')
    },
    password: v => {
        const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,70}/
        return !v || re.test(v) || i18next.t('base.rules.password', { minChars: 8, maxChars: 70 })
    },
    eq: (value, errMsg) => {
        return v => !v || !value || v === value || errMsg
    }
}
