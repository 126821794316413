<template>
    <v-tooltip bottom>
        <template #activator="{ on }">
            <v-btn small text icon dark v-on="on" to="balance" class="mr-3">
                {{ $t('balance.short') }}
            </v-btn>
        </template>
        {{ $t('balance.menu') }}
    </v-tooltip>
</template>

<script>
export default {
    name: 'kus-line-grid-topbar-balance'
}
</script>
