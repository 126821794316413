<template>
    <div>
        <kus-card-title :title="createMode ? $t('clients.form.create') : $tu('clients.form.title', client)" :dismissible="false" />
        <v-tabs grow v-model="tabs">
            <v-tab href="#form">
                <v-icon left>$vuetify.icons.clientForm</v-icon>
            </v-tab>
            <v-tab href="#access" :disabled="!client.id">
                <v-icon left>$vuetify.icons.clientAccess</v-icon>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
            <v-tab-item value="form" class="mb-10">
                <kus-client-form :id="id" />
            </v-tab-item>
            <v-tab-item value="access">
                <kus-client-access-table v-if="client.id" :id="client.id" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusClientForm from '@/modules/client/KusClientForm'
import KusClientAccessTable from '@/modules/client/access/KusClientAccessTable'

export default {
    name: 'kus-client-tab',
    components: {
        KusCardTitle,
        KusClientForm,
        KusClientAccessTable
    },
    props: {
        id: { type: String, required: true }
    },

    data: () => ({
        tabs: 'form'
    }),

    computed: {
        client() {
            return this.$store.getters['client/current']
        },
        createMode() {
            return !this.id || this.id === '0'
        }
    }
}
</script>
