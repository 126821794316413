<template>
    <div @keydown="onKeyDown">
        <v-text-field
            ref="field"
            class="pt-0 mt-0"
            v-model="value"
            single-line flat solo
            hide-details>
            <template #append v-if="!forexHidden">
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-icon v-on="on" @click="forex" small :color="forexAvailable ? 'primary' : 'grey'">$vuetify.icons.lineForexBtn</v-icon>
                    </template>
                    <span v-if="forexAvailable">Calculer automatiquement</span>
                    <span v-else-if="forexUnknownCurrency">Monnaie inconnue, impossible de calculer automatiquement</span>
                    <span v-else>Renseigner la date, les monnaies et le montant pour le calcul automatique</span>
                </v-tooltip>
            </template>
        </v-text-field>
    </div>
</template>

<script>
import Vue from 'vue'
import SelectFocus from '@/modules/line/mixin/SelectFocus'
import Forex from '@/modules/line/mixin/Forex'

export default Vue.extend({
    name: 'kus-line-grid-forex-number',
    mixins: [SelectFocus, Forex],
    data: () => ({
        value: null
    }),
    created() {
        this.value = this.params.value && Number(this.params.value)
    },
    mounted() {
        this.mixinSelectFocusNumber()
    },
    methods: {
        onKeyDown(event) {
            this.forexKeyDown(event)
            if (['Escape', 'Enter', 'Tab'].indexOf(event.key) !== -1) {
                return
            }
            const reg = /[0-9.,-]+/g
            const keys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete']
            if (keys.indexOf(event.key) === -1 && !reg.test(event.key)) {
                event.preventDefault()
            }
            event.stopPropagation()
        },
        getValue() {
            return this.value || this.value === 0 ? Number(this.value) : null
        },
        isPopup() {
            return false
        }
    }
})
</script>
