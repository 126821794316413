'use strict'

import Vue from 'vue'
import store from './store'

const bind = function(el, binding) {
    const rights = Array.isArray(binding.value) ? binding.value : [binding.value]
    const user = store.getters['auth/user']
    let ok = false
    if (user && user.roles) {
        ok = rights
            .filter((x) => user.roles.includes(x))
            .length
    }
    el.style.display = ok ? '' : 'none'
}

const Plugin = function() {
    Vue.prototype.$rights = function(value) {
        const obj = { style: {} }
        bind(obj, { value: value })
        return obj.style.display === ''
    }
}

Vue.directive('rights', {
    inserted: bind,
    bind: bind,
    update: bind
})

Vue.use({ install: Plugin })
