import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        filesUnassociated: [],
        filesUpload: [],
        files: [],
        removePopup: false,
        removeDocument: null,
        removeType: null,
        preview: null,
        previewPopup: false,
        previewToggle: false
    },

    getters: {
        filesUnassociated: state => state.filesUnassociated,
        filesUnassociatedSelected: state => state.filesUnassociated.filter(file => file._selected),
        files: state => state.files,
        filesLength: state => state.files.length,
        removePopup: state => state.removePopup,
        removeDocument: state => state.removeDocument,
        removeType: state => state.removeType,
        filesUpload: state => state.filesUpload,
        preview: state => state.preview,
        previewPopup: state => state.previewPopup,
        previewToggle: state => state.previewToggle,
        previewIsUnassociated: state => state.preview && state.preview.flow === 'noassoc'
    },

    mutations: {
        reset(state, data) {
            data = data || {}
            if (!data.keepUnassociated) {
                state.filesUnassociated = []
                if (!data.keepPreview) {
                    state.preview = null
                }
            } else {
                // si on veut garder les noassoc, on ne va pas fermer le preview
                // si le preview est un noassoc
                if (state.preview && state.preview.flow !== 'noassoc') {
                    state.preview = null
                }
            }
            state.filesUpload = []
            state.files = []
            state.previewPopup = false
            state.previewToggle = false
        },

        resetPopup(state, data) {
            if (!data?.keepPreview) {
                state.preview = null
                state.previewPopup = false
                state.previewToggle = false
            }
        },

        preview(state, data) {
            state.preview = data.file
            state.previewToggle = !state.previewToggle
            if (data.popup !== undefined) {
                state.previewPopup = data.popup
            }
        },

        selectUnassociated(state, { file }) {
            state.filesUnassociated.forEach(file => {
                Vue.set(file, '_selected', false)
            })

            const unassoc = state.filesUnassociated.find(f => f.id === file.id)
            if (unassoc) {
                Vue.set(file, '_selected', true)
                Vue.set(unassoc, '_selected', true)
            }
        },

        resetSelectUnassociated(state) {
            state.filesUnassociated.forEach(file => {
                Vue.set(file, '_selected', false)
            })
        },

        filesUnassociated(state, data) {
            state.filesUnassociated = data.files || []
        },

        addFileUnassociated(state, data) {
            state.filesUnassociated.push(data.file)
        },

        removeFileUnassociated(state, data) {
            const index = state.filesUnassociated.findIndex(f => f.id === data.file.id)
            if (index !== -1) {
                state.filesUnassociated.splice(index, 1)
            }
        },

        setFilesUpload(state, data) {
            state.filesUpload = data.files || []
        },

        upsertFileUpload(state, data) {
            const index = state.filesUpload.findIndex(f => f.id === data.file.id)
            if (index === -1) {
                state.filesUpload.push(data.file)
            } else {
                state.filesUpload.splice(index, 1, data.file)
            }
        },

        removeUpload(state, data) {
            const index = state.filesUpload.findIndex(f => f.id === data.file.id)
            if (index !== -1) {
                state.filesUpload.splice(index, 1)
            }
        },

        files(state, data) {
            state.files = data.files || []
        },

        addFile(state, data) {
            state.files.push(data.file)
        },

        remove(state, data) {
            const index = state.files.findIndex(f => f.id === data.file.id)
            if (index !== -1) {
                state.files.splice(index, 1)
            }
        },

        removePopup(state, data) {
            state.removePopup = data.show !== undefined ? data.show : true
            state.removeDocument = data.document
            state.removeType = data.type
        }
    },

    actions: {
        files(context, data) {
            return Vue.prototype.$api.get(`/clients/${data.clientId}/flows/${data.flow}/documents`)
                .then(res => {
                    context.commit('files', { files: res.data })
                    return res.data
                })
        },

        filesUnassociated(context, data) {
            return Vue.prototype.$api.get(`/clients/${data.clientId}/documents/unassociated`)
                .then(res => {
                    context.commit('filesUnassociated', { files: res.data })
                    return res.data
                })
        }
    }
}
