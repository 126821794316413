<template>
    <v-container fluid class="fill-height">
        <v-row no-gutters class="fill-height">
            <v-col cols="12" md="4" sm="12">
                <kus-user-list class="align-self-stretch fill-height pr-4" />
            </v-col>
            <v-col cols="12" md="8" sm="12">
                <router-view class="align-self-stretch fill-height" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import KusUserList from '@/modules/user/KusUserList'

export default {
    name: 'kus-user-list-vue',
    components: { KusUserList }
}
</script>
