<template>
    <v-container fluid class="fill-height pa-0">
        <v-row no-gutters class="fill-height">
            <v-col cols="12" order-md="2">
                <div class="kus-home-login">
                    <v-tabs v-model="tab" centered class="mb-5">
                        <v-tab :to="{ name: 'homepublic' }">Login</v-tab>
                        <v-tab :to="{ name: 'content', params: { contentId: 'cgu' } }">CGU</v-tab>
                        <v-tab :to="{ name: 'content', params: { contentId: 'dp' } }">DP</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab" class="flex-grow-1">
                        <v-tab-item>e</v-tab-item>
                        <v-tab-item value="/content/cgu" eager>
                            <kus-content-page content-id="cgu" />
                        </v-tab-item>
                        <v-tab-item value="/content/dp" eager>
                            <kus-content-page content-id="dp" />
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </v-col>
            <v-col cols="12" order-md="1">
                <kus-home-public-info class="align-self-stretch fill-height" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import KusHomePublicInfo from '@/modules/home/public/KusHomePublicInfo'
import KusContentPage from '@/modules/content/KusContentPage'

export default {
    name: 'kus-content-vue',
    components: { KusContentPage, KusHomePublicInfo },
    props: {
        contentId: { type: String }
    },
    data: () => ({
        tab: 1
    }),
    mounted() {
        this.tab = this.contentId === 'cgu' ? 1 : 2
    }
}
</script>

<style lang="sass">
.kus-home-login
    display: flex
    align-items: center
    flex-direction: column

</style>
