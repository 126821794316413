<template>
    <v-text-field
        ref="field"
        v-model="currency"
        :label="$t('clients.form.currency')"
        name="currency"
        :rules="[required]"
        :disabled="disabled" />
</template>

<script>
import KusBaseRules from '@/modules/base/KusBaseRules'

export default {
    name: 'kus-client-form-currency',
    props: {
        value: { type: String },
        disabled: { type: Boolean }
    },
    data: () => ({
        required: KusBaseRules.required
    }),
    computed: {
        currency: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', (v || '').toUpperCase())
            }
        }
    }
}
</script>
