<template>
    <v-card scrollable>
        <kus-card-title :title="$t('vat.menu')" :dismissible="false" hide-spacer>
            <template #buttons>
                <span class="white--text kus-separator mx-3">|</span>
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-btn small text icon dark to="line" v-on="on" :disabled="loading">
                            <v-icon small>$vuetify.icons.lineMenu</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('lines.menu') }}</span>
                </v-tooltip>
                <span class="white--text kus-separator mx-3">|</span>
                <kus-line-grid-topbar-balance />
                <kus-line-grid-topbar-result />
                <kus-line-grid-topbar-vat />
                <span class="white--text kus-separator mr-3">|</span>
                <v-tooltip v-if="!disabled" bottom>
                    <template #activator="{ on }">
                        <v-btn small text icon dark @click="addGroup" v-on="on" :disabled="loading">
                            <v-icon small>$vuetify.icons.baseAdd</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('balance.group.add') }}</span>
                </v-tooltip>
                <v-tooltip v-if="!disabled" bottom>
                    <template #activator="{ on }">
                        <v-btn small text icon dark @click="refreshSchema()" v-on="on" :disabled="loading">
                            <v-icon small>$vuetify.icons.balanceRefresh</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('balance.refresh') }}</span>
                </v-tooltip>
                <v-btn small text icon dark v-if="disabled" :disabled="exportDisabled" @click="download">
                    <v-icon small>$vuetify.icons.balanceDownload</v-icon>
                </v-btn>
                <v-btn small text icon dark
                    v-if="isAdmin"
                    @click="disabled ? disabled = !disabled : saveSchema()"
                    :disabled="loading">
                    <v-icon v-if="disabled" small>$vuetify.icons.baseEdit</v-icon>
                    <v-icon v-else small>$vuetify.icons.baseSave</v-icon>
                </v-btn>
            </template>
        </kus-card-title>
        <v-card-text v-if="disabled">
            <kus-vat-date-selector v-model="dates" :disabled="!isAdmin" @save="loadBalance(true)" class="mb-5" />

            <kus-vat-accounts-selector
                v-model="accountsResult"
                type="result"
                :disabled="!isAdmin"
                :label="$t('vat.accounts.result')"
                @change="loadBalance(true)" />

            <kus-vat-accounts-selector
                v-model="accountsBalance"
                type="balance"
                :disabled="!isAdmin"
                :label="$t('vat.accounts.balance')"
                @change="loadBalance(true)" />
        </v-card-text>
        <kus-vat-group
            v-if="!disabled"
            class="first"
            ref="list"
            group-account-name="account"
            :groups="groups" />

        <kus-vat-display v-else
            :groups="groups"
            :balance="accountsBalance"
            :result="accountsResult"
            :dates="dates"
            @expanded="onToggleExpand" />

        <kus-balance-remove-group-dialog @remove="removeGroup" />
    </v-card>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusLineGridTopbarBalance from '@/modules/line/gridTopbar/KusLineGridTopbarBalance'
import KusLineGridTopbarResult from '@/modules/line/gridTopbar/KusLineGridTopbarResult'
import KusLineGridTopbarVat from '@/modules/line/gridTopbar/KusLineGridTopbarVat'
import KusVatDisplay from '@/modules/vat/KusVatDisplay'
import KusVatGroup from '@/modules/vat/KusVatGroup'
import KusBalanceRemoveGroupDialog from '@/modules/balance/KusBalanceRemoveGroupDialog'
import KusVatDateSelector from '@/modules/vat/KusVatDateSelector'
import KusVatAccountsSelector from '@/modules/vat/KusVatAccountsSelector'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import lodash from 'lodash'

export default {
    name: 'kus-balance',
    components: {
        KusCardTitle,
        KusLineGridTopbarBalance,
        KusLineGridTopbarResult,
        KusLineGridTopbarVat,
        KusVatDisplay,
        KusVatGroup,
        KusBalanceRemoveGroupDialog,
        KusVatDateSelector,
        KusVatAccountsSelector
    },
    data: () => ({
        pivot: false,
        disabled: true,
        loading: false,
        export: [],
        dates: [],
        datesDefault: [moment().set({
            year: Number(moment().subtract(2, 'year').format('YYYY')),
            month: 11,
            date: 31
        }).format('YYYY-MM-DD'),
        moment().set({
            year: Number(moment().subtract(1, 'year').format('YYYY')),
            month: 11,
            date: 31
        }).format('YYYY-MM-DD')],
        groups: [],
        schema: [],
        accountsBalance: [],
        accountsResult: []
    }),

    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        },

        tokenQuery() {
            return this.$store.getters['auth/tokenQuery']
        },

        groupBalanceId() {
            return this.$store.getters['bal/groupBalanceId']
        },

        datesDisplay() {
            return this.dates
        },

        isAdmin() {
            return this.$store.getters['auth/is']('admin')
        },

        query() {
            return this.dates.reduce(
                (acc, date) => `${acc}&dates=${date}`,
                this.accountsBalance.reduce(
                    (acc, account) => `${acc}&balance=${account}`,
                    this.accountsResult.reduce(
                        (acc, account) => `${acc}&result=${account}`,
                        ''
                    )
                )
            )
        },

        exportDisabled() {
            return !this.accountsBalance.length || !this.accountsResult.length
        }
    },

    mounted() {
        this.loading = true
        this.$store.commit('cls/add', { type: 'main', cls: 'kus-scrollable' })
        return Promise
            .all([
                this.loadPreferences(),
                this.loadSchema()
            ])
            .then(() => this.loadBalance(true))
            .finally(() => (this.loading = false))
    },

    beforeDestroy() {
        this.$store.commit('cls/remove', { type: 'main', cls: 'kus-scrollable' })
    },

    methods: {

        download() {
            if (this.exportDisabled) {
                return
            }
            const ids = this.export.reduce((str, id) => `${str}&id=${id}`, 'id=&id=')
            const url = `${this.$api.defaults.baseURL}/clients/${this.clientId}/vat/export?${this.tokenQuery}&${this.query}&${ids}`
            window.location.href = url
        },

        onToggleExpand(group) {
            const index = this.export.indexOf(group.id)
            if (index === -1 && !group.expanded) {
                return this.export.push(group.id)
            }
            if (index !== -1 && group.expanded) {
                this.export.splice(index, 1)
            }
        },

        loadPreferences() {
            return this.$store.dispatch('pref/get', { type: 'vat-report', clientId: this.clientId, defaultTo: {} })
                .then(pref => {
                    this.dates = pref.dates || this.datesDefault
                    this.accountsBalance = pref.balance || []
                    this.accountsResult = pref.result || []
                })
        },

        loadSchema() {
            return this.$api.get(`/clients/${this.clientId}/vat/schema`)
                .then(res => (this.schema = res.data))
        },

        saveSchema() {
            this.loading = true
            return this.$api.put(`/clients/${this.clientId}/vat/schema`, this.groups)
                .then(async() => {
                    this.disabled = true
                    this.schema = []
                    this.groups = []
                    this.accountsBalance = []
                    this.accountsResult = []
                    await Promise.all([
                        this.loadSchema(),
                        this.loadPreferences()
                    ])
                    return this.loadBalance()
                })
                .finally(() => (this.loading = false))
        },

        refreshSchema() {
            this.loading = true
            return this.$api.post(`/clients/${this.clientId}/vat/schema/refresh`)
                .then(() => {
                    this.schema = []
                    this.groups = []
                    return this.loadSchema()
                })
                .then(() => this.loadBalance())
                .finally(() => (this.loading = false))
        },

        loadBalance(savePref) {
            if (!this.accountsBalance.length || !this.accountsResult.length || this.dates.length !== 2) {
                return
            }
            this.loading = true
            return this.$api.get(`/clients/${this.clientId}/vat?${this.query}`)
                .then(res => {
                    // on merge les valeurs dans les comptes
                    this.groups = this.parseGroups(lodash.cloneDeep(this.schema), res.data, Object.keys(res.data))
                    if (savePref === true) {
                        return this.$store.dispatch('pref/save', {
                            type: 'vat-report',
                            clientId: this.clientId,
                            value: {
                                dates: this.dates,
                                balance: this.accountsBalance,
                                result: this.accountsResult
                            }
                        })
                    }
                })
                .finally(() => (this.loading = false))
        },

        parseGroups(groups, balances, valid) {
            return (groups || []).map(group => {
                if (group.type === 'account') {
                    this.$set(group, 'balances', balances[group.code] || {})
                }
                this.$set(group, 'visible', valid.indexOf(String(group.code)) !== -1 || group.type !== 'account')
                group.groups = this.parseGroups(group.groups, balances, valid)
                return group
            })
        },

        addGroup() {
            const id = uuidv4()
            this.groups.splice(0, 0, {
                id: id,
                type: 'group',
                code: null,
                visible: true,
                balances: {},
                groups: []
            })
            this.$nextTick(() => this.$refs.list.focusFirstGroup())
        },

        removeGroup({ parent, accounts, group }) {
            const p = parent || this
            const index = p.groups.findIndex(g => g.id === group.id)
            if (index !== -1) {
                p.groups.splice(index, 1)
            }
        }
    }
}
</script>
