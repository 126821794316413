<template>
    <v-text-field
        v-model="name"
        :label="$t('lines.gridHeaderEdit.fieldLabel')"
        :rules="rules" />
</template>

<script>
import KusBaseRules from '@/modules/base/KusBaseRules'

export default {
    name: 'kus-line-grid-header-edit-field',
    props: {
        value: { type: String }
    },
    data: () => ({
        rules: [KusBaseRules.required]
    }),
    computed: {
        name: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
