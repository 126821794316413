<template>
    <v-list two-line dense>
        <v-list-item v-for="file in filesUpload" :key="file.id">
            <v-list-item-content>
                <v-list-item-title>{{ file.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="!file.error">{{ file.size | formatSize }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else class="red--text">{{ file.error }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="file.success || file.active">
                <v-btn small text icon :loading="file.active" :color="file.success ? 'green' : 'primary'">
                    <v-icon small>$vuetify.icons.baseDeleteConfirm</v-icon>
                </v-btn>
            </v-list-item-action>
            <v-list-item-action v-else>
                <v-btn small text icon @click="remove(file)" :color="file.error ? 'red' : 'secondary'">
                    <v-icon small>$vuetify.icons.baseClose</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    name: 'kus-document-upload-list',
    computed: {
        filesUpload() {
            return this.$store.getters['doc/filesUpload']
        }
    },
    methods: {
        remove(file) {
            this.$store.commit('doc/removeUpload', { file: file })
        }
    }
}
</script>
