export default {
    methods: {
        mixinKeyDown(event) {
            if (['Escape', 'Enter', 'Tab'].indexOf(event.key) !== -1) {
                return
            }
            event.stopPropagation()
        },

        mixinKeyDownMenu(event) {
            if (event.key === 'Escape') {
                return this.params.api.stopEditing(true)
            }
            if (event.key === 'Tab') {
                return
            }
            if (event.key === 'Enter') {
                if (this.$refs.field.isMenuActive) {
                    // event.preventDefault()
                    // event.stopPropagation()
                    return window.setTimeout(() => {
                        this.params.api.stopEditing()
                        this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.field)
                    }, 50)
                }
                return this.params.api.stopEditing()
            }
        }
    }
}
