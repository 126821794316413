<template>
    <v-form
        class="kus-home-public-password-lost--container mt-4"
        v-model="valid"
        ref="form"
        lazy-validation
        @submit.stop.prevent="submit">
        <v-card :loading="loading ? 'secondary' : false" v-if="active">
            <kus-card-title :title="$t('home.publicPasswordLost.title')" :dismissible="false" />
            <v-card-text>
                <p>{{ $t('home.publicPasswordLost.help') }}</p>
                <kus-user-form-password
                    v-model="password.password"
                    :label="$t('users.account.password')"
                    required />

                <kus-user-form-password
                    v-model="password.confirm"
                    :rules-eq="password.password"
                    :rules-eq-msg="$t('home.publicPasswordLost.passwordSame')"
                    :label="$t('users.account.passwordConfirm')"
                    required />
            </v-card-text>
            <v-card-actions>
                <v-btn
                    type="submit"
                    :disabled="!valid"
                    :loading="loading"
                    class="primary kus-btn-center">
                    {{ $t('home.publicPasswordLost.send') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-alert v-if="!active && !loading" text color="error">
            {{ $t('home.publicPasswordLost.notFound') }}
        </v-alert>
    </v-form>
</template>

<script>
import KusUserFormPassword from '@/modules/user/form/KusUserFormPassword'
import KusCardTitle from '@/modules/card/KusCardTitle'

export default {
    name: 'kus-home-public-password-lost',
    props: {
        token: { type: String, required: true }
    },
    components: { KusCardTitle, KusUserFormPassword },
    data: () => ({
        valid: false,
        loading: false,
        password: {},
        active: false
    }),

    mounted() {
        this.load()
    },

    methods: {
        load() {
            this.loading = true
            return this.$api.get(`/accounts/passwordlost/${this.token}`)
                .then(() => (this.active = true))
                .finally(() => (this.loading = false))
        },

        submit() {
            if (!this.$refs.form.validate()) {
                return
            }
            this.loading = true
            return this.$api.post(`/accounts/passwordlost/${this.token}`, this.password)
                .then(() => {
                    this.$store.commit('msg/show', { message: this.$i18n.t('home.publicPasswordLost.success') })
                    this.$router.replace({ name: 'homepublic' })
                })
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style lang="sass">
.kus-home-public-password-lost--container
    max-width: 600px
    margin: 0 auto

</style>
