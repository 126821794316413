<template>
    <v-text-field
        type="email"
        v-model="email"
        name="rightemail"
        :placeholder="$t('clients.access.headers.email')"
        autocapitalize="none"
        autocomplete="off"
        :rules="[required, emailed]"
        :loading="loading"
        :disabled="loading"
        :readonly="!!id"
        :hint="$t('clients.access.inviteHint', { date: sentAt })"
        :persistent-hint="!!name || (!accepted && !!sentAt)">
        <template #append>
            <v-tooltip>
                <template #activator="{ on }">
                    <v-btn v-if="!accepted" small icon text
                        :color="sentAt && outdated ? 'warning' : 'primary'"
                        @click="send"
                        v-on="on"
                        :loading="loading"
                        :disabled="loading || !id">
                        <v-icon small v-if="!sentAt">$vuetify.icons.clientAccessInvite</v-icon>
                        <v-icon small v-else-if="sentAt && !outdated">$vuetify.icons.clientAccessWait</v-icon>
                        <v-icon small v-else>$vuetify.icons.clientAccessOutdated</v-icon>
                    </v-btn>
                    <v-icon v-else
                        color="success">$vuetify.icons.clientAccessAccepted</v-icon>
                </template>
                <span>{{ $t(`clients.access.${sentAt ? 'reinvite' : 'invite'}`) }}</span>
            </v-tooltip>
        </template>
    </v-text-field>
</template>

<script>
import KusBaseRules from '@/modules/base/KusBaseRules'
import moment from 'moment'

export default {
    name: 'kus-client-access-email',
    props: {
        value: { type: String },
        clientId: { type: String, required: true },
        id: { type: String },
        name: { type: String },
        accepted: { type: Boolean },
        sentAt: { type: String }
    },

    data: () => ({
        loading: false,
        required: KusBaseRules.required,
        emailed: KusBaseRules.emailed
    }),

    computed: {
        email: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        outdated() {
            if (!this.sentAt) {
                return false
            }
            const hours = this.$config.client.invitation.expiration.replace(/[^0-9]/g, '')
            return moment(this.sentAt).add(hours, 'hours').isBefore(moment())
        }
    },

    methods: {
        send() {
            this.loading = true
            return this.$store.dispatch('caccess/invite', { clientId: this.clientId, accessId: this.id })
                .then(() => this.$store.commit('msg/show', { message: this.$i18n.t('clients.access.invitationSent') }))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
