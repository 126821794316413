import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        users: [],
        user: {}
    },

    getters: {
        list: state => state.users,
        current: state => state.user
    },

    mutations: {
        list(state, data) {
            state.users = data.users
        },

        upsert(state, data) {
            const index = state.users.findIndex(u => u.id === data.user.id)
            if (index !== -1) {
                state.users.splice(index, 1, { ...data.user })
            } else {
                state.users.unshift({ ...data.user })
            }
        },

        remove(state, data) {
            const index = state.users.findIndex(u => u.id === data.user.id)
            if (index !== -1) {
                state.users.splice(index, 1)
            }
        },

        current(state, data) {
            state.user = data.user ? { ...data.user } : { role: 'member' }
        }
    },

    actions: {
        list(context, data) {
            const query = `start=${data.start || 0}&limit=${data.limit || 100}&q=${data.q || ''}`
            return Vue.prototype.$api.get(`/users?${query}`)
                .then(res => {
                    context.commit('list', { users: res.data })
                    return context.state.users
                })
        },

        get(context, data) {
            return Vue.prototype.$api.get(`/users/${data.id}`)
                .then(res => {
                    context.commit('current', { user: res.data })
                    return context.state.user
                })
        },

        upsert(context, data) {
            const promise = data.user.id
                ? Vue.prototype.$api.put(`/users/${data.user.id}`, data.user)
                : Vue.prototype.$api.post('/users', { id: 'new', ...data.user })

            return promise.then(res => {
                context.commit('current', { user: res.data })
                context.commit('upsert', { user: res.data })
                return context.state.user
            })
        },

        remove(context, data) {
            return Vue.prototype.$api.delete(`/users/${data.user.id}`)
                .then(res => {
                    context.commit('current', { user: null })
                    context.commit('remove', { user: data.user })
                    return res.data
                })
        }
    }
}
