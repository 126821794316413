import Vue from 'vue'
import { marked } from 'marked'
import DOMPurify from 'dompurify'

marked.use({
    breaks: true
})

const bind = function(el, binding) {
    const cls = (el.className || '').replace(' markdown', '')
    const html = binding.modifiers.inline
        ? marked.parseInline(binding.value || '')
        : marked.parse(binding.value || '')

    el.className = `${cls} markdown`
    el.innerHTML = !binding.modifiers.trust
        ? DOMPurify.sanitize(html)
        : html
}

Vue.directive('marked', {
    bind: bind,
    update: bind
})

Vue.filter('marked', value => DOMPurify.sanitize(marked.parse(value || '')))
