import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        token: Vue.prototype.$storage.get('token'),
        user: {
            id: null,
            currentClientId: null
        },
        access: []
    },

    getters: {
        token: state => state.token,
        tokenHeader: state => `${state.token}`,
        tokenQuery: state => `kus-api-token=${state.token}`,
        connected: state => !!(state.user.id && state.token),
        user: state => state.user,
        client: state => state.user && state.user.currentClientId,
        access: state => state.access,
        is: state => (role, clientId) => {
            clientId = state.user.currentClientId
            const access = (state.access || []).find(access => {
                return access.clientId === clientId && access.role === role
            })
            return !!access
        }
    },

    mutations: {
        login(state, data) {
            state.token = data.token
            Vue.prototype.$storage.set('token', data.token)
        },
        logout(state) {
            state.token = null
            state.user = {}
            Vue.prototype.$storage.remove('token')
        },
        user(state, data) {
            state.user = {
                currentClientId: null,
                ...data.user
            }
        },
        client(state, data) {
            state.user.currentClientId = data.clientId
        },
        access(state, data) {
            state.access = data.access
        }
    },

    actions: {
        login(context, data) {
            return Vue.prototype.$api.post('/', data)
                .then(res => {
                    context.commit('login', { token: res.data.token })
                    context.commit('user', { user: res.data.user })
                    context.commit('access', { access: res.data.access })
                    return context.state.user
                })
        },

        subscribe(context, data) {
            return Vue.prototype.$api.post(`/accounts/subscribe/${data.token}`, data.user)
                .then(res => {
                    return context.dispatch('login', {
                        email: res.data.email,
                        password: data.user.password
                    })
                })
        },

        get(context) {
            return Vue.prototype.$api.get('/accounts')
                .then(res => {
                    context.commit('user', { user: res.data.user })
                    context.commit('access', { access: res.data.access })
                    return context.state.user
                })
        },

        client(context, data) {
            return Vue.prototype.$api.post(`/clients/${data.clientId}/select`)
                .then(() => {
                    context.commit('client', data)
                })
        }
    }
}
