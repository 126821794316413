<template>
    <v-list-item class="kus-document-flow-list--item" :class="{ 'kus-document-flow-list--item_active': active }">
        <v-list-item-action v-if="selectable">
            <v-checkbox v-model="file._selected" />
        </v-list-item-action>
        <v-list-item-content @dblclick="preview" @click="activate">
            <v-list-item-title>
                <span @click="edit()" v-if="!editing">{{ file.title || file.uploadName }}</span>
                <input v-if="editing"
                    type="text"
                    ref="editfield"
                    class="field-in-list pa-0 ma-0"
                    v-model="file.title"
                    :placeholder="$t('document.flowList.editLabel')"
                    @dblclick.stop="() => file"
                    @keypress="typeEdit"
                    @blur="unedit()" />
            </v-list-item-title>
            <v-list-item-subtitle>{{ file.size | formatSize }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action @dblclick="preview" @click="activate">
            <v-menu offset-y @click.native.stop.prevent :disabled="loading">
                <template #activator="{ on }">
                    <v-btn text icon :loading="loading" v-on="on">
                        <v-icon>$vuetify.icons.baseMenuVert</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item @click="putIn()" v-if="inside">
                        <v-list-item-action>
                            <v-icon small :disabled="!flow">$vuetify.icons.baseRight</v-icon>
                        </v-list-item-action>
                        <v-list-item-title :class="{ 'grey--text': !flow }">{{ $t('document.flowList.in', { flow: flow }) }}</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="inside" />
                    <v-list-item @click="edit()" color="green">
                        <v-list-item-action>
                            <v-icon small color="green">$vuetify.icons.baseEdit</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ $t('document.flowList.edit') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item target="_blank" :href="`${file.url}?${tokenQuery}`" color="primary">
                        <v-list-item-action>
                            <v-icon small color="primary">$vuetify.icons.docDownload</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ $t('document.flowList.download') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="popup()">
                        <v-list-item-action>
                            <v-icon small>$vuetify.icons.docOpen</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ $t('document.flowList.preview') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="remove()" color="red">
                        <v-list-item-action>
                            <v-icon small color="red">$vuetify.icons.baseClose</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ $t('document.flowList.remove') }}</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="out" />
                    <v-list-item @click="putOut()" v-if="out">
                        <v-list-item-action>
                            <v-icon small>$vuetify.icons.baseLeft</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ $t('document.flowList.out') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import moment from 'moment'

export default {
    name: 'kus-document-flow-list-item',
    props: {
        file: { type: Object, required: true },
        flow: { type: String },
        inside: { type: Boolean },
        out: { type: Boolean },
        active: { type: Boolean },
        selectable: { type: Boolean }
    },
    data: () => ({
        loading: false,
        editing: false,
        title: null,
        checked: false
    }),
    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        },
        tokenQuery() {
            return this.$store.getters['auth/tokenQuery']
        }
    },
    methods: {
        preview() {
            this.$store.commit('doc/selectUnassociated', { file: this.file })
            this.$store.commit('doc/preview', { file: this.file })
        },

        popup() {
            this.$store.commit('doc/preview', { file: this.file, popup: true })
        },

        activate() {
            this.$emit('activate', this.file)
        },

        remove() {
            this.$emit('remove', this.file)
        },

        putIn() {
            if (!this.flow) {
                return
            }
            this.loading = true
            return this.$api.post(`/documents/${this.file.id}/in/${this.flow || ''}`)
                .then(res => {
                    this.$store.commit('doc/removeFileUnassociated', { file: this.file })
                    this.$store.commit('doc/addFile', { file: res.data })
                })
                .finally(() => (this.loading = false))
        },

        putOut() {
            this.loading = true
            return this.$api.post(`/documents/${this.file.id}/out`)
                .then(res => {
                    this.$store.commit('doc/remove', { file: this.file })
                    this.$store.commit('doc/addFileUnassociated', { file: res.data })
                })
                .finally(() => (this.loading = false))
        },

        edit() {
            this.editing = true
            if (!this.file.title) {
                this.file.title = this.file.uploadName
            }
            this.title = this.file.title
            this.$nextTick(() => this.$refs.editfield.select())
        },

        unedit() {
            if (this.title !== this.file.title) {
                this.save()
            }
            this.editing = false
        },

        typeEdit(e) {
            if (e.keyCode === this.$constant.ENTER) {
                this.unedit()
            }
        },

        save() {
            if (this.loading) {
                return
            }
            this.loading = true
            return this.$api
                .put(`/documents/${this.file.id}`, {
                    title: this.file.title
                })
                .then(() => {
                    this.file.updatedAt = moment().format('YYYY-MM-DD HH:mm:ss')
                })
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style lang="sass">
.field-in-list
    width: 100%

.kus-document-flow-list--item
    user-select: none

.kus-document-flow-list--item_active
    background-color: rgba(220, 220, 220, 0.5)

</style>
