<template>
    <div @keydown="onKeyDown">
        <v-text-field
            ref="field"
            class="pt-0 mt-0"
            v-model="value"
            single-line flat solo
            hide-details />
    </div>
</template>

<script>
import Vue from 'vue'
import SelectFocus from '@/modules/line/mixin/SelectFocus'

export default Vue.extend({
    name: 'kus-line-grid-vat',
    mixins: [SelectFocus],
    data() {
        return {
            value: null
        }
    },
    created() {
        this.value = this.params.value && Number(this.params.value) * 100
    },
    mounted() {
        this.mixinSelectFocusNumber()
    },
    methods: {
        onKeyDown(event) {
            if (['Escape', 'Enter', 'Tab'].indexOf(event.key) !== -1) {
                return
            }
            const reg = /[0-9.,-]+/g
            const keys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete']
            if (keys.indexOf(event.key) === -1 && !reg.test(event.key)) {
                event.preventDefault()
            }
            event.stopPropagation()
        },
        getValue() {
            // on divise le montant saisi par 100, car le système utilise
            // derrière des nombres entre 0 et 1
            return this.value || this.value === 0 ? Number(this.value) / 100 : null
        },
        isPopup() {
            return false
        }
    }
})
</script>
