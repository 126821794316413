<template>
    <v-row align="center">
        <v-icon
            class="mr-1 mt-1"
            :disabled="fullDisabled"
            @click="disabledValue = !disabledValue"
            :color="disabled ? 'red' : ''"
            small>$vuetify.icons.clientAccessDisable</v-icon>
        <v-checkbox
            v-model="unlimitedValue"
            hide-details
            class="shrink mr-0 mt-0"
            :disabled="disabled || fullDisabled" />
        <kus-base-date-range-field
            v-model="dates"
            :disabled="unlimited || disabled || fullDisabled"
            :text="datesLabel"
            :allowed-dates="allowedDates" />
    </v-row>
</template>

<script>
import KusBaseDateRangeField from '@/modules/base/KusBaseDateRangeField'

export default {
    name: 'kus-client-access-right',
    components: { KusBaseDateRangeField },
    props: {
        unlimited: { type: Boolean },
        from: { type: String },
        fromMax: { type: [Boolean, String] },
        to: { type: String },
        toMax: { type: [Boolean, String] },
        disabled: { type: Boolean },
        fullDisabled: { type: Boolean }
    },

    computed: {
        dates: {
            get() {
                if (!this.from && !this.to) {
                    return []
                }
                if (this.from && !this.to) {
                    return [this.from]
                }
                return [this.from, this.to]
            },
            set(v) {
                this.fromValue = this.getFromInLimit(v[0])
                this.toValue = this.getToInLimit(v[1])
            }
        },

        datesLabel() {
            return this.unlimited ? this.$i18n.t('clients.access.unlimited') : null
        },

        unlimitedValue: {
            get() {
                return this.unlimited
            },
            set(v) {
                this.$emit('unlimited', v)
            }
        },

        fromValue: {
            get() {
                return this.from
            },
            set(v) {
                this.$emit('from', v)
            }
        },

        toValue: {
            get() {
                return this.to
            },
            set(v) {
                this.$emit('to', v)
            }
        },

        disabledValue: {
            get() {
                return this.disabled
            },
            set(v) {
                this.$emit('disabled', v)
            }
        },

        fromMaxValue() {
            const v = this.fromMax
            const tmp = v === true || v === false ? '' : v
            return Number((tmp || '').replace(/-/g, ''))
        },

        toMaxValue() {
            const v = this.toMax
            const tmp = v === true || v === false ? '' : v
            return Number((tmp || '').replace(/-/g, ''))
        }
    },

    watch: {
        toMax() {
            this.fromValue = this.getFromInLimit(this.from)
            this.toValue = this.getToInLimit(this.to)
        }
    },

    methods: {
        allowedDates(date) {
            const fmax = this.fromMaxValue
            const tmax = this.toMaxValue
            const d = Number(date.replace(/-/g, ''))
            if ((fmax && d < fmax) || (tmax && d > tmax)) {
                return false
            }
            return true
        },

        getFromInLimit(from) {
            const f = Number((from || '').replace(/-/g, ''))
            if (this.fromMaxValue && f && f < this.fromMaxValue) {
                return this.fromMax
            }
            return from
        },

        getToInLimit(to) {
            const t = Number((to || '').replace(/-/g, ''))
            if (this.toMaxValue && t && t > this.toMaxValue) {
                return this.toMax
            }
            return to
        }
    }
}
</script>
