import KusHomePublic from '@/modules/home/public/KusHomePublic'
import KusHomePublicPasswordLost from '@/modules/home/public/KusHomePublicPasswordLost'
import KusHomePublicSubscribe from '@/modules/home/public/KusHomePublicSubscribe'
import KusHome from '@/modules/home/KusHome'

export default [
    {
        path: '/',
        name: 'homepublic',
        component: KusHomePublic,
        meta: {
            public: true
        }
    },
    {
        path: '/home',
        name: 'home',
        component: KusHome
    },
    {
        path: '/passwordlost/:token',
        name: 'passwordlost',
        component: KusHomePublicPasswordLost,
        props: true,
        meta: {
            public: true
        }
    },
    {
        path: '/subscribe/:token',
        name: 'subscribe',
        component: KusHomePublicSubscribe,
        props: true,
        meta: {
            public: true
        }
    }
]
