import KusResult from '@/modules/result/KusResult'
import KusLineTopbar from '@/modules/line/KusLineTopbar'

export default [
    {
        path: '/result',
        name: 'result',
        component: KusResult,
        meta: {
            titleCmp: KusLineTopbar
        }
    }
]
