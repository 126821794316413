<template>
    <kus-base-date-range-field
        input-class="py-0 pr-3"
        v-model="dates"
        :label="$t('vat.dateLabel')"
        :disabled="disabled"
        hide-details
        @input="change" />
</template>

<script>
import KusBaseDateRangeField from '@/modules/base/KusBaseDateRangeField'

export default {
    name: 'kus-vat-date-selector',
    components: { KusBaseDateRangeField },
    props: {
        value: { type: Array },
        disabled: { type: Boolean }
    },
    computed: {
        dates: {
            get() {
                return this.value
            },
            set(v) {
                v.sort()
                this.$emit('input', v)
            }
        }
    },
    methods: {

        async change() {
            await this.$nextTick()
            if (this.dates.length === 2) {
                this.$emit('save', this.dates)
            }
        }
    }
}
</script>
