import KusBalance from '@/modules/balance/KusBalance'
import KusLineTopbar from '@/modules/line/KusLineTopbar'

export default [
    {
        path: '/balance',
        name: 'balance',
        component: KusBalance,
        meta: {
            titleCmp: KusLineTopbar
        }
    }
]
