<template>
    <v-dialog v-model="show" max-width="400">
        <v-card>
            <kus-card-title :title="$t('lines.removePopup.title', { block: line.block })" color="red" @close="show = false" />
            <v-card-text v-marked="$t('lines.removePopup.message', { block: line.block, date: line.opDate })" />
            <v-card-actions>
                <v-spacer />
                <v-btn @click="remove"
                    icon text
                    color="red"
                    :disabled="loading"
                    :loading="loading">
                    <v-icon>$vuetify.icons.baseDelete</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'

export default {
    name: 'kus-line-remove-popup',
    components: { KusCardTitle },
    props: {
        removeMode: { type: Boolean, default: false }
    },
    data: () => ({
        loading: false
    }),
    computed: {
        show: {
            get() {
                return this.$store.getters['linegrid/removePopup']
            },
            set(v) {
                this.$store.commit('linegrid/removePopup', { show: false })
            }
        },
        line() {
            return this.$store.getters['linegrid/removeLine'] || {}
        },
        clientId() {
            return this.$store.getters['auth/client']
        }
    },
    methods: {
        remove() {
            this.loading = true
            const block = this.line.block
            this.$api.delete(`/clients/${this.clientId}/blocks/${block}`)
                .then(res => {
                    this.show = false
                    this.$store.commit('linegrid/removeBlock', { lines: res.data, remove: !this.removeMode })
                    // on reload la zone documents, dans le cas où des docs de flux sont revenus
                    // en non-assigné
                    return this.$store.dispatch('doc/filesUnassociated', { clientId: this.clientId })
                })
                .finally(() => (this.loading = false))
        }
    }
}
</script>
