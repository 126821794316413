import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        roles: []
    },

    getters: {
        list: state => state.roles
    },

    mutations: {
        list(state, data) {
            state.roles = data.roles
        }
    },

    actions: {
        list(context, data) {
            return Vue.prototype.$api.get('/roles')
                .then(res => {
                    context.commit('list', { roles: res.data })
                    return context.state.roles
                })
        }
    }
}
