<template>
    <v-simple-table class="kus-balance--table">
        <template #default>
            <thead>
                <tr>
                    <th class="kus-balance--header-name">&nbsp;</th>
                    <th class="text-right kus-balance--no-wrap" v-for="h in headers" :key="`${h[0]}${h[1]}`">{{ $t('result.dateHeader', { from: h[0], to: h[1] }) }}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(row, index) in flatGroups"
                    :key="index"
                    :class="{ group: row.expandable}"
                    v-show="!row.hidden && row.visible">
                    <td :style="{ 'padding-left': `${1.5 * row.level}em` }" @click="row.expandable && toggle(row, index)">
                        <v-icon small v-if="row.expandable">
                            {{ row.expanded ? '$vuetify.icons.balanceCollapse' : '$vuetify.icons.balanceExpand' }}
                        </v-icon>
                        <v-icon small v-else>$vuetify.icons.balanceNotExpandable</v-icon>
                        {{ row.code }} {{ row.name }}
                    </td>
                    <td v-for="year in years"
                        :key="year"
                        class="text-right"
                        :class="{ 'kus-link-grid': row.type === 'account' }"
                        @dblclick="showGrid(row, year)">
                        <span :class="{ 'grey--text': !row.balances[year] }">{{ row.balances[year] | number }}</span>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import moment from 'moment'

export default {
    name: 'kus-result-display',
    props: {
        groups: { type: Array, required: true },
        headers: { type: Array },
        inverseSign: { type: Boolean }
    },
    data: () => ({
        flatGroups: []
    }),
    computed: {
        years() {
            return this.headers.map(h => `${moment(h[0]).format('YYYY-MM-DD')}-${moment(h[1]).format('YYYY-MM-DD')}`)
        },

        groupBalanceId() {
            return this.$store.getters['bal/groupBalanceId']
        },

        clientId() {
            return this.$store.getters['auth/client']
        }
    },
    mounted() {
        this.setFlatGroups()
    },
    watch: {
        groups: 'setFlatGroups',
        inverseSign: 'toggleSign'
    },
    methods: {

        toggleSign(v) {
            this.flatGroups.forEach(row => {
                this.years.forEach(year => {
                    row.balances[year] *= -1
                })
            })
        },

        showGrid(account, year) {
            if (account.type !== 'account') {
                return
            }
            this.$store.dispatch('pref/saveGrid', {
                clientId: this.clientId,
                sort: 'account',
                dir: 'asc',
                all: false,
                sum: 'relative',
                query: {
                    accountType: 'result',
                    accountCode: account.code,
                    opDate: `${year.substr(0, 10)}<>${year.substr(11)}`
                }
            })
            this.$router.push({ name: 'line' })
        },

        setFlatGroups() {
            const flatGroups = this.flattenGroups(this.groups, 1)
            const group = this.getGroupById(this.groups, this.groupBalanceId)
            if (group) {
                this.$set(group, 'balances', this.years.reduce((acc, date) => {
                    const total = flatGroups.reduce((sum, r) => {
                        if (r.level === 1 && r.id !== this.groupBalanceId) {
                            return sum + r.balances[date]
                        }
                        return sum
                    }, 0)
                    acc[date] = total * (this.inverseSign ? -1 : 1)
                    return acc
                }, {}))

                // on recalcule le tout, après avoir trouvé le montant du groupe
                // spécial "équilibre"
                this.flatGroups = this.flattenGroups(this.groups, 1)
            } else {
                this.flatGroups = flatGroups
            }
        },

        toggle(group, index) {
            group.expanded = !group.expanded
            for (let i = index + 1; i < this.flatGroups.length; i += 1) {
                if (this.flatGroups[i].level > group.level) {
                    this.flatGroups[i].expanded = group.expanded
                    this.flatGroups[i].hidden = !group.expanded
                } else {
                    break
                }
            }
            this.$emit('expanded', group)
        },

        getGroupById(groups, id) {
            let found
            for (let g = 0; g < groups.length; g += 1) {
                if (groups[g].id === id) {
                    return groups[g]
                }
                found = this.getGroupById(groups[g].groups, id)
                if (found) {
                    return found
                }
            }
            return null
        },

        getFirstAccount(groups) {
            return groups.reduce((account, group) => {
                if (group.accounts.length) {
                    return group.accounts[0]
                }
                return this.getFirstAccount(group.groups)
            }, null)
        },

        flattenGroups(groups, level) {
            return groups.reduce((acc, group) => {
                const g = {
                    type: group.type,
                    id: group.id,
                    name: group.name,
                    code: group.code,
                    level: level,
                    visible: group.visible,
                    hidden: false,
                    expanded: true,
                    expandable: group.id !== this.groupBalanceId && !!group.groups.length,
                    balances: this.years.reduce((b, year) => {
                        b[year] = b[year] || 0
                        if (this.inverseSign) {
                            b[year] *= -1
                        }
                        return b
                    }, { ...group.balances })
                }
                acc.push(g)
                if (group.groups.length) {
                    const subgroups = this.flattenGroups(group.groups, level + 1)
                    subgroups
                        .filter(sg => sg.level === level + 1)
                        .forEach(sg => this.years.forEach(year => {
                            g.balances[year] += sg.balances[year]
                        }))

                    acc = acc.concat(subgroups)
                    // permet d'afficher l'arobrescence, ou de la cacher si aucun
                    // compte visible n'existe dedans
                    if (g.type !== 'account' && !subgroups.find(s => s.visible) && !subgroups.find(s => s.type === 'account' && s.visible)) {
                        g.visible = false
                    }
                }
                return acc
            }, [])
        }
    }
}
</script>

<style lang="sass">
.kus-balance--table
    .kus-balance--header-name
        width: 100%

    .group td
        font-weight: bold

    .kus-balance--no-wrap
        white-space: nowrap

    .kus-link-grid
        cursor: pointer

</style>
