import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        flows: [],
        clients: [],
        labels: [],
        vatCodes: [],
        codes: [],
        accounts: [],
        forex: []
    },

    getters: {
        flows: state => state.flows,
        clients: state => state.clients,
        labels: state => state.labels,
        vatCodes: state => state.vatCodes,
        codes: state => state.codes,
        accounts: state => state.accounts,
        forex: state => state.forex
    },

    mutations: {
        setData(state, data) {
            state[data.field] = data.data
            if (data.field !== 'accounts') {
                state[data.field].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
            }
        },

        addData(state, data) {
            if (!data.value || state[data.field].indexOf(data.value) !== -1) {
                return
            }
            state[data.field].push(data.value)
            if (data.field !== 'accounts') {
                state[data.field].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
            }
        },

        setCode(state, data) {
            const index = state.codes.findIndex(code => code.field === data.field)
            if (index !== -1) {
                state.codes.splice(index, 1)
            }
            state.codes.push({ ...data })
        },

        removeCode(state, data) {
            const c = state.codes.find(code => code.field === data.field)
            if (!c) {
                return
            }
            const index = c.data.findIndex(d => d === data.value)
            if (index !== -1) {
                c.data.splice(index, 1)
            }
        },

        removeData(state, data) {
            const index = state[data.field].findIndex(d => d === data.value)
            if (index !== -1) {
                state[data.field].splice(index, 1)
            }
        },

        reset(state) {
            state.flows = []
            state.clients = []
            state.labels = []
            state.vatCodes = []
            state.codes = []
            state.accounts = []
        },

        forex(state, data) {
            state.forex = data.forex
        }
    },

    actions: {
        flows(context, data) {
            return Vue.prototype.$api.get(`/clients/${data.clientId}/flows`)
                .then(res => context.commit('setData', { data: res.data, field: 'flows' }))
        },

        clients(context, data) {
            return Vue.prototype.$api.get(`/clients/${data.clientId}/clients`)
                .then(res => context.commit('setData', { data: res.data, field: 'clients' }))
        },

        labels(context, data) {
            return Vue.prototype.$api.get(`/clients/${data.clientId}/labels`)
                .then(res => context.commit('setData', { data: res.data, field: 'labels' }))
        },

        vatCodes(context, data) {
            return Vue.prototype.$api.get(`/clients/${data.clientId}/vatcodes`)
                .then(res => context.commit('setData', { data: res.data, field: 'vatCodes' }))
        },

        accounts(context, data) {
            return Vue.prototype.$api.get(`/clients/${data.clientId}/accounts`)
                .then(res => context.commit('setData', { data: res.data, field: 'accounts' }))
        },

        exclude(context, data) {
            return Vue.prototype.$api.delete(`/clients/${data.clientId}/${data.field}?excludeItem=${data.value}`)
                .then(() => context.commit('removeData', data))
        },

        codes(context, data) {
            return Vue.prototype.$api.get(`/clients/${data.clientId}/codes/${data.code}`)
                .then(res => context.commit('setCode', { data: res.data, field: data.code }))
        },

        excludeCode(context, data) {
            return Vue.prototype.$api.delete(`/clients/${data.clientId}/codes/${data.code}?excludeItem=${data.value}`)
                .then(() => context.commit('removeCode', { ...data, field: data.code }))
        },

        forex(context, data) {
            return Vue.prototype.$api.get(`/forex/${data.date.format('YYYY-MM-DD')}`)
                .then(res => context.commit('forex', { forex: res.data }))
        }
    }
}
