import Vue from 'vue'
import lodash from 'lodash'

const defaultHeaders = [
    { field: 'docNb', search: 'KusLineSearchBoolean', hidden: false, width: 55, pin: 'left' },
    { field: 'flow', search: 'KusLineSearchText', hidden: true, width: 120, sortable: true },
    { field: 'block', search: 'KusLineSearchText', hidden: true, width: 120, sortable: true },
    { field: 'opDate', search: 'KusLineSearchDate', type: 'date', hidden: false, width: 110, sortable: true },
    { field: 'opYear', hidden: true, width: 100, sortable: true, sortField: 'opDate' },
    { field: 'opMonth', hidden: true, width: 100, sortable: true, sortField: 'opDate' },
    { field: 'opYearMonth', hidden: true, width: 100, sortable: true, sortField: 'opDate' },
    { field: 'account', hidden: false, width: 200, sortable: true, sortField: 'accountCode' },
    { field: 'accountCode', search: 'KusLineSearchText', hidden: true, width: 70, sortable: true },
    { field: 'accountName', search: 'KusLineSearchText', hidden: true, width: 70, sortable: true },
    { field: 'accountType', search: 'KusLineSearchAccountType', hidden: true, width: 70, sortable: true },
    { field: 'client', search: 'KusLineSearchText', hidden: false, width: 300, sortable: true },
    { field: 'label', search: 'KusLineSearchText', hidden: false, width: 300, sortable: true },
    { field: 'code1', search: 'KusLineSearchText', hidden: false, width: 200, sortable: true },
    { field: 'vatCode', search: 'KusLineSearchVatCode', hidden: false, width: 100, sortable: true },
    { field: 'vat', search: 'KusLineSearchNumber', hidden: false, width: 100, sortable: true },
    { field: 'foreignCurrency', search: 'KusLineSearchText', hidden: true, width: 70, sortable: true },
    { field: 'amountForeignCurrency', search: 'KusLineSearchNumber', hidden: true, width: 100, sortable: true },
    { field: 'change', search: 'KusLineSearchNumber', hidden: true, width: 100, sortable: true },
    { field: 'currency', search: 'KusLineSearchText', hidden: true, width: 70, sortable: true },
    { field: 'amount', search: 'KusLineSearchNumber', hidden: false, width: 100, sortable: true, pin: 'right' },
    { field: 'balance', hidden: false, width: 100, pin: 'right' },
    { field: 'balanceForeignCurrency', hidden: true, width: 100, pin: 'right' },
    { field: 'code2', search: 'KusLineSearchText', hidden: true, width: 200, sortable: true },
    { field: 'code3', search: 'KusLineSearchText', hidden: true, width: 200, sortable: true },
    { field: 'code4', search: 'KusLineSearchText', hidden: true, width: 200, sortable: true },
    { field: 'code5', search: 'KusLineSearchText', hidden: true, width: 200, sortable: true },
    { field: 'code6', search: 'KusLineSearchText', hidden: true, width: 200, sortable: true },
    { field: 'code7', search: 'KusLineSearchText', hidden: true, width: 200, sortable: true },
    { field: 'code8', search: 'KusLineSearchText', hidden: true, width: 200, sortable: true },
    { field: 'code9', search: 'KusLineSearchText', hidden: true, width: 200, sortable: true },
    { field: 'payStatus', search: 'KusLineSearchPayStatus', hidden: true, width: 110, sortable: true },
    { field: 'payDate', search: 'KusLineSearchDate', type: 'date', hidden: true, width: 110, sortable: true },
    { field: 'payYear', hidden: true, width: 100, sortable: true, sortField: 'payDate' },
    { field: 'payMonth', hidden: true, width: 100, sortable: true, sortField: 'payDate' },
    { field: 'payYearMonth', hidden: true, width: 100, sortable: true, sortField: 'payDate' },
    { field: 'createdAt', search: 'KusLineSearchDate', hidden: true, width: 100, sortable: true },
    { field: 'createdBy', search: 'KusLineSearchText', hidden: true, width: 100, sortable: true },
    { field: 'updatedAt', search: 'KusLineSearchDate', hidden: true, width: 100, sortable: true },
    { field: 'updatedBy', search: 'KusLineSearchText', hidden: true, width: 100, sortable: true },
    { field: 'deletedAt', search: 'KusLineSearchDate', hidden: true, width: 100, sortable: true },
    { field: 'deletedBy', search: 'KusLineSearchText', hidden: true, width: 100, sortable: true },
    { field: 'deleted', hidden: true, width: 50, sortable: true, sortField: 'deletedAt' },
    { field: 'isTech', hidden: true, width: 80, sortable: true },
    { field: 'id', hidden: true, sortable: true }
]

const defaultEditHeaders = [
    { field: 'docNb', hidden: true },
    { field: 'flow', hidden: true },
    { field: 'balance', hidden: true }
]

const addGridSpecialColumns = line => {
    // split de la date d'opération afin de pivoter sur l'année ou le mois
    if (line.opDate) {
        const date = line.opDate.split('-')
        line.opYear = date[0]
        line.opMonth = date[1]
        line.opYearMonth = `${date[0]}-${date[1]}`

        // split de la date de paiement afin de pivoter sur l'année ou le mois
        if (line.payDate) {
            const pdate = line.payDate.split('-')
            line.payYear = pdate[0]
            line.payMonth = pdate[1]
            line.payYearMonth = `${date[0]}-${date[1]}`
        }
    }
    // définition du compte, pour permettre la saisie complexe
    if (line.accountCode) {
        line.account = {
            name: line.accountName,
            code: line.accountCode,
            type: line.accountType
        }
    }
    return line
}

export default {
    namespaced: true,

    state: {
        headers: [],
        lines: null,
        editHeaders: [],
        editHeaderCount: 0,
        editHeaderChanged: '',
        editHeaderPopup: false,
        editHeader: {},
        removePopup: false,
        removeLine: {},
        sumType: 'absolute'
    },

    getters: {
        headers: state => state.headers,
        lines: state => state.lines,
        editHeaders: state => state.editHeaders,
        editHeaderPopup: state => state.editHeaderPopup,
        editHeader: state => state.editHeader,
        headerChanged: state => state.editHeaderChanged,
        removePopup: state => state.removePopup,
        removeLine: state => state.removeLine,
        sumType: state => state.sumType
    },

    mutations: {
        headers(state, data) {
            state.headers = data.headers
        },

        editHeaders(state, data) {
            state.editHeaders = data.headers
        },

        lines(state, data) {
            state.lines = data.lines.map(addGridSpecialColumns)
        },

        addLines(state, data) {
            const toAdd = (data.lines || []).reduce((acc, line) => {
                if (line.isTech) {
                    // on vérifie si la ligne technique est déjà présente
                    const index = state.lines.findIndex(l => l.id === line.id)
                    if (index === -1) {
                        acc.push(line)
                    }
                } else {
                    acc.push(line)
                }
                return acc
            }, [])
            state.lines = state.lines.concat(toAdd.map(addGridSpecialColumns))
        },

        updateLines(state, data) {
            const lines = data.lines.reduce((acc, line) => {
                acc[line.id] = addGridSpecialColumns(line)
                return acc
            }, {})
            state.lines.forEach(line => {
                if (lines[line.id]) {
                    Object.entries(lines[line.id]).forEach(a => {
                        line[a[0]] = a[1]
                    })
                } else if (data.flow && line.flow === data.flow) {
                    Object.entries(data.flowData).forEach(a => {
                        line[a[0]] = a[1]
                    })
                }
            })
        },

        updateFlowLines(state, data) {
            state.lines.forEach(line => {
                if (line.flow === data.flow) {
                    line.docNb = data.docNb
                }
            })
        },

        editHeaderPopup(state, data) {
            state.editHeaderPopup = data.show !== undefined ? data.show : true
            state.editHeader = { ...data.column || {} }
        },

        headerChanged(state, data) {
            const headers = data.type !== 'main' ? state.editHeaders : state.headers
            const header = headers.find(h => h.field === data.field)
            header.name = data.name

            state.editHeaderCount += 1
            if (state.editHeaderCount > 100) {
                state.editHeaderCount = 0
            }
            state.editHeaderChanged = `${data.type}-${state.editHeaderCount}`
        },

        headersChanged(state, data) {
            const headers = data.type !== 'main' ? state.editHeaders : state.headers
            data.state.forEach((col, i) => {
                const header = headers.find(h => h.field === col.colId.replace('_1', ''))
                if (!header) {
                    // eslint-disable-next-line
                    console.log(col)
                    return
                }
                header._sort = i
                Object.entries(col).forEach(d => (header[d[0]] = d[1]))
            })
            headers.sort((a, b) => a._sort > b._sort ? 1 : -1)

            state.editHeaderCount += 1
            if (state.editHeaderCount > 100) {
                state.editHeaderCount = 0
            }
            state.editHeaderChanged = `${data.type}-${state.editHeaderCount}`
        },

        removePopup(state, data) {
            state.removePopup = data.show !== undefined ? data.show : true
            state.removeLine = { ...data.line }
        },

        removeBlock(state, data) {
            const hashmap = data.lines.reduce((acc, line) => {
                acc[line.id] = line
                return acc
            }, {})
            const ids = Object.keys(hashmap)

            state.lines = state.lines.map((line, i) => {
                if (ids.indexOf(line.id) !== -1) {
                    return hashmap[line.id]
                }
                return line
            })

            if (data.remove) {
                const tmp = state.lines
                lodash.remove(tmp, line => ids.indexOf(line.id) !== -1)
                state.lines = tmp.slice()
            }
        },

        sumType(state, data) {
            state.sumType = data.type || 'absolute'
        }
    },

    actions: {
        headers(context, data) {
            const hashmap = defaultHeaders.reduce((acc, h) => {
                h.minWidth = h.width
                h.name = ''
                acc[h.field] = h
                return acc
            }, {})
            return Promise
                .all([
                    Vue.prototype.$api.get(`/clients/${data.clientId}/preferences/columns`),
                    Vue.prototype.$api.get(`/clients/${data.clientId}/preferences/columns-edit`),
                    Vue.prototype.$api.get(`/clients/${data.clientId}/preferences/columns-default`)
                ])
                .then(res => {
                    const coldefault = res[2]
                    const columns = res[0].data.length
                        ? res[0].data
                        : defaultHeaders.slice().map(h => lodash.pick(h, ['field', 'hidden', 'pin', 'width']))

                    const edits = res[1].data.length
                        ? res[1].data
                        : defaultHeaders.slice().map(h => {
                            const e = defaultEditHeaders.find(eh => eh.field === h.field) || {}
                            return lodash.pick({ ...h, ...e }, ['field', 'hidden', 'pin', 'width'])
                        })

                    // il y a 3 cas de figure:
                    // 1. il n'y a pas de custom du client ni du user connecté
                    // 2. il y a du custom seulement client
                    // 3. il y a du custom user connecté avec client ou non
                    // Pour chacun de ces cas, il faut qu'on gère les headers
                    // de différentes facçons
                    const hashDefault = coldefault.data.reduce((acc, h) => {
                        acc[h.field] = h
                        return acc
                    }, {})
                    context.commit('headers', {
                        headers: columns.map(h => ({
                            ...(hashmap[h.field] || {}),
                            ...(hashDefault[h.field] || {}),
                            ...h
                        }))
                    })
                    context.commit('editHeaders', {
                        headers: edits.map(h => ({
                            ...(hashmap[h.field] || {}),
                            ...(hashDefault[h.field] || {}),
                            ...h
                        }))
                    })
                })
        },

        lines(context, data) {
            const sort = data.sort || 'opDate'
            return Vue.prototype.$api.get(`/clients/${data.clientId}/lines?all=${data.all ? 'true' : 'false'}&query=${data.query || ''}&dir=${data.dir || 'asc'}&sort=${sort}&sortField=${data.sortField || ''}&sum=${context.state.sumType || ''}`)
                .then(res => {
                    context.commit('lines', { lines: res.data })
                })
        },

        pivotLines(context, data) {
            const sort = data.sort || 'opDate'
            return Vue.prototype.$api.get(`/clients/${data.clientId}/lines?all=false&query=${data.query || ''}&dir=${data.dir || 'asc'}&sort=${sort}&sortField=${data.sortField || ''}&sum=${context.state.sumType || ''}`)
                .then(res => res.data.map(addGridSpecialColumns))
        },

        popupLines(context, data) {
            return Promise
                .resolve()
                .then(() => {
                    if (!data.flow) {
                        return Vue.prototype.$api.get(`/clients/${data.clientId}/blocks/${data.block}`)
                    }
                    return Vue.prototype.$api.get(`/clients/${data.clientId}/flows/${data.flow}`)
                })
                .then(res => res.data.map(addGridSpecialColumns))
        },

        column(context, data) {
            if (data.pivot) {
                return
            }
            const type = data.type !== 'main' ? 'columns-edit' : 'columns'

            const validHeaders = defaultHeaders.map(h => h.field)
            const cols = data.state.filter(col => validHeaders.indexOf(col.colId) !== -1)
            return Vue.prototype.$api
                .put(`/clients/${data.clientId}/preferences/${type}`, cols.map(col => ({
                    field: col.colId,
                    hidden: col.hide,
                    pin: col.pinned,
                    width: col.width,
                    sort: col.sort
                })))
                .then(() => {
                    // Plus besoin de notifier les changements de headers
                    // context.commit('headersChanged', data)
                })
        },

        value(context, data) {
            if (data.pivot) {
                return
            }

            const send = {
                id: data.line.id,
                cas: data.line.cas,
                editedColumns: []
            }
            if (data.columns) {
                data.columns.forEach(col => {
                    send.editedColumns.push(col.column)
                    send[col.column] = col.value
                })
            } else {
                send.editedColumns.push(data.column)
                send[data.column] = data.value
            }
            return Vue.prototype.$api.put(`/clients/${data.clientId}/lines/${send.id}`, send)
                .then(res => {
                    context.commit('updateLines', { lines: res.data })
                    return res.data
                })
        }
    }
}
