<template>
    <v-menu open-on-hover offset-y v-model="menu" :close-on-content-click="false" :disabled="loading">
        <template #activator="{ on: menu }">
            <v-btn
                v-on="{ ...menu }"
                small icon text dark
                :loading="loading"
                :disabled="loading">
                <v-icon small>$vuetify.icons.lineImport</v-icon>
            </v-btn>
        </template>
        <v-card>
            <kus-card-title :title="$t('lines.import.btn')" :dismissible="false" />
            <v-card-text>
                <header>{{ $t('lines.import.encoding') }}</header>
                <v-radio-group v-model="encoding" row>
                    <v-radio v-for="enc in encodings" :key="enc" :label="$t(`lines.import.encodings.${enc}`)" :value="enc" />
                </v-radio-group>
            </v-card-text>
            <v-card-actions>
                <kus-line-upload-btn
                    block
                    :loading="loading"
                    :params="options"
                    :tooltip="$t('lines.import.btn')"
                    @upload:start="uploadStart"
                    @upload:error="uploadError"
                    @upload:success="uploaded" />
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusLineUploadBtn from '@/modules/line/KusLineUploadBtn'

export default {
    name: 'kus-line-grid-topbar-import',
    components: { KusLineUploadBtn, KusCardTitle },
    data: () => ({
        menu: false,
        loading: false,
        encoding: 'utf8'
    }),
    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        },

        encodings() {
            return ['utf8']
        },

        options() {
            return { encoding: this.encoding }
        }
    },
    methods: {

        uploadStart(ev) {
            this.loading = true
            this.$emit('upload:start', ev)
        },

        uploadError(ev) {
            this.loading = false
            this.$emit('upload:error', ev)
        },

        uploaded(ev) {
            this.loading = false
            this.$store.commit('msg/show', { message: this.$i18n.t('lines.import.success') })
            this.$emit('upload:success', ev)
        }
    }
}
</script>
