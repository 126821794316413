<template>
    <v-card>
        <kus-card-title color="secondary"
            :title="file.title || file.uploadName"
            :dismissible="dismissible"
            @close="onClose"
            extension-float
            single-line>
            <template #buttons>
                <v-btn text icon small dark @click="info = !info" class="mx-3">
                    <v-icon small>{{ !info ? '$vuetify.icons.baseHelpOn' : '$vuetify.icons.baseHelp' }}</v-icon>
                </v-btn>
                <v-btn icon text small dark :href="`${file.url}?${tokenQuery}`" target="_blank">
                    <v-icon small>$vuetify.icons.docDownload</v-icon>
                </v-btn>
            </template>
            <template #extension>
                <kus-document-metadata v-show="info" :file="file" class="pb-3" dark />
            </template>
        </kus-card-title>
        <v-card-text v-if="isImage" class="pa-0" :class="{ expand: expand }">
            <v-img
                :src="`${file.url}?${tokenQuery}`"
                :alt="file.name">
                <template #placeholder>
                    <v-layout justify-center ma-0>
                        <v-progress-circular indeterminate color="grey" />
                    </v-layout>
                </template>
            </v-img>
        </v-card-text>
        <object v-if="isPdf"
            class="kus-document-preview-pdf"
            :class="{ expand: expand }"
            :data="`${file.url}?${tokenQuery}`"
            :type="file.contentType"
            allowfullscreen
            width="100%"
            height="100%">
            <a :href="`${file.url}?${tokenQuery}`" target="_blank">{{ $t('document.nopreview') }}</a>
        </object>
    </v-card>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusDocumentMetadata from '@/modules/document/KusDocumentMetadata'

export default {
    name: 'kus-document-preview-card',
    components: { KusCardTitle, KusDocumentMetadata },
    props: {
        file: { type: Object },
        dismissible: { type: Boolean, default: true },
        expand: { type: Boolean }
    },
    data: () => ({
        info: false
    }),
    computed: {
        isImage() {
            return ['jpg', 'jpeg', 'png'].indexOf(this.file.extension) !== -1
        },
        isPdf() {
            return this.file.extension === 'pdf'
        },
        tokenQuery() {
            return this.$store.getters['auth/tokenQuery']
        }
    },
    methods: {
        onClose(ev) {
            this.$emit('close', ev)
        }
    }
}
</script>

<style lang="sass">
.kus-document-preview-pdf
    height: 100vh

.kus-document-preview-pdf.expand
    height: calc(100vh - 182px)

.kus-document-preview-metadata .v-list-item
    height: auto !important

</style>
