<template>
    <v-tour :name="name" :steps="steps">
        <template slot-scope="tour">
            <transition name="fade">
                <v-step
                    v-for="(step, index) of tour.steps"
                    :key="index"
                    v-if="tour.currentStep === index"
                    :step="step"
                    :previous-step="tour.previousStep"
                    :next-step="tour.nextStep"
                    :stop="tour.stop"
                    :skip="tour.skip"
                    :is-first="tour.isFirst"
                    :is-last="tour.isLast"
                    :labels="tour.labels">
                    <template #content>
                        <kus-base-video v-if="step.video" :value="step.video" />
                        <div v-else v-marked="step.content"></div>
                    </template>
                </v-step>
            </transition>
        </template>
    </v-tour>
</template>

<script>
import KusBaseVideo from '@/modules/base/KusBaseVideo'

export default {
    name: 'kus-onboarding-tour',
    components: { KusBaseVideo },
    props: {
        name: { type: String }
    },
    data() {
        return {
            steps: [
                {
                    target: '#kusgridtopmenubtn',
                    video: 'test1',
                    header: {
                        title: 'Découverte'
                    }
                },
                {
                    target: '#kusgridunassoctab',
                    content: 'Des choses sur les documents',
                    before: () => {
                        return this.$store.dispatch('pref/setClientPref', { clientId: this.clientId, key: 'docpnl', value: true })
                    },
                    header: {
                        title: 'Découverte'
                    }
                },
                {
                    target: '#kusgriddocshow',
                    video: 'demo2-southafrica',
                    header: {
                        title: 'Gestion des documents'
                    }
                },
                {
                    target: '#kushelpbtn',
                    content: 'Un peu de texte en plus, avec **de la mise en forme** et des [liens](https://www.voilab.ch).',
                    header: {
                        title: 'FAQ'
                    }
                }
            ]
        }
    },
    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        }
    }
}
</script>
