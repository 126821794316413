<template>
    <v-dialog max-width="400" v-model="show" :persistent="loading">
        <template #activator="{ on }">
            <p
                v-on="on"
                class="kus-btn-password-lost primary--text caption"
                :class="{ 'kus-disabled': disabled }">
                {{ $t('home.publicPasswordLostButton.btn') }}
            </p>
        </template>
        <v-form v-model="valid" ref="form" lazy-validation @submit.stop.prevent="submit">
            <v-card :loading="loading ? 'secondary' : false">
                <kus-card-title :title="$t('home.publicPasswordLostButton.title')" :dismissible="!loading" @close="show = false" />

                <v-card-text class="pt-3" v-if="!done">
                    {{ $t('home.publicPasswordLostButton.message') }}
                    <v-text-field
                        ref="field"
                        class="pt-5"
                        v-model="data"
                        type="email"
                        name="email"
                        autocapitalize="none"
                        :rules="[required, emailed]">
                        <template #label>
                            <v-icon style="vertical-align: middle" small>$vuetify.icons.loginEmail</v-icon>
                            {{ $t('home.publicLogin.email') }}
                        </template>
                    </v-text-field>
                </v-card-text>

                <v-card-text v-else class="pt-5">
                    <v-alert text color="success">
                        <h3 class="headline">{{ $t('home.publicPasswordLostButton.done') }}</h3>
                        <v-divider class="my-2 green" />
                        <p>{{ $t('home.publicPasswordLostButton.doneMessage', { email: data }) }}</p>
                    </v-alert>
                </v-card-text>

                <v-card-actions v-if="!done">
                    <v-btn
                        type="submit"
                        @click="submit"
                        :loading="loading"
                        :disabled="($refs.field && !$refs.field.valid) || !data"
                        class="primary kus-btn-center">
                        {{ $t('home.publicPasswordLostButton.send') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusBaseRules from '@/modules/base/KusBaseRules'

export default {
    name: 'kus-home-public-password-lost-btn',
    components: { KusCardTitle },
    props: {
        email: { type: String },
        disabled: { type: Boolean }
    },
    data: () => ({
        valid: false,
        show: false,
        loading: false,
        done: false,
        data: null,
        required: KusBaseRules.required,
        emailed: KusBaseRules.emailed
    }),
    watch: {
        show(v) {
            v && this.load()
        }
    },
    methods: {
        load() {
            window.setTimeout(() => this.$refs.field.focus(), 300)
            this.data = this.email
            this.done = false
        },

        submit() {
            // pour une raison inconnue, le submit est appelé 2x ici
            if (!this.$refs.form.validate() || this.loading) {
                return
            }
            this.loading = true
            return this.$api.post('/accounts/passwordlost', { email: this.data })
                .then(() => (this.done = true))
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style lang="sass">
.kus-btn-password-lost
    text-align: right
    cursor: pointer
    margin-bottom: 30px !important

</style>
