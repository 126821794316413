<template>
    <v-menu offset-y v-model="menu">
        <template #activator="{ on: menu }">
            <v-btn
                v-on="{ ...menu }"
                :id="id"
                small icon dark
                class="mr-3">
                <v-icon small>$vuetify.icons.baseMenuVert</v-icon>
            </v-btn>
        </template>
        <v-list dense>
            <v-list-item-group color="primary">
                <v-list-item @click="e => $emit('adjustColumns', e)">
                    <v-list-item-icon>
                        <v-icon small>$vuetify.icons.lineAdjustColumns</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('lines.adjustColumns') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="e => $emit('reloadDeleted', e)">
                    <v-list-item-icon>
                        <v-icon small>{{ `$vuetify.icons.lineShowDeleted${showDeleted ? 'On' : 'Off'}` }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('lines.showDeleted') }}</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'kus-line-grid-topbar-menu',
    props: {
        showDeleted: { type: Boolean },
        id: { type: String }
    },
    data: () => ({
        menu: false
    })
}
</script>
