import Vue from 'vue'

export default {
    namespaced: true,

    actions: {
        async get(context, { id }) {
            const res = await Vue.prototype.$api.get(`/contents/${id}`)
            return res.data
        }
    }
}
