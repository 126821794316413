<template>
    <v-dialog v-model="show" max-width="400">
        <v-form v-model="valid" @submit.stop.prevent="save">
            <v-card>
                <kus-card-title :title="$t('lines.gridHeaderEdit.title', { column: column.field })" @close="show = false" />
                <v-card-text>
                    <kus-line-grid-header-edit-field v-model="column.headerName" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn type="submit"
                        icon text
                        color="primary"
                        :disabled="!valid || loading"
                        :loading="loading">
                        <v-icon>$vuetify.icons.baseSave</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusLineGridHeaderEditField from '@/modules/line/gridHeaderEdit/KusLineGridHeaderEditField'

export default {
    name: 'kus-line-grid-header-edit-popup',
    components: { KusCardTitle, KusLineGridHeaderEditField },
    props: {
        type: { type: String }
    },
    data: () => ({
        valid: false,
        loading: false
    }),
    computed: {
        show: {
            get() {
                return this.$store.getters['linegrid/editHeaderPopup']
            },
            set(v) {
                this.$store.commit('linegrid/editHeaderPopup', { show: false })
            }
        },
        column() {
            return this.$store.getters['linegrid/editHeader']
        },
        clientId() {
            return this.$store.getters['auth/client']
        }
    },
    methods: {
        save() {
            this.loading = true
            const data = {
                field: this.column.field,
                name: this.column.headerName,
                type: this.type
            }
            this.$api.put(`/clients/${this.clientId}/preferences/columns-default`, data)
                .then(() => {
                    this.$store.commit('linegrid/headerChanged', data)
                    this.show = false
                })
                .finally(() => (this.loading = false))
        }
    }
}
</script>
