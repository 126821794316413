<template>
    <v-flex shrink
        class="kus-slider-container"
        :class="classes"
        @mousedown.prevent="start">
        <div class="kus-slider"></div>
    </v-flex>
</template>

<script>
export default {
    name: 'kus-base-layout-slider',
    props: {
        disabled: { type: Boolean },
        value: { type: Number },
        horizontal: { type: Boolean },
        vertical: { type: Boolean }
    },
    data() {
        return {
            moveListener: (e) => this.resize(e),
            upListener: () => this.stop(),
            startValue: null,
            startPos: null
        }
    },
    computed: {
        classes() {
            return {
                horizontal: this.horizontal || !this.vertical,
                vertical: this.vertical,
                disabled: this.disabled
            }
        }
    },
    destroyed() {
        this.stop()
    },
    methods: {
        start() {
            if (this.disabled) {
                return
            }
            window.addEventListener('mousemove', this.moveListener)
            window.addEventListener('mouseup', this.upListener)
            this.$store.commit('cls/add', { type: 'app', cls: 'kus-mouse-move' })
        },

        stop() {
            window.removeEventListener('mousemove', this.moveListener)
            window.removeEventListener('mouseup', this.upListener)
            this.$store.commit('cls/remove', { type: 'app', cls: 'kus-mouse-move' })
            this.$emit('resized')
        },

        resize(e) {
            if (this.startValue === null) {
                this.startValue = this.value
                this.startPos = this.horizontal
                    ? this.$el.getBoundingClientRect().left
                    : this.$el.getBoundingClientRect().top
            }
            const calc = this.horizontal
                ? e.pageX - this.startPos + this.startValue
                : this.startValue + (this.startPos - e.pageY)

            const min = this.horizontal ? 250 : 100
            const max = this.horizontal ? 1000 : window.innerHeight - 250

            if (calc < min || calc > max) {
                return
            }
            this.$emit('input', calc)
        }
    }
}
</script>

<style lang="sass">
.kus-slider-container
    background-color: #ccc

    &.horizontal
        cursor: col-resize

        .kus-slider
            width: 3px

    &.vertical
        cursor: row-resize

        .kus-slider
            height: 3px

    &.disabled
        cursor: auto !important

.v-application.kus-mouse-move object
    pointer-events: none !important

</style>
