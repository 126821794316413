<template>
    <div>
        <kus-user-list-search start-load>
            <template #append-outer>
                <v-icon color="primary" @click="add">$vuetify.icons.userAdd</v-icon>
            </template>
        </kus-user-list-search>
        <v-list>
            <v-list-item
                v-for="user in users"
                :key="user.id"
                :to="{ name: 'userform', params: { id: user.id } }">
                <v-list-item-content>
                    <v-list-item-title>{{ user.firstname }} {{ user.lastname }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import KusUserListSearch from '@/modules/user/KusUserListSearch'

export default {
    name: 'kus-user-list',
    components: { KusUserListSearch },
    computed: {
        users() {
            return this.$store.getters['usr/list']
        }
    },

    methods: {
        add() {
            this.$router.push({ name: 'userform', params: { id: '0' } })
        }
    }
}
</script>
