import Vue from 'vue'
import lodash from 'lodash'

let client
try {
    client = JSON.parse(Vue.prototype.$storage.get('cs')) || {}
} catch (e) {
    client = {}
}

export default {
    namespaced: true,

    state: {
        clientStoragePrefs: client
    },

    getters: {
        getGrid: state => clientId => {
            return state.clientStoragePrefs[clientId] || {}
        },
        getClientPref: state => (clientId, pref, def) => {
            const res = (state.clientStoragePrefs[clientId] || {})[pref]
            if (res === undefined && def !== undefined) {
                // on va setter la var dans le stockage pour permettre ensuite la réactivité dans les vues
                if (!state.clientStoragePrefs[clientId]) {
                    Vue.set(state.clientStoragePrefs, clientId, {})
                }
                Vue.set(state.clientStoragePrefs[clientId], pref, def)
                Vue.prototype.$storage.set('cs', JSON.stringify(state.clientStoragePrefs))

                return state.clientStoragePrefs[clientId][pref]
            }
            return res
        }
    },

    actions: {
        get(context, data) {
            return Vue.prototype.$api.get(`/clients/${data.clientId}/preferences/${data.type}`)
                .then(res => {
                    if (lodash.isArray(res.data) && !res.data.length) {
                        return data.defaultTo
                    }
                    return res.data || data.defaultTo
                })
        },

        save(context, data) {
            return Vue.prototype.$api.put(`/clients/${data.clientId}/preferences/${data.type}`, data.value)
        },

        getGrid(context, data) {
            if (!context.state.clientStoragePrefs[data.clientId]) {
                return { gs: 'opDate', gd: 'asc' }
            }
            return context.state.clientStoragePrefs[data.clientId]
        },

        saveGrid(context, data) {
            if (!context.state.clientStoragePrefs[data.clientId]) {
                context.state.clientStoragePrefs[data.clientId] = {}
            }

            if (lodash.isObject(data.query)) {
                const query = lodash.reduce(data.query, (acc, value, key) => {
                    acc.push(`${key}::${value}`)
                    return acc
                }, [])
                data.query = `=${query.join(';;')}`
                data.advanced = true
            }

            context.state.clientStoragePrefs[data.clientId].gs = data.sort
            context.state.clientStoragePrefs[data.clientId].gd = data.dir
            context.state.clientStoragePrefs[data.clientId].gq = data.query
            context.state.clientStoragePrefs[data.clientId].gv = data.advanced
            context.state.clientStoragePrefs[data.clientId].ga = data.all
            context.state.clientStoragePrefs[data.clientId].su = data.sum

            Vue.prototype.$storage.set('cs', JSON.stringify(context.state.clientStoragePrefs))
        },

        setClientPref({ state }, data) {
            if (!state.clientStoragePrefs[data.clientId]) {
                Vue.set(state.clientStoragePrefs, data.clientId, {})
            }
            Vue.set(state.clientStoragePrefs[data.clientId], data.key, data.value)
            Vue.prototype.$storage.set('cs', JSON.stringify(state.clientStoragePrefs))
        }
    }
}
