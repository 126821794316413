<template>
    <div>
        <ag-grid-vue
            :columnDefs="columns"
            :rowData="lines"
            rowSelection="multiple"
            class="ag-theme-balham"
            :style="{ height: '300px' }">
        </ag-grid-vue>
    </div>
</template>

<script>
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

import { AgGridVue } from 'ag-grid-vue'

export default {
    name: 'kus-base-test',
    components: {
        AgGridVue
    },
    data() {
        return {
            gridOptions: {},
            columns: [],
            lines: []
        }
    },
    mounted() {
        this.loadHeaders().then(() => this.loadData())
    },
    methods: {
        loadHeaders() {
            return new Promise(resolve => {
                window.setTimeout(() => {
                    this.columns = [
                        {
                            headerName: 'A',
                            field: 'a',
                            width: 300,
                            editable: true
                        },
                        {
                            headerName: 'B',
                            field: 'b',
                            width: 300,
                            editable: true
                        },
                        {
                            headerName: 'C',
                            field: 'id',
                            width: 300,
                            editable: true
                        }
                    ]
                    resolve()
                }, 1000)
            })
        },

        loadData() {
            return new Promise(resolve => {
                window.setTimeout(() => {
                    this.lines = [
                        { a: 'a1', b: 1, id: 1 },
                        { a: 'a2', b: 2, id: 2 },
                        { a: 'a3', b: 3, id: 3 },
                        { a: '', b: 4, id: 4 }
                    ]
                    resolve()
                })
            }, 1000)
        }
    }
}
</script>
