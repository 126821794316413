import Vue from 'vue'

export default {
    namespaced: true,

    state: {},

    getters: {},

    mutations: {},

    actions: {
        async createUser() {
            const res = await Vue.prototype.$api.post('/demo')
            return res.data
        },

        async createClient() {
            const res = await Vue.prototype.$api.post('/demo/clients')
            return res.data
        },

        async createLines(context, data) {
            const res = await Vue.prototype.$api.post(`/demo/clients/${data.clientId}/lines`)
            return res.data
        }
    }
}
