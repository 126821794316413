<template>
    <v-dialog v-model="show" max-width="450">
        <v-card v-if="show">
            <kus-card-title color="red lighten-1" :title="$t('balance.group.remove')" @close="show = false"  />
            <v-card-text
                v-marked="$t('balance.group.removeContent', { group: group.name })">
            </v-card-text>
            <v-card-actions>
                <v-btn @click="show = false" :disabled="disabled" text color="grey">
                    {{ $t('base.no') }}
                </v-btn>
                <v-spacer />
                <v-btn @click="remove" :disabled="disabled" text color="red">
                    {{ $t('base.yes') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'

export default {
    name: 'kus-balance-remove-group-dialog',
    components: { KusCardTitle },
    data: () => ({
        disabled: false
    }),
    computed: {
        show: {
            get() {
                return this.$store.getters['bal/removeDialog']
            },
            set(v) {
                this.$store.commit('bal/hide')
            }
        },
        group() {
            return this.$store.getters['bal/group']
        },
        parent() {
            return this.$store.getters['bal/parent']
        },
        accounts() {
            return this.$store.getters['bal/accounts']
        }
    },
    methods: {
        remove() {
            this.$emit('remove', {
                parent: this.parent,
                accounts: this.accounts,
                group: this.group
            })
            this.$nextTick(() => (this.show = false))
        }
    }
}
</script>
