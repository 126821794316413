import KusClientListVue from '@/modules/client/KusClientListVue'
import KusClientTab from '@/modules/client/KusClientTab'
import KusClientAccessVue from '@/modules/client/access/KusClientAccessVue'

export default [
    {
        path: '/client',
        name: 'client',
        component: KusClientListVue,
        children: [
            {
                path: ':id',
                name: 'clientform',
                component: KusClientTab,
                props: true
            }
        ]
    },
    {
        path: '/client-access/:token',
        name: 'clientaccess',
        component: KusClientAccessVue,
        props: true,
        meta: {
            public: true,
            private: true
        }
    }
]
