<template>
    <v-select
        ref="field"
        :label="label"
        :items="items"
        v-model="select"
        :hint="hint"
        :persistent-hint="!!hint" />
</template>

<script>
export default {
    name: 'kus-line-search-boolean',
    props: {
        value: { type: String },
        label: { type: String },
        hint: { type: String }
    },
    computed: {
        select: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        items() {
            return [
                { text: this.$i18n.t('lines.search.boolTrue'), value: '1' },
                { text: this.$i18n.t('lines.search.boolFalse'), value: '0' }
            ]
        }
    },
    methods: {

        focus() {
            this.$nextTick(() => this.$refs.field.focus())
        }
    }
}
</script>
