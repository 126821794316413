import KusLineHome from '@/modules/line/KusLineHome'
import KusLineTopbar from '@/modules/line/KusLineTopbar'

export default [
    {
        path: '/line',
        name: 'line',
        component: KusLineHome,
        meta: {
            titleCmp: KusLineTopbar
        }
    }
]
