import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import en from 'vuetify/es5/locale/en'
import fr from 'vuetify/es5/locale/fr'
import de from 'vuetify/es5/locale/de'
import it from 'vuetify/es5/locale/it'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            // base
            baseUpload: 'mdi-cloud-upload',
            baseHelp: 'mdi-help-circle',
            baseHelpOn: 'mdi-help-circle-outline',
            baseSave: 'mdi-content-save',
            baseClose: 'mdi-close',
            baseDelete: 'mdi-delete',
            baseDeleteConfirm: 'mdi-check',
            baseBack: 'mdi-undo',
            baseMenu: 'mdi-menu',
            baseMenuVert: 'mdi-dots-vertical',
            baseAdd: 'mdi-plus',
            baseEdit: 'mdi-pencil',
            baseDown: 'mdi-chevron-down',
            baseUp: 'mdi-chevron-up',
            baseRight: 'mdi-chevron-right',
            baseLeft: 'mdi-chevron-left',
            baseMenuLeft: 'mdi-menu-left',
            baseMenuRight: 'mdi-menu-right',
            baseSearch: 'mdi-magnify',
            // balance
            balanceDrag: 'mdi-menu',
            balanceExpand: 'mdi-chevron-right',
            balanceCollapse: 'mdi-chevron-down',
            balanceNotExpandable: 'mdi-circle-small',
            balanceDownload: 'mdi-export',
            balanceRefresh: 'mdi-refresh',
            // client
            clientForm: 'mdi-information',
            clientAdd: 'mdi-account-plus',
            clientMenu: 'mdi-account-tie',
            clientSelect: 'mdi-select-all',
            // clientaccess
            clientAccess: 'mdi-key',
            clientAccessInvite: 'mdi-send',
            clientAccessDisable: 'mdi-cancel',
            clientAccessAccepted: 'mdi-check',
            clientAccessWait: 'mdi-send-clock',
            clientAccessOutdated: 'mdi-timer-off',
            // home
            loginEmail: 'mdi-at',
            loginPassword: 'mdi-lock',
            // document
            docDownload: 'mdi-cloud-download',
            docOpen: 'mdi-eye',
            docShow: 'mdi-file-multiple',
            // line
            lineMenu: 'mdi-grid',
            lineShowDeletedOn: 'mdi-delete-sweep',
            lineShowDeletedOff: 'mdi-delete-sweep-outline',
            linePivot: 'mdi-view-quilt',
            lineShow: 'mdi-reorder-horizontal',
            lineSearchAdvanced: 'mdi-magnify-plus',
            lineSearchSimple: 'mdi-magnify-minus',
            lineImport: 'mdi-import',
            lineExport: 'mdi-export',
            lineSort: 'mdi-sort',
            lineAdjustColumns: 'mdi-view-column',
            lineForexBtn: 'mdi-calculator',
            // user
            userMenu: 'mdi-account',
            userAccount: 'mdi-account-edit',
            userLogout: 'mdi-undo',
            userAdd: 'mdi-account-plus'
        }
    },
    lang: {
        locales: { en, fr, de, it },
        current: 'fr'
    }
})
