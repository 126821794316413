<template>
    <v-menu
        :close-on-content-click="false"
        v-model="menu"
        transition="scale-transition"
        :disabled="disabled"
        offset-y
        min-width="290px">
        <template #activator="{ on }">
            <v-text-field
                v-on="on"
                autocomplete="off"
                :value="dateFormatted"
                :label="$t('clients.form.pivotDate')"
                :hint="hint"
                :rules="[required]"
                :disabled="disabled"
                readonly
                persistent-hint />

        </template>
        <v-date-picker
            v-model="date"
            @input="menu = false"
            no-title />
    </v-menu>
</template>

<script>
import KusBaseRules from '@/modules/base/KusBaseRules'
import moment from 'moment'

export default {
    name: 'kus-client-form-pivot-date',
    props: {
        value: { type: String },
        disabled: { type: Boolean }
    },
    data: () => ({
        menu: false,
        required: KusBaseRules.required,
        year: moment().format('YYYY')
    }),
    computed: {
        date: {
            get() {
                return `${this.year}-${this.value}`
            },
            set(v) {
                this.$emit('input', v && moment(v).format('MM-DD'))
            }
        },

        dateFormatted() {
            return moment(this.date).format('Do MMMM')
        },

        hint() {
            const date = moment(this.date).add(1, 'day')
            if (moment(this.date).isBefore(date, 'year')) {
                return this.$i18n.t('clients.form.pivotDateHintYear', { date: date })
            }
            return this.$i18n.t('clients.form.pivotDateHint', { date: date })
        }
    }
}
</script>
