<template>
    <v-form v-model="valid" ref="form" @submit.stop.prevent="submit">
        <v-card :loading="loading ? 'secondary' : false">
            <kus-card-title :title="createMode ? $t('users.form.create') : $t('users.form.title', user)" :dismissible="false" />
            <v-card-text>
                <kus-user-form-email v-model="user.email" />
                <kus-user-form-name
                    v-model="user.firstname"
                    :label="$t('users.form.firstname')"
                    name="firstname" />

                <kus-user-form-name
                    v-model="user.lastname"
                    :label="$t('users.form.lastname')"
                    name="lastname" />

                <kus-user-form-roles v-model="user.role" />
            </v-card-text>
            <v-card-actions>
                <kus-card-remove-btn
                    :title="$t('users.remove.title')"
                    @remove="remove"
                    :loading="loading">
                    <template #content>
                        {{ $t('users.remove.text', user) }}
                    </template>
                </kus-card-remove-btn>
                <v-spacer />
                <v-btn
                    :disabled="!valid"
                    type="submit"
                    absolute dark fab bottom right
                    color="primary">
                    <v-icon>$vuetify.icons.baseSave</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusCardRemoveBtn from '@/modules/card/KusCardRemoveBtn'
import KusUserFormEmail from '@/modules/user/form/KusUserFormEmail'
import KusUserFormName from '@/modules/user/form/KusUserFormName'
import KusUserFormRoles from '@/modules/user/form/KusUserFormRoles'

export default {
    name: 'kus-user-form',
    components: {
        KusCardTitle,
        KusCardRemoveBtn,
        KusUserFormEmail,
        KusUserFormName,
        KusUserFormRoles
    },
    props: {
        id: { type: String, required: true }
    },
    data: () => ({
        loading: false,
        valid: false
    }),

    computed: {
        user() {
            return this.$store.getters['usr/current']
        },
        createMode() {
            return !this.id || this.id === '0'
        }
    },

    watch: {
        $route: 'load'
    },

    mounted() {
        this.load()
    },

    methods: {

        load() {
            if (this.createMode) {
                return this.$store.commit('usr/current', {})
            }
            this.loading = true
            return this.$store.dispatch('usr/get', { id: this.id })
                .finally(() => (this.loading = false))
        },

        submit() {
            if (!this.$refs.form.validate()) {
                return
            }
            this.loading = true
            return this.$store.dispatch('usr/upsert', { user: this.user })
                .then(user => {
                    if (this.createMode) {
                        this.$router.push({ name: 'userform', params: { id: user.id } })
                    }
                    this.$store.commit('msg/show', { message: this.$i18n.t('users.form.saved') })
                })
                .finally(() => (this.loading = false))
        },

        remove() {
            this.loading = true
            return this.$store.dispatch('usr/remove', { user: this.user })
                .then(() => {
                    this.$store.commit('msg/show', { message: this.$i18n.t('users.remove.success') })
                    this.$router.push({ name: 'user' })
                })
                .finally(() => (this.loading = false))
        }
    }
}
</script>
