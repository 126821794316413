<template>
    <v-list class="pt-0">
        <v-toolbar dense flat class="kus-menu--topbar">
            <v-btn text icon class="ml-0" @click="mini = !mini">
                <v-icon>{{ mini ? '$vuetify.icons.baseMenuRight' : '$vuetify.icons.baseMenuLeft' }}</v-icon>
            </v-btn>
        </v-toolbar>

        <p v-if="!mini" class="mt-4 px-3">
            Bonjour
            <strong>{{ user.firstname }} {{ user.name }}</strong>
            <br />
            <small>Version {{ $config.version }}</small>
        </p>

        <template v-for="(item, index) in items">
            <v-divider v-if="item.divider" :key="index" />
            <v-list-item v-else
                :disabled="item.disabled"
                :key="item.text"
                :to="item.to"
                v-rights="item.rights"
                @click="(item.click && item.click(item)) || null">
                <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
export default {
    name: 'kus-base-menu',
    props: {
        value: { type: Boolean }
    },
    computed: {
        mini: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        user() {
            return this.$store.getters['auth/user']
        },
        client() {
            return this.$store.getters['auth/client']
        },
        items() {
            return [
                { to: { name: 'line' }, rights: 'member', icon: '$vuetify.icons.lineMenu', text: this.$i18n.t('lines.menu'), disabled: !this.client },
                { to: { name: 'client' }, rights: 'member', icon: '$vuetify.icons.clientMenu', text: this.$i18n.t('clients.menu') },
                { to: { name: 'user' }, rights: 'admin', icon: '$vuetify.icons.userMenu', text: this.$i18n.t('users.menu') }
            ]
        }
    }
}
</script>

<style lang="sass">
.kus-menu--topbar .v-toolbar__content
    padding-left: 4px

</style>
