<template>
    <div @keydown="onKeyDown">
        <v-menu
            :close-on-content-click="false"
            v-model="menu"
            transition="scale-transition"
            offset-y
            min-width="290px"
            :content-class="contentClass">
            <template #activator="{ on }">
                <v-text-field
                    v-on="on"
                    ref="field"
                    class="pt-0 mt-0"
                    v-model="dateFormatted"
                    single-line flat solo
                    hide-details />
            </template>
            <v-date-picker
                v-model="value"
                @input="closeMenu"
                no-title />
        </v-menu>
    </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import lodash from 'lodash'
import SelectFocus from '@/modules/line/mixin/SelectFocus'

export default Vue.extend({
    name: 'kus-line-grid-date',
    mixins: [SelectFocus],
    data() {
        return {
            menu: false,
            contentClass: '',
            value: '',
            tmpValue: ''
        }
    },
    computed: {
        dateFormatted: {
            get() {
                const tmp = (this.value || '').split('-')
                return tmp.reverse().join('.')
            },
            set(v) {
                const tmp = lodash.compact(v.split('.')).reverse()
                this.tmpValue = tmp.join('-')
                if (tmp.length > 1) {
                    this.value = this.tmpValue
                }
            }
        }
    },
    created() {
        this.value = this.params.value || ''
        if (this.params.charPress) {
            this.tmpValue = this.params.charPress
        }
    },
    mounted() {
        if (this.params.context && this.params.context.attach) {
            this.contentClass = 'maxZindex'
        }
        this.mixinSelectFocus()
    },
    methods: {
        onKeyDown(event) {
            this.params.context.keypressEnter = event.key === 'Enter'
            if (event.keyCode === 40) {
                this.menu = true
                return
            }
            if (['Escape', 'Enter', 'Tab'].indexOf(event.key) !== -1) {
                return
            }
            const reg = /[0-9.]+/g
            const keys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete']
            if (keys.indexOf(event.key) === -1 && !reg.test(event.key)) {
                event.preventDefault()
            }
            event.stopPropagation()
        },
        isCancelAfterEnd() {
            const tmp = this.tmpValue ? this.tmpValue.split('-') : []
            const now = moment()
            let acc = []
            if (tmp.length === 1) {
                acc = [
                    now.format('YYYY'),
                    now.format('MM'),
                    tmp[0].padStart(2, 0)
                ]
            } else if (tmp.length === 2) {
                acc = [
                    now.format('YYYY'),
                    tmp[0].padStart(2, 0),
                    tmp[1].padStart(2, 0)
                ]
            } else if (tmp.length === 3) {
                const year = tmp[0].length === 2
                    ? now.format('YYYY').substr(0, 2) + tmp[0]
                    : tmp[0]

                acc = [
                    year,
                    tmp[1].padStart(2, 0),
                    tmp[2].padStart(2, 0)
                ]
            }
            if (acc.length) {
                this.value = acc.join('-')
            }
        },
        closeMenu() {
            this.menu = false
            this.$nextTick(() => this.$refs.field.focus())
        },
        getValue() {
            return this.value || null
        },
        isPopup() {
            return false
        }
    }
})
</script>
