<template>
    <v-text-field
        name="kus-client-search"
        v-model="search"
        :label="$t('clients.list.search')"
        single-line
        hide-details
        prepend-inner-icon="$vuetify.icons.baseSearch"
        @click:append="submit"
        :loading="loading"
        clearable>
        <template #append-outer>
            <slot name="append-outer"></slot>
        </template>
    </v-text-field>
</template>

<script>
import lodash from 'lodash'

export default {
    name: 'kus-client-list-search',
    props: {
        startLoad: { type: Boolean }
    },
    data: () => ({
        search: null,
        loading: false
    }),

    watch: {
        search() {
            this.onTypeSubmit()
        }
    },

    mounted() {
        return this.startLoad && this.submit()
    },

    methods: {

        submit() {
            this.loading = true
            return this.$store.dispatch('client/list', { limit: 100, q: this.search })
                .finally(() => (this.loading = false))
        },

        onTypeSubmit: lodash.debounce(function() {
            this.submit()
        }, 800)
    }
}
</script>
