
export default {
    namespaced: true,

    state: {
        show: false,
        message: null,
        detail: null
    },

    getters: {
        show: state => state.show,
        message: state => state.message,
        detail: state => state.detail
    },

    mutations: {
        show(state, data) {
            state.show = true
            state.message = data.message
            state.detail = data.detail
        },

        hide(state) {
            state.show = false
        }
    }
}
