import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        helper: false,
        currentFlow: null
    },

    getters: {
        helper: state => state.helper,
        currentFlow: state => state.currentFlow
    },

    mutations: {
        helper(state, data) {
            state.helper = !!data.show
        },

        currentFlow(state, data) {
            state.currentFlow = data && data.flow
        },

        reset(state) {
            state.currentFlow = null
            state.helper = false
        }
    },

    actions: {
        forex(context, data) {
            return Vue.prototype.$api.get(`/forex/${data.from}/${data.to}/${data.date}?amount=${data.amount}`)
                .then(res => res.data)
        }
    }
}
