<template>
    <v-card v-show="show" flat class="kus-line-edit">
        <kus-card-title
            :title="$t(block ? 'lines.editBlock' : 'lines.add.btn', { block: block, flow: currentFlow })"
            @close="show = false"
            hide-spacer>
            <template #buttons>
                <v-spacer />
                <v-btn dark small class="mr-5" :class="{ green: valid, grey: !valid }" @click="save">
                    <v-icon small>$vuetify.icons.baseSave</v-icon>
                </v-btn>
                <kus-document-upload-btn small
                    v-if="currentFlow"
                    :flow="currentFlow"
                    @upload:success="saveDocUploaded"
                    @upload:end="saveDocUploadedEnd"
                    :tooltip="$t('document.upload.tooltip')"
                    class="mr-3" />
            </template>
        </kus-card-title>
        <v-card-text class="kus-line-edit-content pa-0" ref="dialog">
            <v-tabs
                v-model="tab"
                centered
                icons
                color="secondary"
                dark
                :style="{ height: `${height + slideHeight}px` }"
                :class="{ hideSlider: hideTabs }">
                <v-tab href="#lines" class="white--text">
                    <v-icon>$vuetify.icons.lineShow</v-icon>
                </v-tab>
                <v-tab href="#documents" :disabled="!currentFlow" class="white--text">
                    <v-badge overlap :value="!!docCount">
                        <template #badge>
                            {{ docCount }}
                        </template>
                        <v-icon>$vuetify.icons.docShow</v-icon>
                    </v-badge>
                </v-tab>
                <v-tab-item value="lines">
                    <kus-line-grid
                        ref="grid"
                        :style="{ height: `${height + (hideTabs ? slideHeight : 0)}px` }"
                        @gridReady="gridReady"
                        @cellValueChanged="updateBottom"
                        @mounted="selectFirstLine"
                        @removeLine="removeLine"
                        @changeValue="changeValue"
                        @update:skipHeadersChanges="v => skipHeadersChanges = v"
                        :skipHeadersChanges="skipHeadersChanges"
                        :suppressKeyboardEvent="suppressKeyboardEvent"
                        :pinnedBottomRowData="pinnedBottomRowData"
                        :getRowStyle="getRowStyle"
                        :data="lines"
                        header-type="edit"
                        phantom
                        disable-cell-click />
                </v-tab-item>
                <v-tab-item value="documents">
                    <kus-document-flow-list v-if="block && lines.length" />
                </v-tab-item>
            </v-tabs>
        </v-card-text>
    </v-card>
</template>

<script>
import lodash from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import KusCardTitle from '@/modules/card/KusCardTitle'
import KusLineGrid from '@/modules/line/grid/KusLineGrid'
import KusDocumentUploadBtn from '@/modules/document/KusDocumentUploadBtn'
import KusDocumentFlowList from '@/modules/document/KusDocumentFlowList'

export default {
    name: 'kus-line-add',
    components: {
        KusCardTitle,
        KusLineGrid,
        KusDocumentUploadBtn,
        KusDocumentFlowList
    },
    props: {
        hideTabs: { type: Boolean }
    },
    data: () => ({
        slideHeight: 48,
        lines: [],
        loading: false,
        suppressKeyboardEvent: null,
        skipHeadersChanges: true,
        pinnedBottomRowData: [],
        tab: null,
        updateColumns: false
    }),
    computed: {
        show: {
            get() {
                return this.$store.getters['lineedit/show']
            },
            set(value) {
                this.$store.commit('lineedit/show', { show: value })
            }
        },
        height() {
            return this.$store.getters['lineedit/height']
        },
        headers() {
            return this.$store.getters['linegrid/editHeaders']
        },
        block() {
            return this.$store.getters['lineedit/block']
        },
        field() {
            return this.$store.getters['lineedit/field']
        },
        valid() {
            if (!this.pinnedBottomRowData.length || !this.lines.length) {
                return false
            }
            const line = this.lines[0]
            const required = ['opDate', 'accountCode']
            return !this.pinnedBottomRowData[0].amount && !required.find(f => !line[f])
        },
        clientId() {
            return this.$store.getters['auth/client']
        },
        client() {
            return this.$store.getters['client/current']
        },
        createMode() {
            return !this.block
        },
        currentFlow() {
            return (this.lines.length && this.lines[0].flow) || ''
        },
        currentDocumentPreview() {
            return this.$store.getters['doc/preview']
        },

        previewIsUnassociated() {
            return this.$store.getters['doc/previewIsUnassociated']
        },

        docCount() {
            return this.$store.getters['doc/filesLength']
        },
        headerChanged() {
            return this.$store.getters['linegrid/headerChanged']
        },
        reset() {
            return this.$store.getters['lineedit/reset']
        }
    },
    beforeMount() {
        this.suppressKeyboardEvent = params => {
            const event = params.event
            if (event.shiftKey && event.ctrlKey) {
                if ([this.$constant.UP, this.$constant.DOWN].indexOf(event.which) !== -1) {
                    this.move(event.which === this.$constant.UP, params)
                    return true
                }
            }
            if (event.shiftKey && event.which === this.$constant.ENTER) {
                this.$refs.grid.options().api.stopEditing()
                this.save()
                return true
            }
            if (params.node.rowIndex < this.lines.length - 1) {
                return false
            }

            const last = this.$refs.grid.options().columnApi.getAllDisplayedColumns()
                .slice()
                .reverse()
                .find(col => col.colDef.editable !== false)

            if (!event.shiftKey && event.which === this.$constant.TAB && params.column.colId === last.colId) {
                // l'event est appelé curiseusement 2x
                event.preventDefault()
                this.addBounced()
                return false
            }
            return false
        }
    },
    watch: {
        show(v) {
            if (v) {
                this.skipHeadersChanges = true
                this.$refs.grid.setHeaders(this.headers)
                this.resetGrid({ keepPreview: true })
            } else {
                this.$store.commit('doc/reset', { keepUnassociated: true })
                this.$store.commit('line/currentFlow', { flow: null })
                this.$store.commit('lineedit/hide')
            }
        },
        block() {
            !this.loading && this.block && this.load().then(() => this.updateBottom())
        },
        headerChanged() {
            this.updateColumns = true
        },
        field(v) {
            this.tab = 'lines'
        },
        docCount(v) {
            this.updateDocCount(v)
        },
        reset() {
            this.skipHeadersChanges = true
            this.resetGrid()
        }
    },
    methods: {
        resetGrid(args) {
            this.$store.commit('doc/reset', { keepUnassociated: true, ...(args || {}) })
            if (!(args || {}).keepFlow) {
                this.$store.commit('line/currentFlow', { flow: null })
                this.lines = []
                this.pinnedBottomRowData = []
            }
            this.loading = false
            this.tab = null
            this.updateColumns = false

            !this.loading && this.load(args).then(() => this.updateBottom())
            this.updateColumns && this.$refs.grid.resetColumns()
            this.updateColumns = false
            // si un document est visible, on le sélectionne pour cette pièce
            if (!this.block && this.previewIsUnassociated) {
                this.$store.commit('doc/selectUnassociated', { file: this.currentDocumentPreview })
            }
        },

        load(args) {
            this.$refs.grid.options().api.sizeColumnsToFit()
            if (!this.createMode || this.$store.getters['lineedit/copy']) {
                this.$store.commit('doc/resetPopup', args)
                this.loading = true
                const block = this.block || this.$store.getters['lineedit/copy']
                return Promise
                    .resolve()
                    .then(() => {
                        return this.$store.dispatch('linegrid/popupLines', { clientId: this.clientId, block })
                    })
                    .then(lines => {
                        if (this.$store.getters['lineedit/copy']) {
                            lines.forEach(line => this.add({
                                ...line,
                                block: null,
                                flow: null
                            }, true))
                            return this.$nextTick(() => {
                                this.updateBottom()
                                this.selectFirstLine()
                            })
                        } else {
                            this.lines = lines

                            this.$store.commit('line/currentFlow', { flow: this.currentFlow })
                            return this.$store.dispatch('doc/files', {
                                clientId: this.clientId,
                                flow: this.currentFlow
                            })
                        }
                    })
                    .finally(() => (this.loading = false))
            }
            this.$store.commit('doc/reset', { keepUnassociated: true, ...(args || {}) })
            this.$store.commit('line/currentFlow', { flow: null })
            this.skipHeadersChanges = true
            return new Promise((resolve) => {
                this.lines = []
                this.pinnedBottomRowData = []
                this.add()
                resolve()
            })
        },

        save() {
            if (!this.valid || this.loading) {
                return
            }
            this.loading = true
            this.$refs.grid.options().api.showLoadingOverlay()
            const data = this.lines.filter(l => l.amount || (l.amount === 0 && l.accountCode))
            return (this.createMode ? this.saveAdd(data) : this.saveUpdate(data))
                .finally(() => {
                    this.loading = false
                    this.$refs.grid.options().api.hideOverlay()
                })
        },

        saveUpdate(lines) {
            const ls = lines.map(line => ({
                ...line,
                flowOld: this.$store.getters['line/currentFlow']
            }))
            return this.$api.put(`/clients/${this.clientId}/blocks/${this.block}`, ls)
                .then(res => {
                    // on met à jour le nb de docs s'il a changé (à cause d'un
                    // changement de flux)
                    this.lines.forEach(line => {
                        const l = res.data.find(l => l.id === line.id)
                        if (l) {
                            line.docNb = l.docNb
                            line.cas = l.cas
                        }
                    })
                    this.$refs.grid.refresh(['docNb', 'cas'])

                    // ajoute les nouvelles lignes
                    const newIds = ls.filter(l => l.cas === 'new').map(l => l.id)
                    const toAdd = res.data.filter(l => newIds.indexOf(l.id) !== -1)
                    if (toAdd.length) {
                        this.$emit('added', { flow: toAdd[0].flow, lines: toAdd })
                    }

                    this.$store.commit('linegrid/updateLines', { lines: res.data })
                    this.$store.commit('msg/show', { message: 'Pièce modifiée' })
                    this.$store.commit('line/currentFlow', { flow: this.currentFlow })
                    this.$emit('updated', Object.keys(lines[0]))
                })
                .then(() => this.resetGrid({ keepFlow: true, keepPreview: true }))
        },

        saveAdd(lines) {
            const ls = lines.map(line => {
                return line
            })
            return this.$api.post(`/clients/${this.clientId}/lines`, ls)
                .then(res => {
                    // les lignes seront ajoutées à la main grid à l'interception
                    // de cet event
                    this.$emit('added', { flow: res.data[0].flow, lines: res.data })
                })
                .then(() => {
                    this.$store.commit('msg/show', { message: 'Pièce ajoutée' })
                    // on reset le copy pour éviter que le load casse tout
                    this.$store.commit('lineedit/unsetCopy')
                    return this.load()
                })
        },

        changeValue(ev) {
            if (ev.data.cas === 'new' && !ev.oldValue && !ev.newValue) {
                // pour éviter que la ligne de total (dont les colonnes sont presque toutes vides)
                // ne perturbe les valeurs des lignes saisies
                return
            }
            const cols = ['opDate', 'flow']
            if (cols.indexOf(ev.colDef.field) === -1) {
                return
            }
            this.lines.forEach(line => {
                // si on édite la dernière ligne, on ne propage pas les changements
                // de date. Pareil si on appuie sur Enter
                if (this.lines.length <= 2 || ev.colDef.field !== 'opDate' || (!ev.node.lastChild && line[ev.colDef.field] === ev.oldValue && !ev.context.keypressEnter)) {
                    line[ev.colDef.field] = ev.newValue
                }
            })
            this.$refs.grid.refresh(cols)
        },

        removeLine(params) {
            if (this.lines.length > 1) {
                return this.lines.splice(params.node.rowIndex, 1)
            }
            const tmp = { ...this.lines[0] }
            this.lines.splice(params.node.rowIndex, 1)
            this.pinnedBottomRowData = []
            this.add(lodash.pick(tmp, ['opDate', 'block', 'flow', 'clientId']))
        },

        saveDocUploaded() {
            if (this.createMode) {
                return
            }
            this.$store.commit('linegrid/updateLines', { lines: this.lines })
            this.$emit('updated', ['docNb'])
        },

        saveDocUploadedEnd() {
            this.$refs.grid.options().api.hideOverlay()
            this.loading = false
            if (!this.createMode) {
                return
            }
            this.updateDocCount(null)
            this.$emit('saved')
        },

        updateDocCount(count) {
            if (this.createMode || !this.lines.length || this.lines[0].docNb === count) {
                return
            }
            this.lines.forEach(line => {
                line.docNb = count === null ? (line.docNb || 0) + 1 : count
            })
            this.$store.commit('linegrid/updateLines', { lines: this.lines, flow: this.lines[0].flow, flowData: lodash.pick(this.lines[0], ['docNb']) })
            this.$refs.grid.refresh(['flow', 'opDate', 'docNb'])
            this.$emit('updated', ['flow', 'opDate', 'docNb'])
            if (count === null) {
                return this.$store.dispatch('doc/files', {
                    clientId: this.clientId,
                    flow: this.currentFlow
                })
            }
        },

        updateBottom() {
            const headers = this.$store.getters['linegrid/editHeaders']
            const line = headers.reduce((acc, h) => {
                if (h.field === 'id') {
                    acc[h.field] = 'total'
                }
                if (h.field === 'amount') {
                    acc[h.field] = this.lines.reduce((sum, l) => sum + ((l.amount || 0) * 100), 0) / 100
                }
                if (['flow'].indexOf(h.field) !== -1 && this.lines.length) {
                    acc[h.field] = this.lines[0][h.field]
                }
                return acc
            }, {})
            this.pinnedBottomRowData = [line]
        },

        getRowStyle(params) {
            if (params.node.rowPinned) {
                return { 'font-weight': 'bold' }
            }
        },

        add(lineData, copyMode) {
            const data = {
                ...(lineData || { currency: this.client.currency }),
                id: uuidv4(),
                cas: 'new'
            }
            if (copyMode) {
                this.lines.push(data)
                return
            }

            // on va ajouter les lignes TVA si nécessaire
            const check = this.lines[this.lines.length - 1]
            if (check && check.vat) {
                this.addVat(check)
            } else {
                if (check) {
                    const copy = ['code1', 'code2', 'code3', 'code4', 'code5', 'code6', 'code7', 'code8', 'code9']
                    copy.forEach(c => (data[c] = check[c]))
                }
                this.lines.push(data)
            }

            this.$nextTick(() => {
                this.updateBottom()
                if (this.lines.length > 1) {
                    const copy = ['opDate', 'flow', 'block', 'docNb', 'label', 'client', 'amount']
                    const index = this.lines.length === 2 ? 0 : this.lines.length - 2
                    copy.forEach(c => {
                        data[c] = this.lines[index][c]
                    })
                    data.amount = this.pinnedBottomRowData[0].amount * -1
                    this.$refs.grid.options().api.refreshCells({
                        columns: copy,
                        rowNodes: [this.$refs.grid.options().api.getDisplayedRowAtIndex(this.lines.length - 1)]
                    })
                }
                this.selectFirstLine()
            })
        },

        addVat(check) {
            // Pour la TVA, si on a saisi +100, ça va être découpé en 3 lignes:
            // +92.85, +7.15 et -100
            // Il nous faut changer le signe de la dernière ligne ajoutée, puis
            // insérer AVANT les 2 nouvelles lignes
            check.amount *= -1

            const baseAmount = Math.round((check.amount / (1 + check.vat)) * 100) / 100
            const nextAmount = Math.round((check.amount - baseAmount) * 100) / 100

            this.lines.splice(this.lines.length - 1, 0, {
                ...check,
                id: uuidv4(),
                cas: 'new',
                amount: baseAmount * -1
                // on garde les notions de compte pour cette ligne
            }, {
                ...check,
                id: uuidv4(),
                cas: 'new',
                amount: nextAmount * -1,
                account: null,
                accountName: null,
                accountCode: null,
                accountType: null
            })
            // on supprime les notions de compte pour la ligne principale
            // (qui se retrouve à la fin), ainsi que les infos TVA
            delete check.account
            check.vatCode = null
            check.vat = null
            check.amount = this.pinnedBottomRowData[0].amount * -1
            check.accountName = null
            check.accountCode = null
            check.accountType = null
        },

        move(up, params) {
            const index = params.node.rowIndex

            if ((up && index === 0) || (!up && index === this.lines.length - 1)) {
                return
            }
            const newIndex = up ? index - 1 : index + 1
            this.lines.splice(newIndex, 0, this.lines.splice(index, 1)[0])

            this.$nextTick(() => {
                const api = this.$refs.grid.options().api
                const node = api.getRowNode(params.data.id)
                api.ensureNodeVisible(node, null)
                this.$nextTick(() => {
                    node.setSelected(true, true)
                    api.setFocusedCell(newIndex, params.colDef.colId)
                })
            })
        },

        selectFirstLine() {
            this.$nextTick(() => {
                this.updateBottom()
                if (!this.lines.length) {
                    return
                }
                this.$refs.grid.options().api.ensureColumnVisible('opDate')
                this.$nextTick(() => {
                    this.$refs.grid.options().api.startEditingCell({
                        rowIndex: this.lines.length - 1,
                        colKey: this.lines.length === 1 ? 'opDate' : 'opDate'
                    })
                })
            })
        },

        addBounced: lodash.debounce(function() {
            this.add()
        }, 200),

        gridReady() {
            this.$refs.grid.options().context.attach = this.$refs.dialog
        }
    }
}
</script>

<style lang="sass">
.kus-line-edit

    .kus-line-edit-content
        overflow-y: auto

    .hideSlider .v-slide-group
        display: none !important

</style>
