<template>
    <v-container>
        <v-row>
            <v-col>
                <kus-user-account />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import KusUserAccount from '@/modules/user/KusUserAccount'
export default {
    name: 'kus-user-account-vue',
    components: { KusUserAccount }
}
</script>
