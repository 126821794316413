<template>
    <v-app-bar app dense flat :class="headerCls">
        <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.smAndDown"
            @click.stop="menu = !menu" />

        <v-toolbar-title class="headline">
            <component :is="$route.meta.titleCmp || 'kus-base-topbar-title'" />
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text icon
            @click="help"
            id="kushelpbtn"
            target="_blank">
            <v-icon>$vuetify.icons.baseHelp</v-icon>
        </v-btn>
        <v-btn icon text @click.stop="drawerRight = !drawerRight">
            <v-icon>$vuetify.icons.userMenu</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script>
import KusBaseTopbarTitle from '@/modules/base/KusBaseTopbarTitle'

export default {
    name: 'kus-base-topbar',
    components: { KusBaseTopbarTitle },
    props: {
        value: { type: Boolean },
        dr: { type: Boolean }
    },
    computed: {
        menu: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        drawerRight: {
            get() {
                return this.dr
            },
            set(v) {
                this.$emit('dr', v)
            }
        },

        headerCls() {
            return this.$store.getters['cls/header']
        }
    },
    methods: {
        help() {
            this.$store.commit('line/helper', { show: true })
        }
    }
}
</script>

<style lang="sass">
.kus-dragging
    margin-top: 3px !important

</style>
