<template>
    <v-list two-line dense>
        <kus-document-flow-list-item
            v-for="file in files"
            :key="file.id"
            :flow="currentFlow"
            :file="file"
            @remove="remove"
            @activate="file => selected = file.id"
            :active="selected === file.id"
            inside
            :selectable="selectable" />

        <p v-if="!files.length" class="px-4 py-2"><em>{{ $t('document.anonymous.empty') }}</em></p>
    </v-list>
</template>

<script>
import KusDocumentFlowListItem from '@/modules/document/KusDocumentFlowListItem'

export default {
    name: 'kus-document-unassociated-list',
    components: { KusDocumentFlowListItem },
    props: {
        selectable: { type: Boolean }
    },
    data: () => ({
        selected: null
    }),
    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        },
        files() {
            return this.$store.getters['doc/filesUnassociated']
        },
        currentFlow() {
            return this.$store.getters['line/currentFlow']
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.$emit('load')
            return this.$store.dispatch('doc/filesUnassociated', { clientId: this.clientId })
                .then(() => {
                    this.$emit('loaded')
                })
        },
        remove(file) {
            this.$store.commit('doc/removePopup', {
                document: file,
                type: 'unassoc'
            })
        }
    }
}
</script>
