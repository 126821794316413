<template>
    <div @keyup="onKeyUp">
        <v-layout>
            <v-flex>
                <v-text-field
                    ref="field"
                    type="number"
                    v-model="from"
                    :label="label"
                    :hint="hint"
                    :persistent-hint="!!hint"
                    :rules="[required]" />
            </v-flex>
            <v-flex xs2 shrink>
                <v-select :items="items" v-model="select" />
            </v-flex>
        </v-layout>

        <v-text-field
            v-if="showTo"
            v-model="to"
            type="number"
            label="jusqu'à (inclu)"
            :rules="showTo ? [required] : []" />
    </div>
</template>

<script>
import KusBaseRules from '@/modules/base/KusBaseRules'

export default {
    name: 'kus-line-search-number',
    props: {
        value: { type: String },
        label: { type: String },
        hint: { type: String }
    },
    data: () => ({
        from: null,
        to: null,
        showTo: false,
        select: '=',
        items: ['<=', '=', '>=', '<>'],
        required: KusBaseRules.required
    }),
    watch: {
        select() {
            this.onSelect()
            this.submit()
        },
        from: 'submit',
        to: 'submit'
    },
    methods: {
        onSelect() {
            if (this.select === '<>') {
                this.showTo = true
            } else {
                this.to = null
                this.showTo = false
            }
        },

        onKeyUp(event) {
            if (event.key === 'Enter') {
                this.$emit('submit')
            }
        },

        focus() {
            this.$nextTick(() => this.$refs.field.focus())
        },

        submit() {
            const value = this.from + this.select + (this.showTo ? this.to : '')
            this.$emit('input', value)
        }
    }
}
</script>
