export default {
    methods: {
        mixinSelectFocus() {
            let select = true
            if (this.params.charPress !== null) {
                this.value = this.params.charPress
                select = false
            }
            this.$nextTick(() => {
                this.mixinFocus()
                select && this.$refs.field && this.$nextTick(() => this.$refs.field.$refs.input.select())
            })
        },

        mixinSelectFocusNumber() {
            let select = true
            const char = Number(this.params.charPress)
            if (this.params.charPress !== null && !isNaN(char)) {
                this.value = char
                select = false
            }
            if (this.params.charPress === '-') {
                const has = String(this.value).indexOf('-') !== -1
                this.value = (has ? '' : '-') + (String(this.value || '')).replace('-', '')
                select = false
            }
            if (['.', ','].indexOf(this.params.charPress) !== -1) {
                this.value = '0.'
                select = false
            }
            this.$nextTick(() => {
                this.$refs.field.focus()
                select && this.$refs.field.$refs.input.select()
            })
        },

        mixinFocus() {
            this.$refs.field && this.$nextTick(() => this.$refs.field.focus())
        }
    }
}
