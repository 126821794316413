<template>
    <v-select
        v-model="selection"
        :items="roles"
        :disabled="fullDisabled"
        :readonly="disabled || fullDisabled"
        small-chips
        :placeholder="$t('clients.access.fullRolesDoc')"
        multiple>
        <template #prepend>
            <v-icon
                class="mr-1 mt-1"
                :disabled="fullDisabled"
                @click="disabledValue = !disabledValue"
                :color="disabled ? 'red' : ''"
                small>$vuetify.icons.clientAccessDisable</v-icon>
        </template>
    </v-select>
</template>

<script>
export default {
    name: 'kus-client-access-roles-doc',
    props: {
        value: { type: Array, default: () => [] },
        disabled: { type: Boolean },
        fullDisabled: { type: Boolean }
    },
    computed: {
        selection: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        disabledValue: {
            get() {
                return this.disabled
            },
            set(v) {
                this.$emit('disabled', v)
            }
        },

        roles() {
            return this.$store.getters['caccess/rolesDoc'].map(role => ({
                value: role,
                text: this.$i18n.t(`clients.access.rolesDoc.${role}`)
            }))
        }
    }
}
</script>
