<template>
    <v-app :class="appCls">
        <v-navigation-drawer app fixed class="kus-main-drawer"
            :mini-variant="mini"
            v-model="drawer"
            v-if="connected">
            <kus-base-menu v-model="mini" />
            <v-spacer />
        </v-navigation-drawer>
        <v-navigation-drawer app temporary fixed right v-model="drawerRight">
            <kus-base-menu-alt v-model="drawerRight" />
        </v-navigation-drawer>
        <kus-base-topbar v-if="connected" v-model="drawer" :dr="drawerRight" @dr="v => drawerRight = v" />
        <kus-onboarding-tour name="kusTour" />
        <kus-base-message />
        <kus-base-error />
        <kus-line-helper />
        <v-main :class="mainCls">
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import Vue from 'vue'
import KusBaseTopbar from '@/modules/base/KusBaseTopbar'
import KusBaseMenu from '@/modules/base/KusBaseMenu'
import KusBaseMessage from '@/modules/base/KusBaseMessage'
import KusBaseError from '@/modules/base/KusBaseError'
import KusLineHelper from '@/modules/line/KusLineHelper'
import KusBaseMenuAlt from '@/modules/base/KusBaseMenuAlt'
import KusOnboardingTour from '@/modules/onboarding/KusOnboardingTour'

export default {
    name: 'App',
    components: {
        KusBaseTopbar,
        KusBaseMenu,
        KusBaseMessage,
        KusLineHelper,
        KusBaseError,
        KusBaseMenuAlt,
        KusOnboardingTour
    },
    data: () => ({
        drawer: true,
        drawerRight: false,
        mini: true
    }),
    computed: {
        connected() {
            if (this.$route.meta.hideConnected) {
                return false
            }
            return this.$store.getters['auth/connected']
        },

        user() {
            return this.$store.getters['auth/user']
        },

        cid() {
            return this.user && this.user.id
        },

        appCls() {
            return this.$store.getters['cls/app']
        },

        mainCls() {
            return this.$store.getters['cls/main']
        }
    },
    watch: {
        cid(v) {
            if (v) {
                this.startTour()
            }
        }
    },
    created() {
        const errInterceptor = (err) => {
            const res = (err.response && err.response.data) || {}
            const msg = res.message || (err && err.message)
            if (res.detail) {
                this.$store.commit('error/show', { message: msg, detail: res.detail })
            } else {
                this.$store.commit('msg/show', { message: msg, type: 'error' })
            }
            throw err
        }
        Vue.prototype.$api.interceptors.response.use(undefined, errInterceptor)
    },
    mounted() {
        this.startTour()
    },
    methods: {
        startTour() {
            // #KUS-134 : on désactive le tour pour le moment, faute de contenus
            // window.setTimeout(() => this.$tours.kusTour.start(), 1000)
        }
    }
}
</script>

<style lang="sass">
@font-face
    font-family: 'EB Garamond'
    font-style: normal
    font-weight: 400
    src: local('EB Garamond Regular'), local('EBGaramond-Regular'), url('/fonts/eb-garamond-v9-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/eb-garamond-v9-latin-regular.woff') format('woff') /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

html, body
    overflow-y: auto

.kus-main-drawer
    display: flex !important
    flex-direction: column !important

.kus-nav-3
    display: flex
    flex-direction: column

    > .v-card__title
        display: flex
        flex: 1 1 auto

    > .v-card__text
        max-height: 300px
        overflow-y: auto

    &.pr-0 > .v-card__text
        padding-right: 0

.kus-btn-center
    display: block
    margin: 0 auto

.kus-no-transition .v-sheet
    transition: all 0s ease 0s !important

.v-list-item__content.align-end
    align-items: flex-end !important

.v-tab
    min-width: 60px !important

.v-main.kus-scrollable .v-main__wrap
    height: calc(100vh - 48px)
    overflow-y: auto
</style>
