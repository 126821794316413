import Vue from 'vue'
import Vuex from 'vuex'

import KusBaseAuthStore from '@/modules/base/KusBaseAuthStore'
import KusBaseMessageStore from '@/modules/base/KusBaseMessageStore'
import KusBaseLnStore from '@/modules/base/KusBaseLnStore'
import KusBaseErrorStore from '@/modules/base/KusBaseErrorStore'
import KusBaseClassStore from '@/modules/base/KusBaseClassStore'
import KusBaseLoadStore from '@/modules/base/KusBaseLoadStore'

import KusDemoStore from '@/modules/demo/KusDemoStore'
import KusContentStore from '@/modules/content/KusContentStore'

import KusUserPreferenceStore from '@/modules/user/KusUserPreferenceStore'
import KusUserRoleStore from '@/modules/user/KusUserRoleStore'
import KusUserStore from '@/modules/user/KusUserStore'

import KusLineGridStore from '@/modules/line/KusLineGridStore'
import KusLineGridSelectStore from '@/modules/line/KusLineGridSelectStore'
import KusLineEditStore from '@/modules/line/KusLineEditStore'
import KusLineStore from '@/modules/line/KusLineStore'

import KusBalanceStore from '@/modules/balance/KusBalanceStore'
import KusResultStore from '@/modules/result/KusResultStore'
import KusVatStore from '@/modules/vat/KusVatStore'

import KusDocumentStore from '@/modules/document/KusDocumentStore'
import KusDocumentPopupStore from '@/modules/document/KusDocumentPopupStore'

import KusClientStore from '@/modules/client/KusClientStore'
import KusClientAccessStore from '@/modules/client/access/KusClientAccessStore'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        ln: KusBaseLnStore,
        auth: KusBaseAuthStore,
        msg: KusBaseMessageStore,
        cls: KusBaseClassStore,
        client: KusClientStore,
        caccess: KusClientAccessStore,
        pref: KusUserPreferenceStore,
        usr: KusUserStore,
        role: KusUserRoleStore,
        linegrid: KusLineGridStore,
        lineedit: KusLineEditStore,
        linegridsel: KusLineGridSelectStore,
        line: KusLineStore,
        load: KusBaseLoadStore,
        bal: KusBalanceStore,
        res: KusResultStore,
        vat: KusVatStore,
        error: KusBaseErrorStore,
        doc: KusDocumentStore,
        docpop: KusDocumentPopupStore,
        demo: KusDemoStore,
        content: KusContentStore
    }
})
