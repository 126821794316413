<template>
    <v-layout ref="layout" v-resize="containerWidth">
        <v-flex shrink class="kus-line-area--left kus-no-transition" v-show="showDocuments">
            <kus-line-document :style="{ width: `${width}px` }" ref="documents" />
        </v-flex>
        <kus-base-layout-slider
            v-show="showDocuments"
            horizontal
            v-model="width"
            @resized="savePanelWidth"
            :disabled="!showDocuments" />

        <v-flex ref="container" :style="{ width: `${widthMain - width}px` }" class="kus-no-transition">
            <v-layout column>
                <v-flex>
                    <kus-line-main-view ref="main" :height="height">
                        <template #toptoolbar>
                            <v-tooltip bottom v-if="!showDocuments">
                                <template #activator="{ on }">
                                    <v-btn id="kusgriddocshow" small icon dark text v-on="on" @click="showDocuments = true" class="mr-3">
                                        <v-icon small>$vuetify.icons.docShow</v-icon>
                                    </v-btn>
                                </template>
                                {{ $t('lines.documents.show') }}
                            </v-tooltip>
                            <v-tooltip bottom v-else>
                                <template #activator="{ on }">
                                    <v-btn id="kusgriddocshow" small icon dark text v-on="on" @click="showDocuments = false" class="mr-3">
                                        <v-icon small>$vuetify.icons.baseLeft</v-icon>
                                    </v-btn>
                                </template>
                                {{ $t('lines.documents.hide') }}
                            </v-tooltip>
                            <span class="white--text kus-separator mr-3">|</span>
                        </template>
                    </kus-line-main-view>
                    <kus-line-grid-header-edit-popup type="main" />
                </v-flex>
                <kus-base-layout-slider
                    v-show="lineEditShow"
                    vertical
                    v-model="height"
                    :disabled="!lineEditShow" />
                <v-flex>
                    <kus-line-edit-view
                        hide-tabs
                        :height="height"
                        @saved="() => $refs.main.goToBottom()"
                        @added="uploadAfterAdd"
                        @updated="fields => $refs.main.refresh(fields)" />
                </v-flex>
            </v-layout>
        </v-flex>
        <kus-document-snack />
        <kus-document-preview />
    </v-layout>
</template>

<script>
import KusLineEditView from '@/modules/line/KusLineEditView'
import KusLineMainView from '@/modules/line/KusLineMainView'
import KusLineGridHeaderEditPopup from '@/modules/line/gridHeaderEdit/KusLineGridHeaderEditPopup'
import KusDocumentSnack from '@/modules/document/KusDocumentSnack'
import KusDocumentPreview from '@/modules/document/KusDocumentPreview'
import KusLineDocument from '@/modules/line/KusLineDocument'
import KusBaseLayoutSlider from '@/modules/base/KusBaseLayoutSlider'

export default {
    name: 'kus-line-home',
    components: {
        KusLineMainView,
        KusLineEditView,
        KusLineGridHeaderEditPopup,
        KusLineDocument,
        KusDocumentSnack,
        KusDocumentPreview,
        KusBaseLayoutSlider
    },

    data: () => ({
        width: 300,
        widthMain: 0
    }),

    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        },

        lineEditShow() {
            return this.$store.getters['lineedit/show']
        },

        showDocuments: {
            get() {
                return this.$store.getters['pref/getClientPref'](this.clientId, 'docpnl', true)
            },
            set(v) {
                this.$store.dispatch('pref/setClientPref', { clientId: this.clientId, key: 'docpnl', value: v })
            }
        },

        height: {
            get() {
                return this.$store.getters['lineedit/height']
            },
            set(v) {
                this.$store.commit('lineedit/height', { height: v })
            }
        }
    },

    watch: {
        lineEditShow: 'toggleStatusBar',
        showDocuments: 'toggleShowDocuments'
    },

    created() {
        this.$store.commit('linegrid/headers', { headers: [] })
        this.$store.commit('linegrid/lines', { lines: [] })
        this.$store.commit('lineedit/show', { show: false })
    },

    mounted() {
        // on va réinitialiser les stores grid et documents
        this.$store.commit('linegridsel/reset')
        this.$store.commit('line/reset')
        this.$store.commit('doc/reset')

        // normalement, une combinaison de flex devrait éviter de gérer cette
        // largeur "en dur". Mais pas moyen de trouver comment...
        this.widthMain = this.$refs.container.offsetWidth
        return this.loadPanelWidth()
    },

    methods: {

        toggleShowDocuments(v) {
            if (!v) {
                this.$nextTick(() => this.$refs.main.adjustColumns())
            }
        },

        toggleStatusBar(show) {
            this.$refs.main.toggleStatusBar(!show)
        },

        loadPanelWidth() {
            return this.$api.get(`/clients/${this.clientId}/preferences/document-panel-width`)
                .then(res => (this.width = res.data.width || 300))
                .catch(err => {
                    // si on est sur du 403, on retourne à l'accueil
                    if (err.response && err.response.status === 403) {
                        return this.$router.replace({ name: 'home' })
                    }
                    throw err
                })
        },

        savePanelWidth() {
            if (!this.clientId) {
                // le resize est appelé lors du logout, car il y a une animation
                // pour revenir à l'écran de login. A ce moment, le client
                // n'existe plus
                return
            }
            return this.$api.put(`/clients/${this.clientId}/preferences/document-panel-width`, {
                width: this.width
            })
        },

        containerWidth() {
            const diff = this.$refs.layout.offsetWidth - window.innerWidth
            if (diff < 0) {
                this.widthMain += diff
            }
        },

        async uploadAfterAdd(data) {
            let lines = data.lines

            // on ajoute les documents sélectionnés automatiquement que si le panel est
            // ouvert (KUS-153)
            if (this.showDocuments) {
                const files = await this.$refs.documents.upload(data.flow).catch(() => {
                    // si l'upload n'a pas fonctionné, on affiche quand même
                    // les lignes créées
                    return []
                })
                // une fois tout ok, on va sélectionner automatiquement le
                // document suivant, s'il y en a un, et si le panneau
                // des documents est visible
                const file = this.showDocuments && this.$refs.documents.selectNext()
                if (!file) {
                    // il n'y a plus aucun fichier non-classé, on va
                    // fermer le document en cours de preview
                    this.$refs.documents.endPreview()
                }

                // on met à jour le nb de docs ajoutés dans les lignes avant
                // de l'intégrer dans la main grid
                lines = data.lines.map(line => {
                    if (!line.isTech) {
                        line.docNb += files.length
                    }
                    return line
                })
            }

            this.$store.commit('linegrid/addLines', { lines })
            return this.$nextTick(() => this.$refs.main.goToBottom())
        }
    }
}
</script>

<style lang="sass">
.v-menu__content
    &.maxZindex
        z-index: 900 !important

.kus-line-area--left
    max-height: calc(100vh - 48px)
    overflow-y: auto

.kus-separator
    font-weight: normal
    font-size: 12px

</style>
