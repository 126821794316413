<template>
    <v-card flat>
        <kus-card-title v-if="!hideTitle"
            :title="$t('document.anonymous.title')"
            :badge="filesUnassociated.length"
            badgeColor="secondary"
            :dismissible="false">
            <template #buttons>
                <kus-document-upload-btn small :loading="loading" :tooltip="$t('document.upload.tooltip')" />
            </template>
        </kus-card-title>
        <v-card-text>
            <kus-document-unassociated-list
                ref="list"
                @load="loading = true"
                @loaded="loading = false"
                :selectable="selectable" />

            <kus-document-remove-popup />
        </v-card-text>
    </v-card>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusDocumentUploadBtn from '@/modules/document/KusDocumentUploadBtn'
import KusDocumentUnassociatedList from '@/modules/document/KusDocumentUnassociatedList'
import KusDocumentRemovePopup from '@/modules/document/KusDocumentRemovePopup'

export default {
    name: 'kus-document-unassociated',
    components: {
        KusCardTitle,
        KusDocumentUploadBtn,
        KusDocumentUnassociatedList,
        KusDocumentRemovePopup
    },
    props: {
        hideTitle: { type: Boolean },
        selectable: { type: Boolean }
    },
    data: () => ({
        loading: false
    }),
    computed: {
        filesUnassociated() {
            return this.$store.getters['doc/filesUnassociated']
        }
    },
    methods: {
        remove(file) {
            this.$store.commit('doc/removePopup', {
                document: file,
                type: 'anonymous'
            })
        }
    }
}
</script>
