<template>
    <div class="d-flex justify-center">
        <v-card class="ma-2" max-width="400">
            <kus-card-title :title="$t('clients.access.validate.header')" :dismissible="false" />
            <v-card-text>
                <h4>{{ $tu('clients.access.validate.title', { client: data.client }) }}</h4>
                <br />
                <p v-marked="$t('clients.access.validate.content', { sender: data.sender, senderEmail: data.senderEmail })"></p>
            </v-card-text>
            <v-card-actions>
                <v-btn block text
                    color="primary"
                    @click="validate"
                    :disabled="loading"
                    :loading="loading">
                    {{ $t('clients.access.validate.btn') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'

export default {
    name: 'kus-client-access-vue',
    components: { KusCardTitle },
    props: {
        token: { type: String }
    },

    data: () => ({
        loading: false,
        data: {}
    }),

    mounted() {
        this.load()
    },

    methods: {
        load() {
            return this.$store.dispatch('caccess/get', { token: this.token })
                .then(data => (this.data = data))
        },

        validate() {
            this.loading = true
            return this.$store.dispatch('caccess/accept', { token: this.token })
                .then(() => {
                    this.$store.commit('msg/show', { message: this.$i18n.t('clients.access.validated') })
                    this.$router.replace({ name: 'home' })
                })
                .finally(() => (this.loading = false))
        }
    }
}
</script>
