import KusVat from '@/modules/vat/KusVat'
import KusLineTopbar from '@/modules/line/KusLineTopbar'

export default [
    {
        path: '/vat',
        name: 'vat',
        component: KusVat,
        meta: {
            titleCmp: KusLineTopbar
        }
    }
]
