import Vue from 'vue'
import store from './store'
import Axios from 'axios'
import AxiosFinally from 'promise.prototype.finally'
import AxiosRetry from 'axios-retry'
import lodash from 'lodash'

Axios.defaults.withCredentials = true
Axios.defaults.headers = {
    'X-Requested-With': 'XMLHttpRequest'
}
const authInterceptor = function(req) {
    const token = store.getters['auth/tokenHeader']
    if (token) {
        req.headers['Kus-Api-Token'] = token
    }
    req.headers['Kus-Language'] = store.getters['ln/current']
    return req
}
// pour gérer le .finally
AxiosFinally.shim()

Vue.prototype.$http = Axios

Vue.prototype.$api = Axios.create({
    baseURL: '' // sera configuré lors du boot
})
Vue.prototype.$api.interceptors.request.use(authInterceptor)
Vue.prototype.$api.interceptors.response.use(
    res => {
        store.commit('load/retry', false)
        return res
    }
)

AxiosRetry(Vue.prototype.$api, {
    retryCondition: err => AxiosRetry.isNetworkError(err) || lodash.get(err, 'response.data.retryable'),
    retryDelay: retryCount => {
        store.commit('load/retry', retryCount > 1 && retryCount < 7)
        return AxiosRetry.exponentialDelay(retryCount) + (1 * 1000)
    },
    retries: 7
})

export default Axios
