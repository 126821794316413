<template>
    <kus-base-upload-btn
        class="kus-document-upload--btn"
        :color="small ? 'white' : 'primary'"
        :small="small"
        :accept="['application/vnd.ms-excel', 'text/csv', 'application/csv']"
        :block="block"
        auto-start
        :url="url"
        :axios="$api"
        :loading="loading"
        :multiple="false"
        :tooltip="tooltip"
        :params="params"
        icon="$vuetify.icons.lineImport"
        @upload:start="upload"
        @upload:progress="uploading"
        @upload:success="uploaded"
        @upload:error="error"
        @upload:end="end"
        @upload:finish="finish"
        ref="upload">
        <template #content v-if="!small">
            {{ $t('document.upload.select') }}
        </template>
    </kus-base-upload-btn>
</template>

<script>
import KusBaseUploadBtn from '@/modules/base/KusBaseUploadBtn'

export default {
    name: 'kus-line-upload-btn',
    props: {
        small: { type: Boolean },
        block: { type: Boolean },
        loading: { type: Boolean },
        tooltip: { type: String },
        params: { type: Object, default: () => ({}) }
    },
    components: { KusBaseUploadBtn },
    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        },

        url() {
            return `/clients/${this.clientId}/lines/import`
        }
    },
    methods: {
        reset() {
            this.$refs.upload.reset()
        },

        upload(file) {
            this.$emit('upload:start', file)
        },

        uploading(file) {
            this.$emit('upload:progress', file)
        },

        uploaded(file) {
            this.$emit('upload:success', file)
        },

        error(file) {
            this.$emit('upload:error', file)
        },

        end(ev) {
            this.$emit('upload:end', ev)
        },

        finish() {
            this.reset()
        }
    }
}
</script>
