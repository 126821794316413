<template>
    <v-menu
        :close-on-content-click="false"
        v-model="menu"
        transition="scale-transition"
        offset-y
        min-width="290px">
        <template #activator="{ on }">
            <v-text-field
                v-on="on"
                ref="field"
                :value="dateFormatted"
                :label="label"
                :placeholder="placeholder"
                :rules="rules"
                :flat="flat"
                :single-line="singleLine"
                :solo="solo"
                :hide-details="hideDetails"
                :disabled="disabled"
                :clearable="clearable"
                :class="inputClass"
                @click:clear="ev => $emit('click:clear', ev)"
                readonly>
            </v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            :allowed-dates="allowedDates"
            range
            no-title />
    </v-menu>
</template>

<script>
export default {
    name: 'kus-base-date-range-field',
    props: {
        value: { type: Array, default: () => [] },
        label: { type: String },
        placeholder: { type: String },
        text: { type: String },
        rules: { type: Array, default: () => [] },
        flat: { type: Boolean },
        solo: { type: Boolean },
        singleLine: { type: Boolean },
        hideDetails: { type: Boolean },
        inputClass: { type: [String, Object] },
        allowedDates: {},
        disabled: { type: Boolean },
        clearable: { type: Boolean }
    },
    data: () => ({
        menu: false
    }),
    computed: {
        date: {
            get() {
                return this.value
            },
            set(v) {
                if (v.length === 2) {
                    this.menu = false
                }
                this.$emit('input', v)
            }
        },
        dateFormatted() {
            if (this.text) {
                return this.text
            }
            if (this.date.length < 2) {
                return this.$i18n.t('clients.access.date')
            }
            return this.$i18n.t('clients.access.range', { from: this.date[0], to: this.date[1] })
        }
    }
}
</script>
