import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        clients: [],
        client: {}
    },

    getters: {
        list: state => state.clients,
        current: state => state.client
    },

    mutations: {
        list(state, data) {
            state.clients = data.clients
        },

        upsert(state, data) {
            const index = state.clients.findIndex(u => u.id === data.client.id)
            if (index !== -1) {
                state.clients.splice(index, 1, { ...data.client })
            } else {
                state.clients.unshift({ ...data.client })
            }
        },

        remove(state, data) {
            const index = state.clients.findIndex(u => u.id === data.client.id)
            if (index !== -1) {
                state.clients.splice(index, 1)
            }
        },

        current(state, data) {
            state.client = data.client ? { ...data.client } : {}
        }
    },

    actions: {
        list(context, data) {
            const query = `start=${data.start || 0}&limit=${data.limit || 100}&q=${data.q || ''}`
            return Vue.prototype.$api.get(`/clients?${query}`)
                .then(res => {
                    context.commit('list', { clients: res.data })
                    return context.state.clients
                })
        },

        get(context, data) {
            return Vue.prototype.$api.get(`/clients/${data.id}`)
                .then(res => {
                    context.commit('current', { client: res.data })
                    return context.state.client
                })
        },

        upsert(context, data) {
            const promise = data.client.id
                ? Vue.prototype.$api.put(`/clients/${data.client.id}`, data.client)
                : Vue.prototype.$api.post('/clients', { id: 'new', ...data.client })

            return promise.then(res => {
                context.commit('current', { client: res.data })
                context.commit('upsert', { client: res.data })
                return context.state.client
            })
        },

        remove(context, data) {
            return Vue.prototype.$api.delete(`/clients/${data.client.id}`)
                .then(res => {
                    context.commit('current', { client: null })
                    context.commit('remove', { client: data.client })
                    return res.data
                })
        }
    }
}
