<template>
    <div @keyup="onKeyUp">
        <v-layout>
            <v-flex>
                <kus-base-date-field
                    ref="field"
                    v-model="from"
                    :label="label"
                    :hint="hint"
                    :persistent-hint="!!hint"
                    :rules="[required]" />
            </v-flex>
            <v-flex xs2 shrink>
                <v-select :items="items" v-model="select" />
            </v-flex>
        </v-layout>

        <kus-base-date-field
            v-if="showTo"
            v-model="to"
            label="jusqu'à (inclu)"
            :rules="showTo ? [required] : []" />
    </div>
</template>

<script>
import KusBaseDateField from '@/modules/base/KusBaseDateField'
import KusBaseRules from '@/modules/base/KusBaseRules'

// eslint-disable-next-line
const regexp = new RegExp(/^([0-9\-]{10})([<>=]{1,2})([0-9\-]{10})?$/)

export default {
    name: 'kus-line-search-date',
    components: { KusBaseDateField },
    props: {
        value: { type: String },
        label: { type: String },
        hint: { type: String }
    },
    data: () => ({
        from: null,
        to: null,
        showTo: false,
        select: '=',
        items: ['<', '<=', '=', '>=', '>', '<>'],
        required: KusBaseRules.required
    }),
    watch: {
        select() {
            this.onSelect()
            this.submit()
        },
        from: 'submit',
        to: 'submit',
        value: 'checkValue'
    },
    mounted() {
        this.checkValue(this.value)
    },
    methods: {
        checkValue(v) {
            if (!this.from && v && regexp.test(v)) {
                const matches = regexp.exec(v)
                this.select = matches[2]
                this.from = matches[1]
                this.to = matches[3]
            }
        },

        onSelect() {
            if (this.select === '<>') {
                this.showTo = true
            } else {
                this.to = null
                this.showTo = false
            }
        },

        onKeyUp(event) {
            if (event.key === 'Enter') {
                this.$emit('submit')
            }
        },

        focus() {
            this.$nextTick(() => this.$refs.field.focus())
        },

        submit() {
            const value = this.from + this.select + (this.showTo ? this.to : '')
            this.$emit('input', value)
        }
    }
}
</script>
