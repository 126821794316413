import Vue from 'vue'

export default Vue.extend({

    template: `
        <div class="ag-name-value" v-if="visible">
            <span>{{ $t('lines.statusBar.debit') }}&nbsp;:</span>
            <span class="ag-name-value-value">{{ debit | number }}</span>
            <span>&nbsp;{{ $t('lines.statusBar.credit') }}&nbsp;:</span>
            <span class="ag-name-value-value">{{ credit | number }}</span>
            <span>&nbsp;{{ $t('lines.statusBar.balance') }}&nbsp;:</span>
            <span class="ag-name-value-value" :class="balanceColor">{{ balance | number }}</span>
        </div>
    `,

    name: 'kus-line-grid-status-bar',

    data() {
        return {
            credit: 0,
            debit: 0,
            balance: 0,
            visible: true,
            sumsMethod: this.sums
        }
    },

    computed: {
        balanceColor() {
            if (this.balance > 0) {
                return 'green--text'
            }
            if (this.balance < 0) {
                return 'red--text'
            }
            return ''
        }
    },

    mounted() {
        this.params.api.addEventListener('modelUpdated', this.sumsMethod)
    },

    beforeDestroy() {
        this.params.api.removeEventListener('modelUpdated', this.sumsMethod)
    },

    methods: {
        sums(params) {
            let credit = 0
            let debit = 0

            this.params.api.forEachLeafNode(node => {
                const amount = Math.abs(node.data.amount * 100)
                if (node.data.amount < 0) {
                    credit += amount
                } else {
                    debit += amount
                }
            })
            this.balance = (debit - credit) / 100
            this.credit = credit / 100
            this.debit = debit / 100
        },

        setVisible(visible) {
            this.visible = visible
        },

        isVisible() {
            return this.visible
        }
    }
})
