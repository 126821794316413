import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import KusLineRouter from '@/modules/line/router'
import KusHomeRouter from '@/modules/home/router'
import KusUserRouter from '@/modules/user/router'
import KusContentRouter from '@/modules/content/router'
import KusClientRouter from '@/modules/client/router'
import KusBalanceRouter from '@/modules/balance/router'
import KusResultRouter from '@/modules/result/router'
import KusVatRouter from '@/modules/vat/router'
import KusDemoRouter from '@/modules/demo/router'
import KusBaseRouter from '@/modules/base/router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: []
        .concat(KusLineRouter)
        .concat(KusHomeRouter)
        .concat(KusUserRouter)
        .concat(KusContentRouter)
        .concat(KusClientRouter)
        .concat(KusBalanceRouter)
        .concat(KusResultRouter)
        .concat(KusVatRouter)
        .concat(KusDemoRouter)
        .concat(KusBaseRouter)
})

router.beforeEach((to, from, next) => {
    const connected = store.getters['auth/connected']
    const client = store.getters['auth/client']

    if (!connected && !to.meta.public) {
        return next({ path: `/?p=${to.path}` })
    }
    if (connected && to.meta.public && to.meta.private !== true) {
        if (client) {
            return next({ path: '/line' })
        }
        return next({ path: '/home' })
    }
    return next()
})

export default router
