<template>
    <div class="kus-line-search--container">
        <v-text-field
            v-if="!advanced"
            v-model="search"
            :label="$t('lines.search.button')"
            class="kus-line-search--button pt-0 mt-0"
            ref="field"
            flat
            autocomplete="off"
            single-line
            solo-inverted
            hide-details
            clearable
            @input="change">
            <template #prepend-inner>
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-icon
                            v-on="on"
                            @click="advanced = true">$vuetify.icons.baseSearch</v-icon>
                    </template>
                    {{ $t('lines.search.advanced') }}
                </v-tooltip>
            </template>
        </v-text-field>

        <v-autocomplete
            v-if="advanced"
            v-model="select"
            :items="filters"
            :label="$t('lines.search.buttonAdvanced')"
            :menu-props="{ 'closeOnClick': false, 'closeOnContentClick': true }"
            :filter="filter"
            class="kus-line-search--button pt-0 mt-0"
            ref="field"
            item-text="text"
            item-value="field"
            flat
            autocomplete="off"
            auto-select-first
            single-line
            solo-inverted
            hide-details
            hide-selected
            multiple
            clearable
            small-chips
            chips
            return-object
            @input="change(true)">
            <template #prepend-inner>
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-icon
                            v-on="on"
                            color="primary"
                            @click.stop="advanced = false">$vuetify.icons.lineSearchSimple</v-icon>
                    </template>
                    {{ $t('lines.search.simple') }}
                </v-tooltip>
            </template>
            <template #item="data">
                <span :class="`kus-line-search--${data.item.field}`">{{ data.item.text }}</span>
            </template>
            <template #selection="data">
                <v-chip
                    small
                    label
                    close
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    @click.prevent.stop="editChip(data.item)"
                    @click:close="data.parent.selectItem(data.item)">
                    {{ data.item.field }}: {{ selectValues[data.item.field] }}
                </v-chip>
            </template>
        </v-autocomplete>

        <v-dialog v-model="edit" max-width="350" hide-overlay>
            <v-card>
                <v-form ref="form" v-model="editForm" lazy-validation @submit.stop.prevent="editSubmit">
                    <kus-card-title :title="editType.text" @close="edit = false" />
                    <v-card-text>
                        <component
                            ref="cmpField"
                            :is="editType.search"
                            :label="editType.text"
                            :hint="editType.hint"
                            v-model="selectValues[editType.field]"
                            @submit="editSubmit" />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn small icon color="primary" @click="editSubmit" :disabled="!editForm">
                            <v-icon small>$vuetify.icons.baseSave</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusLineSearchVatCode from '@/modules/line/search/KusLineSearchVatCode'
import KusLineSearchText from '@/modules/line/search/KusLineSearchText'
import KusLineSearchDate from '@/modules/line/search/KusLineSearchDate'
import KusLineSearchBoolean from '@/modules/line/search/KusLineSearchBoolean'
import KusLineSearchNumber from '@/modules/line/search/KusLineSearchNumber'
import KusLineSearchPayStatus from '@/modules/line/search/KusLineSearchPayStatus'
import KusLineSearchAccountType from '@/modules/line/search/KusLineSearchAccountType'
import lodash from 'lodash'

export default {
    name: 'kus-line-search',
    components: {
        KusCardTitle,
        KusLineSearchText,
        KusLineSearchDate,
        KusLineSearchBoolean,
        KusLineSearchNumber,
        KusLineSearchAccountType,
        KusLineSearchVatCode,
        KusLineSearchPayStatus
    },
    props: {
        value: { type: String }
    },
    data: () => ({
        advanced: false,
        search: null,
        select: [],
        selectValues: {},
        oldSelect: [],
        edit: false,
        editForm: false,
        editType: {}
    }),
    computed: {
        headers() {
            return this.$store.getters['linegrid/headers']
        },

        filters() {
            const fs = this.headers
                .filter(header => !!header.search)
                .map(header => {
                    header.text = (header && header.name) || this.$i18n.t(`lines.headers.${header.field}`)
                    if (header.field === 'docNb') {
                        header.text = this.$i18n.t('lines.search.docNb')
                    }
                    header.value = this.selectValues[header.field] || null
                    return header
                })

            fs.push({
                search: 'KusLineSearchText',
                field: 'docAll',
                text: this.$i18n.t('lines.search.docTitle'),
                value: this.selectValues.docAll || null
            })

            fs.unshift({
                search: 'KusLineSearchText',
                field: 'all',
                text: this.$i18n.t('lines.search.all'),
                value: this.selectValues.all || null
            })
            return fs
        }
    },
    watch: {
        edit: 'focusField',
        search: 'onSearch',
        advanced: 'switchSearch'
    },
    mounted() {
        this.headers.forEach(h => this.$set(this.selectValues, h.field, null))
    },
    methods: {

        isAdvanced() {
            return this.advanced
        },

        setSearch(search, advanced) {
            if (!advanced) {
                this.search = search || ''
                if (!search) {
                    this.select = []
                }
                return
            }
            this.search = null
            this.advanced = true

            search = (search || '').replace('=', '')
            this.select = search.split(';;').reduce((acc, obj) => {
                const parts = obj.split('::')
                const filter = this.filters.find(filter => filter.field === parts[0])
                if (filter) {
                    filter.value = parts[1]
                    // parce [filters] est un computed, changer ses propriétés
                    // en dehors du computed ne sert +ou- à rien. On set donc
                    // une 2e variable avec les valeurs
                    this.selectValues[filter.field] = filter.value
                    acc.push(filter)
                }
                return acc
            }, [])
            this.oldSelect = this.select
            this.$emit('input', search)
        },

        change(advanced) {
            if ((advanced && !this.advanced) || (!advanced && this.advanced)) {
                return
            }
            if (this.select.length <= this.oldSelect.length) {
                this.oldSelect = this.select
                this.editSubmit()
                return
            }
            const diff = this.select
                .filter(x => !this.oldSelect.includes(x))
                .concat(this.oldSelect.filter(x => !this.select.includes(x)))[0]

            this.edit = true
            this.editType = diff
            this.oldSelect = this.select
        },

        filter(editType, query) {
            const tokens = lodash.compact(lodash.deburr(query).toLowerCase().split(' '))
            const check = lodash.deburr(editType.text).toLowerCase()
            for (let i = 0; i < tokens.length; i += 1) {
                if (check.indexOf(tokens[i]) === -1) {
                    return false
                }
            }
            return true
        },

        focusField(v) {
            if (v) {
                this.$nextTick(() => this.$refs.cmpField && this.$refs.cmpField.focus())
            } else {
                this.select = this.select.filter(x => !!this.selectValues[x.field])
                this.oldSelect = this.select
            }
        },

        editChip(editType) {
            this.edit = true
            this.editType = editType
        },

        editSubmit() {
            if (this.edit && !this.$refs.form.validate()) {
                return
            }
            this.edit = false
            this.$emit('input', this.advancedToSimple(this.select))
        },

        advancedToSimple(objects) {
            return (objects || []).reduce((acc, editType) => {
                const v = this.selectValues[editType.field]
                v && acc.push(`${editType.field}::${v}`)
                editType.value = v
                return acc
            }, []).join(';;')
        },

        simpleToAdvanced(str) {
            if ((str || '').indexOf('=') !== 0) {
                str = `=all::${str || ''}`
            }
            return str.substr(1).split(';;').reduce((acc, obj) => {
                const parts = obj.split('::')
                const filter = this.filters.find(filter => filter.field === parts[0])
                if (filter) {
                    filter.value = parts[1]
                    this.selectValues[filter.field] = filter.value
                    acc.push(filter)
                }
                return acc
            }, [])
        },

        switchSearch() {
            if (!this.advanced) {
                // advanced => simple
                const all = this.select.find(editType => editType.field === 'all')
                if (all && this.select.length === 1) {
                    this.search = all ? this.selectValues[all.field] : null
                } else {
                    this.search = this.select.length > 0 ? '=' + this.advancedToSimple(this.select) : ''
                }
                this.editSubmit()
            } else if (this.search) {
                // simple => advanced
                this.select = this.simpleToAdvanced(this.search)
                this.oldSelect = this.select
                this.editSubmit()
            }
        },

        simpleSearch() {
            if (!this.search) {
                return this.$emit('input', '')
            }
            this.$emit('input', this.search.indexOf('=') === 0 ? this.search.substr(1) : `all::${this.search}`)
        },

        onSearch: lodash.debounce(function() {
            this.simpleSearch()
        }, 500)
    }
}
</script>

<style lang="sass">
.kus-line-search--container
    display: flex
    flex: 1 1 auto

.kus-line-search--button
    transition: max-width 0.5s

    input, label
        font-size: 1em

    .v-text-field__slot
        min-height: 32px

    &.v-input--is-focused .v-input__prepend-inner i,
    &.v-input--is-focused .v-input__slot input
        color: inherit !important

    &.v-input--is-focused .v-input__slot .v-label
        color: rgba(0, 0, 0, 0.6) !important

.kus-line-search--all
    border-bottom: 1px dotted #ccc
    width: 100%

</style>
