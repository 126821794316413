<template>
    <div>
        <v-combobox
            class="pt-0 mt-0"
            ref="field"
            v-model="value"
            :items="items"
            :menu-props="menuprops"
            :search-input.sync="search"
            hide-details
            single-line flat solo
            @keydown="onKeyDown">
            <template #item="{ item }">
                <div class="v-list-item px-0"
                    @mouseover="() => hovers.push(item)"
                    @mouseout="() => hovers.splice(hovers.findIndex(t => t === item), 1)">
                    <v-flex>
                        {{ item }}
                    </v-flex>
                    <v-flex shrink>
                        <v-slide-x-transition>
                            <v-btn small icon color="red" @click.prevent.stop="exclude(item)" v-show="hovers.indexOf(item) !== -1">
                                <v-icon small>$vuetify.icons.baseDelete</v-icon>
                            </v-btn>
                        </v-slide-x-transition>
                    </v-flex>
                </div>
            </template>
        </v-combobox>
    </div>
</template>

<script>
import Vue from 'vue'
import SelectFocus from '@/modules/line/mixin/SelectFocus'
import KeyDown from '@/modules/line/mixin/KeyDown'

export default Vue.extend({
    name: 'kus-line-grid-client',
    mixins: [SelectFocus, KeyDown],
    data: () => ({
        menuprops: {},
        value: '',
        search: '',
        params: null,
        hovers: []
    }),
    computed: {
        clientId() {
            return this.$store.getters['auth/client']
        },
        items() {
            return this.$store.getters['linegridsel/clients']
        }
    },
    mounted() {
        if (this.params.context && this.params.context.attach) {
            this.menuprops.contentClass = 'maxZindex'
        }
        this.value = this.params.value
        this.$store.dispatch('linegridsel/clients', { clientId: this.clientId })
            .then(res => this.mixinSelectFocus())
    },
    methods: {
        onKeyDown(event) {
            this.mixinKeyDownMenu(event)
        },
        getValue() {
            return this.value || null
        },
        isPopup() {
            return false
        },
        exclude(item) {
            this.$store.dispatch('linegridsel/exclude', { clientId: this.clientId, value: item, field: 'clients' })
        }
    }
})
</script>
