<template>
    <div @keydown="onKeyDown">
        <v-combobox ref="field" v-model="value" :search="search" :items="items" hide-details single-line flat solo></v-combobox>
    </div>
</template>

<script>
import Vue from 'vue'
import SelectFocus from '@/modules/line/mixin/SelectFocus'

export default Vue.extend({
    name: 'kus-base-test-grid-editor',
    mixins: [SelectFocus],
    data() {
        return {
            items: [],
            search: '',
            value: ''
        }
    },
    mounted() {
        this.mixinSelectFocus()
    },
    methods: {
        getValue() {
            return this.value
        },
        onKeyDown(e) {
            if (e.key === 'Enter') {
                this.params.api.stopEditing()
            }
        }
    }
})
</script>
