<template>
    <div>
        <kus-line-search-type v-model="type" :skip="['contain']" />
        <v-text-field
            ref="field"
            v-model="text"
            :label="label"
            :disabled="disabled"
            :hint="hint || $t('lines.search.stringStrictHint')"
            persistent-hint />
    </div>
</template>

<script>
import KusLineSearchType from '@/modules/line/search/KusLineSearchType'
import SelectFocus from '@/modules/line/mixin/SelectFocus'

export default {
    name: 'kus-line-search-text',
    mixins: [SelectFocus],
    components: { KusLineSearchType },
    props: {
        value: { type: String },
        label: { type: String },
        hint: { type: String }
    },
    data: () => ({
        type: null
    }),
    mounted() {
        this.$nextTick(() => this.mixinFocus())
    },
    computed: {
        text: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        disabled() {
            return ['notnull', 'null'].indexOf(this.type) !== -1
        }
    },
    watch: {
        type(v) {
            this.text = v ? `[${v}]` : null
        }
    },
    methods: {
        focus() {
            this.mixinFocus()
        }
    }
}
</script>
