<template>
    <v-list two-line dense>
        <kus-document-flow-list-item
            v-for="file in files"
            :key="file.id"
            :file="file"
            @remove="remove"
            @activate="file => selected = file.id"
            :active="selected === file.id"
            out />

        <p v-if="!files.length" class="px-4 py-2"><em>{{ $t('document.flowList.empty') }}</em></p>
    </v-list>
</template>

<script>
import KusDocumentFlowListItem from '@/modules/document/KusDocumentFlowListItem'

export default {
    name: 'kus-document-flow-list',
    components: { KusDocumentFlowListItem },
    data: () => ({
        selected: null
    }),
    computed: {
        files() {
            return this.$store.getters['doc/files']
        }
    },
    methods: {
        remove(file) {
            this.$store.commit('doc/removePopup', { document: file })
        }
    }
}
</script>
