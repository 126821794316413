// import Vue from 'vue'
import i18next from 'i18next'
import moment from 'moment'
import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        current: null,
        region: null,
        available: []
    },

    getters: {
        current: state => state.current,
        region: state => state.region,
        available: state => state.available
    },

    mutations: {
        current(state, data) {
            state.current = data.current
            state.region = data.current
            i18next.changeLanguage(data.current)
            moment.locale(data.current)
            if (Vue.prototype.$vuetify) {
                Vue.prototype.$vuetify.lang.current = data.current
            }
        },
        region(state, data) {
            state.region = data.region
        },
        available(state, data) {
            state.available = data.available
        }
    }
}
