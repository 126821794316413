<template>
    <v-list class="pt-0">
        <v-toolbar dense flat color="primary">
            <v-spacer />
            <v-btn icon text @click="show = false" dark>
                <v-icon>$vuetify.icons.baseMenuRight</v-icon>
            </v-btn>
        </v-toolbar>

        <template v-for="(item, index) in items">
            <v-divider v-if="item.divider" :key="index" />
            <v-list-item v-else
                :key="item.text"
                :to="item.to"
                v-rights="item.rights"
                @click="(item.click && item.click(item)) || null">
                <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
export default {
    name: 'kus-base-menu-alt',
    props: {
        value: { type: Boolean }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        user() {
            return this.$store.getters['auth/user']
        },
        items() {
            return [
                { to: { name: 'useraccount' }, rights: 'member', icon: '$vuetify.icons.userAccount', text: this.$i18n.t('users.account.menu') },
                { divider: true },
                { click: this.logout, rights: 'member', icon: '$vuetify.icons.userLogout', text: this.$i18n.t('users.logout') }
            ]
        }
    },
    methods: {
        logout() {
            this.$store.commit('auth/logout')
            this.$store.commit('msg/show', { message: this.$i18n.t('users.logoutMsg') })
            this.$router.push({ name: 'homepublic' })
        }
    }
}
</script>
