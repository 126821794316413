<template>
    <v-select
        :loading="loading"
        :items="roles"
        v-model="selected"
        :label="$t('users.form.role')">
    </v-select>
</template>

<script>
export default {
    name: 'kus-user-form-roles',
    props: {
        value: { type: String }
    },
    data: () => ({
        loading: false
    }),
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        roles() {
            return this.$store.getters['role/list']
        }
    },
    mounted() {
        this.loading = true
        return this.$store.dispatch('role/list')
            .finally(() => (this.loading = false))
    }
}
</script>
