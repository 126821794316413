<template>
    <ag-grid-vue
        ref="maingrid"
        class="ag-theme-balham"
        rowSelection="multiple"
        :style="gridStyles"
        :columnDefs="headers"
        :rowData="data"
        :gridOptions="gridOptions"
        @columnResized="columnResized"
        @columnVisible="columnVisible"
        @columnMoved="columnMoved"
        @columnPinned="columnPinned"
        @cellValueChanged="cellValueChanged"
        @columnPivotChanged="ev => $emit('pivotChanged', ev)"
        @columnRowGroupChanged="ev => $emit('pivotChanged', ev)"
        @columnValueChanged="ev => $emit('pivotChanged', ev)"
        @cellClicked="cellClicked"
        @gridReady="gridReady"
        @sortChanged="sortChanged"
        @cell-key-down="onCellKeyDown"
        @cell-double-clicked="onCellDoubleClicked"
        :getRowStyle="getRowStyle"
        :getRowClass="getRowClass"
        :getMainMenuItems="getMainMenuItems"
        :getContextMenuItems="getContextMenuItems"
        :frameworkComponents="frameworkComponents"
        :pinnedBottomRowData="pinnedBottomRowData"
        :defaultColDef="defaultColDef"
        :enterMovesDownAfterEdit="enterMovesDownAfterEdit"
        enableCellChangeFlash
        :statusBar="statusBar"
        :sideBar="sideBar"
        :domLayout="layout">
    </ag-grid-vue>
</template>

<script>
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

import lodash from 'lodash'
import moment from 'moment'
import { AgGridVue } from 'ag-grid-vue'
import KusLineGridFlow from '@/modules/line/grid/KusLineGridFlow'
import KusLineGridDate from '@/modules/line/grid/KusLineGridDate'
import KusLineGridNumber from '@/modules/line/grid/KusLineGridNumber'
import KusLineGridSelect from '@/modules/line/grid/KusLineGridSelect'
import KusLineGridString from '@/modules/line/grid/KusLineGridString'
import KusLineGridAccount from '@/modules/line/grid/KusLineGridAccount'
import KusLineGridVatCode from '@/modules/line/grid/KusLineGridVatCode'
import KusLineGridPayStatus from '@/modules/line/grid/KusLineGridPayStatus'
import KusLineGridForexNumber from '@/modules/line/grid/KusLineGridForexNumber'
import KusLineGridForexString from '@/modules/line/grid/KusLineGridForexString'
import KusLineGridVat from '@/modules/line/grid/KusLineGridVat'
import KusLineGridLabel from '@/modules/line/grid/KusLineGridLabel'
import KusLineGridClient from '@/modules/line/grid/KusLineGridClient'
import KusBaseTestGridEditor from '@/modules/base/KusBaseTestGridEditor'
import KusLineGridStatusBar from '@/modules/line/grid/KusLineGridStatusBar.js'

export default {
    name: 'kus-line-grid',
    components: {
        AgGridVue, // eslint-disable-next-line
        KusLineGridFlow, // eslint-disable-next-line
        KusLineGridDate, // eslint-disable-next-line
        KusLineGridNumber, // eslint-disable-next-line
        KusLineGridSelect, // eslint-disable-next-line
        KusLineGridString, // eslint-disable-next-line
        KusLineGridAccount, // eslint-disable-next-line
        KusLineGridVatCode, // eslint-disable-next-line
        KusLineGridVat, // eslint-disable-next-line
        KusLineGridPayStatus, // eslint-disable-next-line
        KusLineGridLabel, // eslint-disable-next-line
        KusLineGridClient, // eslint-disable-next-line
        KusLineGridForexNumber, // eslint-disable-next-line
        KusLineGridForexString, // eslint-disable-next-line
        KusBaseTestGridEditor, // eslint-disable-next-line
        KusLineGridStatusBar
    },
    props: {
        pivot: { type: Boolean },
        layout: { type: String },
        enterMovesDownAfterEdit: { type: Boolean },
        data: { type: Array, default: () => [] },
        pinnedBottomRowData: { type: Array },
        suppressKeyboardEvent: { type: Function },
        getRowStyle: { type: Function },
        autoHeight: { type: Boolean },
        phantom: { type: Boolean },
        disableCellClick: { type: Boolean },
        disableEdit: { type: Boolean },
        statusPanel: { type: Object },
        headerType: { type: String },
        skipHeadersChanges: { type: Boolean, default: true },
        showSeparation: { type: Boolean },
        showDeleted: { type: Boolean },
        sort: { type: String }
    },
    data() {
        const self = this
        return {
            gridOptions: {
                context: {},
                singleClickEdit: false,
                groupSuppressAutoColumn: true,
                localeTextFunc(key, defaultvalue) {
                    const localeText = self.$i18n.t('aggrid')
                    return localeText[key]
                },
                getRowNodeId(node) {
                    return node.id
                }
            },
            lastSort: null,
            lastSortId: null,
            height: null,
            headers: [],
            frameworkComponents: {
                flowEditor: KusLineGridFlow,
                dateEditor: KusLineGridDate,
                numberEditor: KusLineGridNumber,
                selectEditor: KusLineGridSelect,
                stringEditor: KusLineGridString,
                accountEditor: KusLineGridAccount,
                vatcodeEditor: KusLineGridVatCode,
                paystatusEditor: KusLineGridPayStatus,
                vatEditor: KusLineGridVat,
                labelEditor: KusLineGridLabel,
                clientEditor: KusLineGridClient,
                testEditor: KusBaseTestGridEditor,
                forexNumberEditor: KusLineGridForexNumber,
                forexStringEditor: KusLineGridForexString,
                gridStatusBar: KusLineGridStatusBar
            },
            statusBar: {
                // KUS-173: pour le moment, on n'affiche plus cette barre
                // statusPanels: this.statusPanel ? [this.statusPanel] : []
            }
        }
    },
    computed: {
        gridStyles() {
            return { height: `${this.height}px` }
        },
        clientId() {
            return this.$store.getters['auth/client']
        },
        sideBar() {
            return this.pivot
        },
        skipHeadersCh: {
            get() {
                return this.skipHeadersChanges
            },
            set(v) {
                this.$emit('update:skipHeadersChanges', v)
            }
        },
        headerChanged() {
            return this.$store.getters['linegrid/headerChanged']
        },
        docPopupChanged() {
            return this.$store.getters['docpop/added']
        },
        defaultColDef() {
            const def = {}
            if (this.suppressKeyboardEvent) {
                def.suppressKeyboardEvent = this.suppressKeyboardEvent
            }
            return def
        }
    },
    watch: {
        pivot(v) {
            this.gridOptions.columnApi.setPivotMode(v)
        },
        headerChanged(type) {
            if (!type && type.indexOf(this.headerType) === -1) {
                return
            }
            this.gridOptions.api.refreshHeader()
            this.headers.forEach(header => {
                this.gridOptions.columnApi.setColumnWidth(header.colId, header.width, false)
            })
        },
        docPopupChanged(v) {
            this.refresh(['docNb'])
        }
    },
    mounted() {
        if (this.phantom) {
            return
        }
        return this.$store.dispatch('linegrid/headers', { clientId: this.clientId })
            .then(() => {
                this.$emit('mounted')
            })
    },
    methods: {

        setHeaders(headers) {
            const data = headers.concat([])
            const self = this
            const defaults = {
                _base: {
                    cellEditor: 'selectEditor',
                    editable: this.canEdit,
                    onCellValueChanged: this.changeValue,
                    resizable: true,
                    enablePivot: true,
                    enableRowGroup: true,
                    enableValue: true
                },
                id: {
                    editable: false
                },
                docNb: {
                    lockPinned: true,
                    editable: false,
                    cellRenderer: this.renderDocNb
                },
                flow: {
                    editable: false
                },
                block: {
                    editable: false
                },
                client: {
                    cellEditor: 'clientEditor',
                    editable: this.canEdit,
                    onCellValueChanged: this.changeValue
                },
                label: {
                    cellEditor: 'labelEditor',
                    editable: this.canEdit,
                    onCellValueChanged: this.changeValue
                },
                opDate: {
                    cellEditor: 'dateEditor',
                    type: null,
                    cellRenderer: this.renderDate,
                    editable: this.canEdit,
                    onCellValueChanged: this.changeValue
                },
                opYear: {
                    editable: false,
                    type: 'numericColumn'
                },
                opMonth: {
                    editable: false
                },
                opYearMonth: {
                    editable: false
                },
                payDate: {
                    cellEditor: 'dateEditor',
                    type: null,
                    cellRenderer: this.renderDate,
                    editable: this.canEdit,
                    onCellValueChanged: this.changeValue
                },
                payStatus: {
                    cellEditor: 'paystatusEditor',
                    cellRenderer: this.renderPayStatus,
                    editable: this.canEdit,
                    onCellValueChanged: this.changeValue
                },
                payYear: {
                    editable: false,
                    type: 'numericColumn'
                },
                payMonth: {
                    editable: false
                },
                payYearMonth: {
                    editable: false
                },
                balance: {
                    editable: false,
                    type: 'numericColumn',
                    cellRenderer: this.renderBalance
                },
                balanceForeignCurrency: {
                    editable: false,
                    type: 'numericColumn',
                    cellRenderer: this.renderNumber
                },
                account: {
                    cellEditor: 'accountEditor',
                    cellRenderer: this.renderAccount,
                    editable: this.canEdit,
                    onCellValueChanged: this.phantom ? this.changeAccountPhantom : this.changeAccount,
                    valueGetter: this.renderAccountCodeName,
                    filterValueGetter: this.renderAccountCodeName
                },
                accountName: {
                    editable: false
                },
                accountCode: {
                    editable: false
                },
                accountType: {
                    editable: false
                },
                currency: {
                    editable: false
                },
                amount: {
                    cellEditor: 'forexNumberEditor',
                    type: 'numericColumn',
                    cellRenderer: this.renderNumber,
                    editable: this.canEdit,
                    onCellValueChanged: this.changeValue
                },
                foreignCurrency: {
                    cellEditor: 'forexStringEditor',
                    editable: this.canEdit,
                    onCellValueChanged: this.changeValue
                },
                change: {
                    cellEditor: 'forexNumberEditor',
                    type: 'numericColumn',
                    cellRenderer: this.renderChange,
                    editable: this.canEdit,
                    onCellValueChanged: this.changeValue
                },
                amountForeignCurrency: {
                    cellEditor: 'forexNumberEditor',
                    type: 'numericColumn',
                    cellRenderer: this.renderNumber,
                    editable: this.canEdit,
                    onCellValueChanged: this.changeValue
                },
                vat: {
                    cellEditor: 'vatEditor',
                    type: 'numericColumn',
                    cellRenderer: this.renderVat,
                    editable: this.canEdit,
                    onCellValueChanged: this.changeValue
                },
                vatCode: {
                    cellEditor: 'vatcodeEditor',
                    editable: this.canEdit,
                    onCellValueChanged: this.changeValue
                },
                deletedAt: {
                    cellEditor: 'dateEditor',
                    type: null,
                    cellRenderer: this.renderDate,
                    editable: false
                },
                deletedBy: {
                    editable: false
                },
                deleted: {
                    editable: false,
                    cellRenderer(params) {
                        return params.data.deletedAt ? self.$i18n.t('base.yes') : ''
                    }
                }
            }
            this.headers = data.map((d, i) => {
                const o = { ...d, ...defaults._base, ...(defaults[d.field] || {}) }
                o.hide = o.hidden
                o.pinned = o.pin
                o.headerName = o.name || this.$i18n.t(`lines.headers.${d.field}`)
                o.colId = o.field
                o.sortingOrder = ['asc', 'desc']
                if (this.headerType !== 'main') {
                    o.sortable = false
                    o.sort = null
                }
                return o
            })
        },

        setHeight(height) {
            this.height = height
        },

        resetColumns() {
            this.gridOptions.api.setColumnDefs([])
            this.$nextTick(() => this.gridOptions.api.setColumnDefs(this.headers))
        },

        getRowClass(params) {
            if (!this.gridOptions.columnApi) {
                return
            }
            let cls = ''
            if (params.data && params.data.isTech) {
                cls += 'kus-grid-row--tech '
            }
            const sortField = this.gridOptions.columnApi.getColumnState().find(col => col.colId === this.sort)
            if (this.showSeparation && sortField) {
                const prev = this.gridOptions.api.getDisplayedRowAtIndex(params.rowIndex - 1)
                if (prev && JSON.stringify(prev.data[sortField.colId]) !== JSON.stringify(params.data[sortField.colId])) {
                    cls += 'kus-grid-row--sep '
                }
            }
            return cls
        },

        getMainMenuItems(params) {
            const items = params.defaultItems.slice(0).filter(t => t !== 'resetColumns')
            if (params.column.colId === 'balance' || params.column.colId === 'balanceForeignCurrency') {
                items.push({
                    name: this.$i18n.t('lines.gridHeaderBalance.menu'),
                    subMenu: [{
                        name: this.$i18n.t('lines.gridHeaderBalance.absolute'),
                        checked: this.$store.getters['linegrid/sumType'] === 'absolute',
                        action: () => {
                            this.$emit('sumChanged', 'absolute')
                        }
                    }, {
                        name: this.$i18n.t('lines.gridHeaderBalance.relative'),
                        checked: this.$store.getters['linegrid/sumType'] === 'relative',
                        action: () => {
                            this.$emit('sumChanged', 'relative')
                        }
                    }]
                })
            }
            items.push({
                name: this.$i18n.t('lines.gridHeaderEdit.menu'),
                action: () => {
                    this.$store.commit('linegrid/editHeaderPopup', { column: params.column.colDef })
                }
            })
            return items
        },

        onCellKeyDown(ev) {
            this.$emit('cell-key-down', ev)
        },

        getContextMenuItems(params) {
            if (params.node.data.deletedAt || params.node.data.isTech) {
                return []
            }
            const self = this
            const result = []
            if (!this.disableCellClick) {
                result.push(
                    {
                        name: this.$i18n.t(`lines.copy.${this.disableCellClick ? 'line' : 'title'}`, params.node.data),
                        action() {
                            self.$emit('copyLine', params)
                        }
                    },
                    'separator'
                )
            }
            result.push({
                name: this.$i18n.t(`lines.removePopup.${this.disableCellClick ? 'line' : 'title'}`, params.node.data),
                action() {
                    self.$emit('removeLine', params)
                },
                cssClasses: ['red--text']
            })
            return result
        },

        goToBottom() {
            this.gridOptions.api.ensureIndexVisible(this.data.length - 1)
        },

        options() {
            return this.gridOptions
        },

        // méthodes de réaction aux colonnes de la grid
        columnMoved: lodash.debounce(function(ev) {
            this.gridOptions.api.stopEditing()
            this.$emit('pivotChanged', ev)
            return this.$store.dispatch('linegrid/column', {
                ev: ev,
                type: this.headerType,
                pivot: this.pivot,
                clientId: this.clientId,
                state: this.gridOptions.columnApi.getColumnState()
            })
        }, 1000),
        columnVisible: lodash.debounce(function(ev) {
            this.skipHeadersCh = true
            this.gridOptions.api.sizeColumnsToFit()
            this.gridOptions.api.stopEditing()
            return this.$store.dispatch('linegrid/column', {
                ev: ev,
                type: this.headerType,
                pivot: this.pivot,
                clientId: this.clientId,
                state: this.gridOptions.columnApi.getColumnState()
            })
        }, 500),
        columnResized: lodash.debounce(function(ev) {
            if (!ev.finished || this.skipHeadersCh) {
                this.skipHeadersCh = false
                return
            }
            this.$emit('pivotChanged', ev)
            this.gridOptions.api.stopEditing()
            return this.$store.dispatch('linegrid/column', {
                ev: ev,
                type: this.headerType,
                pivot: this.pivot,
                clientId: this.clientId,
                state: this.gridOptions.columnApi.getColumnState()
            })
        }, 1000),
        columnPinned: lodash.debounce(function(ev) {
            this.gridOptions.api.stopEditing()
            return this.$store.dispatch('linegrid/column', {
                ev: ev,
                type: this.headerType,
                pivot: this.pivot,
                clientId: this.clientId,
                state: this.gridOptions.columnApi.getColumnState()
            })
        }, 1000),

        sortChanged(ev) {
            if (this.disableCellClick) {
                return
            }
            const s = this.gridOptions.columnApi.getColumnState().reduce((acc, col) => {
                if (!col.sort) {
                    return acc
                }
                acc.push({
                    sort: col.sort,
                    colId: col.colId,
                    index: col.sortIndex
                })
                return acc
            }, []) || []
            s.sort((a, b) => a.index < b.index)
            this.$emit('sortChanged', s)
            if (this.headerType === 'main') {
                this.$store.dispatch('linegrid/column', {
                    ev: ev,
                    type: this.headerType,
                    pivot: this.pivot,
                    clientId: this.clientId,
                    state: this.gridOptions.columnApi.getColumnState()
                })
            }
        },

        cellClicked(data) {
            this.$emit('cellClicked', data)
            // les 3 lignes ci-dessous permettent les comportements suivants
            // - 0 docs : rien ne se passe
            // - 1 doc  : pas de popup, mais le panel doc s'ouvre avec le seul doc dedans
            // - 2+ docs: popup documents
            if (data.colDef.field === 'docNb' && (data.data.docNb > 1 || data.data.flow !== this.$store.getters['docpop/flow'])) {
                return this.docNbClicked(data)
            }

            if (this.disableCellClick || !data || !data.data || data.data.deletedAt || data.data.isTech) {
                return
            }
            if (data.event.ctrlKey || data.event.shiftKey) {
                return
            }
            const readonly = ['block', 'amount', 'flow', 'currency', 'foreignCurrency', 'amountForeignCurrency', 'change']
            this.$emit('autoFilter', data)
            if (readonly.indexOf(data.colDef.field) !== -1) {
                this.$store.commit('lineedit/show', {
                    show: true,
                    block: data.data.block,
                    flow: data.data.flow,
                    field: data.colDef.field
                })
            }
        },

        async onCellDoubleClicked(ev) {
            if (ev.colDef.field === 'docNb' && ev.data.docNb === 1) {
                const files = await this.$store.dispatch('docpop/show', {
                    clientId: this.clientId,
                    show: true,
                    flow: ev.data.flow,
                    block: ev.data.block,
                    disablePopup: true
                })
                this.$store.commit('lineedit/show', {
                    show: true,
                    block: ev.data.block,
                    flow: ev.data.flow,
                    field: 'docNb'
                })
                this.$store.commit('doc/preview', { file: files[0] })
            }
        },

        docNbClicked(ev) {
            if (this.disableCellClick) {
                return
            }
            const pos = { x: ev.event.clientX - 20, y: ev.event.clientY - 20 }
            if (this.$store.getters['docpop/popup'] && this.$store.getters['docpop/flow'] === ev.data.flow) {
                return this.$store.commit('docpop/pos', { pos: pos })
            }
            this.$store.dispatch('docpop/show', {
                clientId: this.clientId,
                show: true,
                flow: ev.data.flow,
                block: ev.data.block,
                pos: pos
            })
        },

        gridReady(data) {
            this.$emit('gridReady', data)
        },

        renderDate(params) {
            return params.value && moment(params.value).format('DD.MM.YYYY')
        },
        renderPayStatus(params) {
            if (!params.value) {
                return
            }
            const s = this.$i18n.t('lines.payStatus')
            return s[params.value] || params.value
        },
        renderNumber(params) {
            if (params.value === null || params.value === undefined || isNaN(params.value)) {
                return ''
            }
            return this.$options.filters.number(params.value)
        },
        renderBalance(params) {
            if (this.showDeleted) {
                return ''
            }
            return this.renderNumber(params)
        },
        renderChange(params) {
            if (params.value === null || params.value === undefined || isNaN(params.value)) {
                return ''
            }
            return this.$options.filters.number(params.value, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 10
            })
        },
        renderVat(params) {
            if (params.value === null || params.value === undefined || isNaN(params.value)) {
                return ''
            }
            return this.$options.filters.number(params.value, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
                style: 'percent'
            })
        },
        renderAccount(params) {
            if (params.value && typeof params.value === 'string') {
                // utilisé dans les pivots rowgroup
                return params.value
            }
            if (!params.value || !params.value.code) {
                return ''
            }
            return `${params.value.code} ${params.value.name}`
        },
        renderAccountCodeName(params, a) {
            if (!params.data) {
                return
            }
            if (params.columnApi && params.columnApi.columnController.pivotMode) {
                // utilisé dans les pivots rowgroup
                const account = (params.data && params.data.account) || {}
                return `${account.code} ${account.name}`
            }
            return params.data.account
        },
        renderDocNb(params) {
            const color = params.value ? 'primary--text' : 'grey--text'
            const value = params.value || ''
            return `<i aria-hidden="true" class="v-icon mdi mdi-file theme--light ${color}" style="font-size: 16px;"></i> ${value}`
        },

        changeValue(ev) {
            if (ev.oldValue === ev.newValue || this.phantom) {
                this.$emit('changeValue', ev)
                return
            }
            this.$store
                .dispatch('linegrid/value', {
                    pivot: this.pivot,
                    clientId: this.clientId,
                    line: ev.data,
                    column: ev.colDef.field,
                    oldValue: ev.oldValue,
                    value: ev.newValue
                })
                .then(lines => this.refresh(['flow', 'opDate', 'docNb']))
        },

        changeAccount(ev) {
            if ((ev.oldValue && ev.oldValue.code) === (ev.newValue && ev.newValue.code)) {
                return
            }
            this.$store
                .dispatch('linegrid/value', {
                    pivot: this.pivot,
                    clientId: this.clientId,
                    line: ev.data,
                    columns: ['code', 'name', 'type'].map(key => ({
                        column: `account${key.charAt(0).toUpperCase() + key.slice(1)}`,
                        oldValue: ev.oldValue && ev.oldValue[key],
                        value: ev.newValue && ev.newValue[key]
                    }))
                })
                .then(lines => this.refresh(['flow', 'opDate', 'year', 'month', 'yearMonth', 'account', 'accountName', 'accountCode', 'accountType']))
        },

        changeAccountPhantom(ev) {
            if (!ev.newValue || (ev.oldValue && ev.oldValue.code) === ev.newValue.code) {
                return
            }
            ev.data.accountCode = ev.newValue.code
            ev.data.accountName = ev.newValue.name
            ev.data.accountType = ev.newValue.type
        },

        refresh(fields) {
            const rows = []
            for (let i = 0; i < this.gridOptions.api.getDisplayedRowCount(); i += 1) {
                rows.push(this.gridOptions.api.getDisplayedRowAtIndex(i))
            }
            this.gridOptions.api.refreshCells({
                columns: fields,
                rowNodes: rows
            })
        },

        canEdit(ev) {
            if (ev.node.rowPinned === 'bottom') {
                // la seule ligne pinned est la ligne de résumé à l'ajout ou
                // modification d'une pièce
                return false
            }
            if (!this.disableCellClick && ['amount', 'currency', 'amountForeignCurrency', 'foreignCurrency', 'change'].indexOf(ev.colDef.field) !== -1) {
                // le montant n'est pas modifiable en mode grid complète
                return false
            }
            if (this.disableEdit) {
                return false
            }
            // gestion des comptes techniques
            if (ev.data.isTech && ['flow', 'opDate', 'account'].indexOf(ev.colDef.field) !== -1) {
                return false
            }
            return !ev.data.deletedAt
        },

        cellValueChanged(data) {
            this.$emit('cellValueChanged', data)
        }
    }
}
</script>

<style lang="sass">
div.ag-cell-inline-editing
    overflow: visible

div.ag-menu-separator
    border-bottom: 1px solid #ccc

div.ag-root.ag-layout-normal, div.ag-root.ag-layout-auto-height
    border-left: 0
    border-right: 0
    border-bottom: 0

.v-text-field--single-line.v-text-field--solo.v-text-field--solo-flat.v-input--hide-details
    .v-input__control
        min-height: inherit

    .v-input__slot
        font-size: 12px
        background-color: #eee

    input
        padding: 3px 0 3px 0
        border: none
        background-color: transparent
        box-shadow: none

.ag-header-cell[aria-sort="ascending"], .ag-header-cell[aria-sort="descending"]
    background-color: #fc0

.kus-grid-row--tech
    background-color: #efe !important

.kus-grid-row--sep
    border-top: 1px solid #555 !important

</style>
