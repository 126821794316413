<template>
    <v-card class="kus-demo-card">
        <v-form class="kus-demo-form">
            <v-card-text>
                {{ $t(`demo.step${step}`) }}

                <v-progress-linear
                    v-model="progress"
                    v-if="!finished && step"
                    height="12"
                    color="primary"
                    class="my-5"
                    rounded />
            </v-card-text>
            <v-card-actions>
                <v-btn
                    v-if="!finished"
                    rounded
                    block
                    color="primary"
                    @click="start"
                    :loading="loading">
                    {{ $t('demo.start')}}
                </v-btn>

                <v-btn
                    v-else
                    rounded
                    block
                    outlined
                    color="primary"
                    :to="{ name: 'line' }">
                    {{ $t('demo.finished') }}
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
export default {
    name: 'kus-demo-form',
    data: () => ({
        loading: false,
        finished: false,
        user: null,
        logged: false,
        client: null,
        selected: false,
        timeout: null,
        step: 0
    }),

    computed: {
        progress() {
            return this.step * (100 / 6)
        }
    },

    beforeDestroy() {
        this.stopRedirection()
    },

    methods: {
        async start() {
            this.loading = true
            this.finished = false

            try {
                this.step = 1
                const user = this.user || await this.$store.dispatch('demo/createUser')
                this.user = user

                this.step++
                !this.logged && await this.$store.dispatch('auth/login', {
                    email: user.email,
                    password: user.password
                })
                this.logged = true

                this.step++
                const client = this.client || await this.$store.dispatch('demo/createClient')
                this.client = client

                this.step++
                !this.selected && await this.$store.dispatch('auth/client', { clientId: client.id })
                this.selected = true

                this.step++
                await this.$store.dispatch('demo/createLines', { clientId: client.id })

                this.step++
                this.loading = false
                this.finished = true

                this.startRedirection()
            } catch (err) {
                this.$store.commit('msg/show', { message: err.message })
                this.loading = false
            }
        },

        startRedirection() {
            this.timeout = window.setTimeout(() => {
                this.$router.push({ name: 'line' })
            }, 3000)
        },

        stopRedirection() {
            window.clearTimeout(this.timeout)
        }
    }
}
</script>

<style lang="sass">
.kus-demo-card
    display: flex
    align-items: center
    padding-top: 2em

    background-color: transparent !important

.kus-demo-form
    max-width: 400px
    width: 100%
    margin: 0 auto
    text-align: center

    .kus-login-logo
        margin: 0 auto

    .kus-btn--login
        display: block
        margin: 0 auto

    .kus-btn--more
        text-transform: none

</style>
