<template>
    <v-text-field
        ref="field"
        type="email"
        name="email"
        v-model="email"
        autocapitalize="none"
        :rules="[required, emailed]"
        :label="$t('users.form.email')" />
</template>

<script>
import KusBaseRules from '@/modules/base/KusBaseRules'

export default {
    name: 'kus-user-form-email',
    props: {
        value: { type: String }
    },
    data: () => ({
        required: KusBaseRules.required,
        emailed: KusBaseRules.emailed
    }),
    computed: {
        email: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
