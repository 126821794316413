<template>
    <iframe
        frameborder="0"
        :width="width"
        :height="height"
        :src="src"
        allowfullscreen></iframe>
</template>

<script>
export default {
    name: 'kus-base-video',
    props: {
        value: { type: String },
        width: { type: [String, Number], default: '100%' },
        height: { type: [String, Number], default: 'auto' }
    },
    computed: {
        src() {
            const url = 'https://vod.infomaniak.com/iframe.php'
            const params = {
                player: 9222,
                vod: 5637,
                wmode: 'direct',
                mobile: `nilsguillaume-gentil_vod/racibe-55663/mp4-322/${this.value}.mp4`,
                url: `nilsguillaume-gentil_vod/racine-55663/mp4-12/${this.value}.mp4`,
                preloadImage: `nilsguillaume-gentil_vod/racine-55663/mp4-12/${this.value}.jpg`
            }
            return url + '?' + Object.entries(params).map(e => `${e[0]}=${e[1]}`).join('&')
        }
    }
}
</script>
