<template>
    <v-container fluid class="fill-height">
        <v-row no-gutters class="fill-height">
            <v-col cols="12" md="3" sm="12">
                <kus-client-list class="align-self-stretch fill-height pr-4" />
            </v-col>
            <v-col cols="12" md="9" sm="12">
                <router-view class="align-self-stretch fill-height" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import KusClientList from '@/modules/client/KusClientList'

export default {
    name: 'kus-client-list-vue',
    components: { KusClientList }
}
</script>
