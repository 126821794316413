<template>
    <v-text-field
        ref="field"
        v-model="text"
        :label="label"
        :name="name" />
</template>

<script>
export default {
    name: 'kus-user-form-name',
    props: {
        value: { type: String },
        label: { type: String },
        name: { type: String }
    },
    computed: {
        text: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
