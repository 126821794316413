<template>
    <div class="kus-card-title">
        <v-card-title class="py-1" :class="[color, singleLine && 'single-line']">
            <slot name="before-buttons"></slot>
            <div class="title--text">
                <h6 v-if="!badge" :class="colorText">{{ title }}</h6>
                <v-badge v-else :color="badgeColor">
                    <template #badge>
                        {{ badge }}
                    </template>
                    <h3 :class="colorText">{{ title }}</h3>
                </v-badge>
            </div>
            <slot name="buttons"></slot>
            <v-spacer v-if="!hideSpacer" />
            <v-btn dark text icon small @click="onClick" v-if="dismissible">
                <v-icon small>$vuetify.icons.baseClose</v-icon>
            </v-btn>
        </v-card-title>
        <div class="extension secondary white--text" :class="{ absolute: extensionFloat }">
            <slot name="extension"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'kus-card-title',
    props: {
        title: { type: String },
        color: { type: String, default: 'primary' },
        colorText: { type: String, default: 'white--text' },
        minHeight: { type: Boolean },
        badge: {},
        badgeColor: { type: String },
        dismissible: { type: Boolean, default: true },
        hideSpacer: { type: Boolean },
        extensionFloat: { type: Boolean },
        singleLine: { type: Boolean }
    },
    methods: {
        onClick(ev) {
            this.$emit('close', ev)
        }
    }
}
</script>

<style lang="sass" scoped>
.extension.absolute
    z-index: 1
    position: absolute
    width: 100%

.kus-card-title

    .single-line
        display: flex
        flex-wrap: nowrap

        .title--text
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

</style>
