<template>
    <v-dialog v-model="show" scrollable max-width="600">
        <v-card>
            <kus-card-title :title="$t('document.removePopup.title', { name: document.name })" color="red" @close="show = false" />
            <v-card-text>
                <div v-marked="$t('document.removePopup.text', { title: document.title || document.uploadName })"></div>
                <kus-document-metadata :file="document" />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-tooltip left>
                    <template #activator="{ on }">
                        <v-btn icon text color="secondary" target="_blank" :href="`${document.url}?${tokenQuery}`" v-on="on">
                            <v-icon>$vuetify.icons.docOpen</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('document.removePopup.download') }}</span>
                </v-tooltip>
                <v-btn @click="remove" icon text color="red" :disabled="loading" :loading="loading">
                    <v-icon>$vuetify.icons.baseDelete</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import KusCardTitle from '@/modules/card/KusCardTitle'
import KusDocumentMetadata from '@/modules/document/KusDocumentMetadata'

export default {
    name: 'kus-document-remove-popup',
    components: { KusCardTitle, KusDocumentMetadata },
    data: () => ({
        loading: false
    }),
    computed: {
        show: {
            get() {
                return this.$store.getters['doc/removePopup']
            },
            set(v) {
                this.$store.commit('doc/removePopup', { show: false })
            }
        },
        document() {
            return this.$store.getters['doc/removeDocument'] || {}
        },
        removeType() {
            return this.$store.getters['doc/removeType']
        },
        tokenQuery() {
            return this.$store.getters['auth/tokenQuery']
        }
    },
    methods: {
        remove() {
            this.loading = true
            this.$emit('start', this.document)
            this.$api.delete(`/documents/${this.document.id}`)
                .then(() => {
                    const operation = this.removeType === 'unassoc' ? 'removeFileUnassociated' : 'remove'
                    this.$store.commit(`doc/${operation}`, { file: this.document })
                    this.$store.commit('doc/removePopup', { show: false })
                })
                .finally(() => {
                    this.loading = false
                    this.$emit('end', this.document)
                })
        }
    }
}
</script>
