export default {
    namespaced: true,

    state: {
        block: null,
        flow: null,
        show: false,
        field: null,
        copy: null,
        reset: false,
        height: 162
    },

    getters: {
        show: state => state.show,
        block: state => state.block,
        flow: state => state.flow,
        field: state => state.field,
        height: state => state.height,
        reset: state => state.reset,
        copy: state => state.copy
    },

    mutations: {
        show(state, data) {
            state.show = data.show
            state.block = data.block
            state.flow = data.flow
            state.field = data.field
            state.copy = data.copy
        },

        hide(state) {
            state.show = false
            state.block = null
            state.flow = null
            state.field = null
            state.copy = null
        },

        unsetCopy(state) {
            state.copy = null
        },

        height(state, data) {
            state.height = data.height
        },

        reset(state) {
            state.reset = !state.reset
        }
    }
}
