<template>
    <v-menu v-model="menu">
        <template #activator="{ on, attrs }">
            <v-btn
                v-on="on"
                v-bind="attrs"
                small icon
                dark
                class="ml-3"
                :to="isLink ? 'line' : null"
                :input-value="active"
                :loading="loading">
                <v-icon small>$vuetify.icons.linePivot</v-icon>
            </v-btn>
        </template>
        <v-list v-if="!isLink" dense>
            <v-list-item v-for="(item, i) in items" :key="i" @click.stop.prevent="select(item)">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
                <v-list-item-action v-if="selected === item">
                    <v-icon @click.prevent.stop="save()" small color="primary">$vuetify.icons.baseSave</v-icon>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'kus-line-grid-topbar-pivot',
    props: {
        value: { type: Object },
        current: { type: Object },
        active: { type: Boolean },
        isLink: { type: Boolean }
    },
    data: () => ({
        loading: false,
        menu: false,
        items: []
    }),

    computed: {
        selected: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        clientId() {
            return this.$store.getters['auth/client']
        }
    },

    mounted() {
        if (this.isLink) {
            return
        }
        this.loading = true
        const defaultTo = [{
            id: 1,
            text: this.$i18n.t('pivot.standard'),
            sumType: 'absolute',
            sort: 'accountCode',
            search: 'vatCode::[notnull]',
            state: {
                state: [
                    { colId: 'accountCode', rowGroup: true, enableRowGroup: true },
                    { colId: 'vatCode', rowGroup: true, enableRowGroup: true },
                    { colId: 'amount', enableValue: true, aggFunc: 'sum' },
                    { colId: 'accountType', pivot: true, enablePivot: true }
                ],
                defaultState: {
                    pivot: false,
                    rowGroup: false,
                    enableValue: false,
                    enablePivot: false,
                    enableRowGroup: false
                }
            }
        }]
        return this.$store.dispatch('pref/get', { clientId: this.clientId, type: 'pivot-config', defaultTo })
            .then(items => (this.items = items))
            .finally(() => (this.loading = false))
    },

    methods: {
        select(item) {
            this.selected = item
            this.$nextTick(() => (this.menu = false))
        },

        save() {
            if (!this.selected || !this.current) {
                return
            }
            // on sauve la config actuelle de la grid dans les prefs
            this.loading = true

            // modifie this.items par référence
            this.selected.state.state = this.current.state
            this.selected.sumType = this.current.sumType
            this.selected.search = this.current.search
            this.selected.sort = this.current.sort

            return this.$store.dispatch('pref/save', { clientId: this.clientId, type: 'pivot-config', value: this.items })
                .then(() => {
                    this.$store.commit('msg/show', { message: this.$i18n.t('pivot.saved') })
                    this.menu = false
                })
                .finally(() => (this.loading = false))
        }
    }
}
</script>
