<template>
    <div>
        <p class="mb-0">{{ $t('result.dateLabel') }}</p>
        <div class="d-flex justify-space-between">
            <kus-base-date-range-field
                input-class="py-0 pr-3"
                v-for="(date, index) in dates"
                :key="index"
                v-model="dates[index]"
                single-line
                hide-details
                :disabled="disabled"
                :clearable="dates.length > 1"
                @click:clear="removeDate(index)" />

            <template v-if="!disabled">
                <v-btn text icon small @click="addDate" color="primary" class="mt-2">
                    <v-icon small>$vuetify.icons.baseAdd</v-icon>
                </v-btn>
                <v-btn text icon small @click="save" color="primary" class="mt-2">
                    <v-icon small>$vuetify.icons.baseSave</v-icon>
                </v-btn>
            </template>
        </div>
    </div>
</template>

<script>
import KusBaseDateRangeField from '@/modules/base/KusBaseDateRangeField'
import moment from 'moment'

export default {
    name: 'kus-result-date-selector',
    components: { KusBaseDateRangeField },
    props: {
        value: { type: Array },
        disabled: { type: Boolean }
    },
    computed: {
        dates: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    methods: {

        addDate() {
            this.dates.push([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
        },

        removeDate(index) {
            // on doit mettre un timeout, parce que sinon le range suivant se
            // vide. C'est un bug visuel, mais c'est moche
            window.setTimeout(() => this.dates.splice(index, 1), 100)
        },

        save() {
            this.dates.sort()
            this.dates.reverse()
            this.$emit('save', this.dates)
        }
    }
}
</script>
